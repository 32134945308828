import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { setBreadcrumbs } from '../../features/active-page/sub-pages-slice';
import { setPageHeader } from '../../features/active-page/page-header-slice';
import { setHeaderElements } from '../../features/active-page/header-elements-slice';
import { EventHandlerContext } from '../../contexts/event-handler-context';
import $ from "jquery";
import "datatables.net";
import "datatables.net-responsive";
import "datatables.net-buttons-dt";
import "datatables.net-select";
import "jszip";
import "pdfmake";
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.print';
import 'datatables.net-buttons/js/buttons.colVis';
import 'datatables.net-buttons/js/buttons.flash';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Functions from '../../class/functions';
import { getEndPoint } from "../../state/api/index"
import axios from 'axios';

Functions.importThemeStyles();

const ReactSwal = withReactContent(Swal);
const mapStateToProps = state => ({
    redux: {
        pageHeader: state.pageHeader,
        breadcrumbs: state.breadcrumbs,
        headerElements: state.headerElements
    }
});
const mapDispatchToProps = () => ({
    setPageHeader,
    setBreadcrumbs,
    setHeaderElements
});

class StorageList extends Component {
    static contextType = EventHandlerContext

    constructor(props) {
        super(props);

        this.state = {
            rowsToDelete: [],
            selectDeleteItemRows: 0,
        };
        this.columnSelect = React.createRef();

        this.storageListTable = null;
        this.storageListTableRef = React.createRef();
        this.selectAllRef = React.createRef();
    }

    componentDidMount() {
        const { t } = this.props;
        //let _this = this;
        $('.select').select2({
            width: '100%'
        });
        this.props.setPageHeader(t("pages.storages.storages", { ns: "translations" }));
        this.props.setHeaderElements([
            {
                componentId: "delete-storage",
                href: "#", translation: { key: "misc.deleteSelected", opts: { ns: "translations" } },
                className: "btn btn-link btn-float text-body", icon: "fas fa-trash text-danger",
            }
        ]);
        this.context.addEventHandler("delete-storage", (ev) => this.onDeleteStorageButtonClick(ev));
        if (this.storageListTable != undefined)
            this.storageListTable.destroy();
        this.storageListTable = $(".datatable-pagination").DataTable({
            pagingType: 'simple_numbers',
            autoWidth: false,
            responsive: true,
            processing: true,
            serverSide: true,
            lengthMenu: [[50, 100, 150, -1], [50, 100, 150, "Hepsi"]],
            dom: 'Blfrtip',
            buttons: [
                'copyHtml5',
                'excelHtml5',
                'csvHtml5',
                'pdfHtml5'
            ],
            ajax: {
                url: `${getEndPoint()}/api/storages/all`,
                type: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                xhrFields: {
                    withCredentials: true
                },
                crossDomain: true,
                dataFilter: function (data) {
                    var json = $.parseJSON(data);
                    json.data = json.data;
                    json.recordsFiltered = json.result.recordsTotal
                    json.recordsTotal = json.result.recordsTotal
                    return JSON.stringify(json);
                },
            },
            fnServerParams: (aoData) => {

                aoData["filters"] = [{ expression: "LIKE", column: $('.select').val(), value: '%' + String(aoData.search.value).toLocaleUpperCase('TR')+ '%' },
                { "expression": "order", "column": aoData.columns[aoData.order[0].column].data, "value": aoData.order[0].dir },];
                aoData["page"] = aoData.start / aoData.length;
                aoData["per_page"] = aoData.length;
            },
            "columns": [
                { data: "id", "orderable": "false" },
                {
                    data: "id", render: function (data, type, row) {
                        return null
                    }
                },
                { data: "code", "orderable": "false" },
                { data: "name", "orderable": "false" },
                { data: "responsible_employee.name", "orderable": "false" },
                { data: "responsible_employee.phone", "orderable": "false" },
                {
                    data: "id", render: function (data, type, row) {
                        return row.get_depo_stock.length
                    }, "orderable": "false"
                },
                { data: "get_hareket_depo_list_giris_sum_miktar", "orderable": "false" },
                { data: "get_hareket_depo_list_cikis_sum_miktar", "orderable": "false" },

                {
                    data: "id", render: function (data, type, row) {
                        return parseFloat(row.get_hareket_depo_list_giris_sum_miktar) - parseFloat(row.get_hareket_depo_list_cikis_sum_miktar)
                    }, "orderable": "false"
                },
            ],
            columnDefs: [{
                data: '',
                defaultContent: '',
                orderable: false,
                className: 'select-checkbox',
                targets: 1,
                checkboxes: {
                    selectRow: true
                }
            },
            {
                data: '',
                defaultContent: '',
                orderable: false,
                className: '',
                targets: 0
            }],
            select: {
                style: 'multi',
                selector: 'td:nth-child(2)'
            },
            order: [[0, 'asc']],
            language: {
                ...(t("plugins.dataTables", { ns: "translations" })),
                paginate: {
                    'next': $('html').attr('dir') === 'rtl' ? t("plugins.dataTables.paginate.next", { ns: "translations" }) + '&larr;' : t("plugins.dataTables.paginate.next", { ns: "translations" }) + ' &rarr;',
                    'previous': $('html').attr('dir') === 'rtl' ? '&rarr; ' + t("plugins.dataTables.paginate.previous", { ns: "translations" }) : '&larr; ' + t("plugins.dataTables.paginate.previous", { ns: "translations" })
                }
            }
        });
        this.storageListTable.on('select', (e, dt, type, indexes) => this.onStorageListTableSelect(e, dt, type, indexes));
        this.storageListTable.on('deselect', (e, dt, type, indexes) => this.onStorageListTableDeselect(e, dt, type, indexes));
    }

    componentWillUnmount() {
        this.props.setPageHeader("");
        this.props.setHeaderElements([]);
        this.context.handlers = [];
    }

    onDeleteStorageButtonClick(ev) {
        const { t } = this.props;
        const _this = this;
        console.log(this.state.selectDeleteItemRows);
        if (this.state.selectDeleteItemRows === 0) {
            ReactSwal.fire({
                title: <span>{t("operations.deleteMultiple", { ns: "translations", itemCount: 1 })}</span>,
                html: ``,
                icon: 'error',
                showCloseButton: true,
                confirmButtonText: t("misc.ok", { ns: "translations" }),
            });
            return;
        }


        ReactSwal.fire({
            title: <span>{t("operations.wouldYouLikeToDeleteRecords", { ns: "translations" })}</span>,
            html: <span>{t("operations.itemsToDelete", { ns: "translations", itemCount: this.state.selectDeleteItemRows })}</span>,
            icon: '',
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: t("misc.yes", { ns: "translations" }),
            cancelButtonText: t("misc.cancel", { ns: "translations" }),
        }).then((a) => {
            if (a.isConfirmed) {

                var data = JSON.stringify({
                    "ids": [
                        _this.state.rowsToDelete
                    ]
                });

                var config = {
                    method: 'post',
                    url: `${getEndPoint()}` + "/api/storages/delete",
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: data
                };
                axios(config)
                    .then(function (response) {
                        ReactSwal.fire({
                            title: <span>{t("misc.successfull", { ns: "translations" })}</span>,
                            html: <span>{t("operations.itemsDeleted", { ns: "translations", itemCount: _this.state.selectDeleteItemRows })}</span>,
                            icon: '',
                            showCloseButton: true,
                            focusConfirm: false,
                            confirmButtonText: t("misc.ok", { ns: "translations" }),
                        })
                        _this.componentDidMount()
                    })
            }
        });
    }

    onSelectAllButtonClick(ev) {
        if (this.storageListTable == null)
            return;

        $(ev.target).parent().find("#selectAll").prop("checked", (index, oldPropertyValue) => {
            $(this.selectAllRef.current).prop("checked", !oldPropertyValue);
        });

        if ($(ev.target).parent().find("#selectAll").is(':checked')) {
            this.storageListTable.rows().select();
            this.setState({
                rowsToDelete: this.storageListTable.rows({ selected: true }).count()
            });
        } else {
            this.storageListTable.rows().deselect();
            this.setState({
                rowsToDelete: 0
            });
        }
    }

    onStorageListTableSelect(e, dt, type, indexes) {
        this.state.rowsToDelete.push(dt.data().id);
        this.setState({
            rowsToDelete: this.state.rowsToDelete,
            selectDeleteItemRows: this.storageListTable.rows({ selected: true }).count()
        });
        console.log(this.state.selectDeleteItemRows);
        if (this.storageListTable.rows({ selected: true }).count() === this.storageListTable.rows().count())
            $(this.selectAllRef.current).prop("checked", true);
    }
    onStorageListTableDeselect(e, dt, type, indexes) {
        var filteredArray = this.state.rowsToDelete.filter(function (f) { return f !== dt.data().id })
        this.setState({
            rowsToDelete: filteredArray,
            selectDeleteItemRows: this.storageListTable.rows({ selected: true }).count()
        });
        if (this.storageListTable.rows({ selected: true }).count() !== this.storageListTable.rows().count())
            $(this.selectAllRef.current).prop("checked", false);
    }
    render() {
        const { t } = this.props;

        return (
            <div className='card'>
                <div className='card-body'>
                    <div className='w-100'>
                        <table ref={this.storageListTableRef} className="table table-striped table-hover dt-responsive nowrap datatable-pagination w-100">
                            <thead className='w-100'>
                                <tr>
                                    <th>
                                        <label>Aranılacak Alan</label>

                                    </th>
                                    <th>
                                        <div className='form-group'>
                                            <select className='select' ref={this.columnSelect}>
                                                <option value="name">Depo Adı</option>
                                                <option value="code">Depo Kodu</option>
                                            </select>
                                        </div>
                                    </th>

                                </tr>
                                <tr>
                                    <th></th>
                                    <th className='select-checkbox h-100'>
                                        <div className="custom-control custom-switch d-flex justify-content-center align-items-center align-content-center" onClick={(ev) => this.onSelectAllButtonClick(ev)}>
                                            <input defaultChecked={this.state.selectDeleteItemRows === 0 ? "" : "checked"} ref={this.selectAllRef} type="checkbox" className="custom-control-input" id="selectAll" />
                                            <label className="custom-control-label" style={{ paddingLeft: "0" }} defaultValue={false} htmlFor="selectAll"></label>
                                        </div>
                                    </th>
                                    <th>{t("table.header.storageCode")}</th>
                                    <th>{t("table.header.storageName")}</th>
                                    <th>{t("table.header.storageResponsible")}</th>
                                    <th>{t("table.header.storageResponsiblePhone")}</th>
                                    <th>Stok Çeşidi</th>
                                    <th>Toplam Girilen Stok Miktarı</th>
                                    <th>Toplam Çıkan Stok Miktarı</th>
                                    <th>Güncel Stok Miktarı</th>
                                </tr>
                            </thead>
                            <tbody className='w-100'>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps())(
    withTranslation(["storage-list", "translations"])(StorageList)
);