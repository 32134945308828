import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { setBreadcrumbs } from '../../features/active-page/sub-pages-slice';
import { setPageHeader } from '../../features/active-page/page-header-slice';
import { setHeaderElements } from '../../features/active-page/header-elements-slice';
import { EventHandlerContext } from '../../contexts/event-handler-context';
import Functions from '../../class/functions';
import $ from "jquery";
import "select2";
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { getEndPoint } from "../../state/api/index"

const ReactSwal = withReactContent(Swal);
Functions.importThemeStyles();

const mapStateToProps = state => ({
    redux: {
        pageHeader: state.pageHeader,
        breadcrumbs: state.breadcrumbs,
        headerElements: state.headerElements
    }
});
const mapDispatchToProps = () => ({
    setPageHeader,
    setBreadcrumbs,
    setHeaderElements
});

class DebitAdd extends Component {
    static contextType = EventHandlerContext

    constructor(props) {
        super(props);

        this.employeeRef = React.createRef();
        this.stockRef = React.createRef();
        this.state = {
            stocks: [
                React.createRef()
            ]
        };
        this.createSelect = (ref, t) => {
            $(ref.current).select2({
                width: "100%",
                height: "40px",
                language: {
                    noResults: () => t("plugins.select2.noResults", { ns: "translations" }),
                    searching: () => t("plugins.select2.searching", { ns: "translations" }),
                    maximumSelected: () => t("plugins.select2.maximumSelected", { ns: "translations" }),
                    loadingMore: () => t("plugins.select2.loadingMore", { ns: "translations" }),
                    inputTooShort: () => t("plugins.select2.inputTooShort", { ns: "translations" }),
                    inputTooLong: () => t("plugins.select2.inputTooLong", { ns: "translations" }),
                    errorLoading: () => t("plugins.select2.errorLoading", { ns: "translations" }),
                }
            });


        }
        this.createSelects = () => {
            const { t } = this.props;
            this.state.stocks.map((ref, index) => {
                if (ref === undefined || ref === null) return;
                Functions.createSelectList(ref, t, 'stocks');
            });
        };
    }

    componentDidMount() {
        const { t } = this.props;
        const _this = this;
        $(this.employeeRef.current).select2()
        this.props.setPageHeader(t("pages.stocks.add", { ns: "translations" }));
        this.props.setHeaderElements([
            {
                componentId: "add-stock",
                href: "#", translation: { key: "misc.add", opts: { ns: "translations" } },
                className: "btn btn-link btn-float text-body", icon: "fas fa-plus text-primary",
            },
            {
                componentId: "save-debit",
                href: "#", translation: { key: "misc.save", opts: { ns: "translations" } },
                className: "btn btn-link btn-float text-body", icon: "fas fa-save text-primary",
            },
        ]);
        var config = {
            method: 'get',
            url: `${getEndPoint()}/api/users/personel`,
            headers: {
                'Content-Type': 'application/json',
            }
        };

        axios(config)
            .then(function (response) {
                var selectData = response.data.data
                $(_this.employeeRef.current).empty().trigger("change");
                var selects = $(_this.employeeRef.current);

                var option = new Option(
                    'Lütfen Seçim Yapınız...',
                    0,
                    true,
                    true);
                selects.append(option).trigger('change');
                selectData.forEach((elm, index) => {

                    var option = new Option(
                        elm.name,
                        elm.id,
                        false,
                        false);
                    selects.append(option).trigger('change');
                });


            })
        this.context.addEventHandler("save-debit", (ev) => this.onSaveStockButtonClick(ev));
        this.context.addEventHandler("add-stock", (ev) => this.onAddStockButtonClick(ev));

        this.createSelects();
    }

    componentDidUpdate() {
        this.createSelects();
    }

    componentWillUnmount() {
        this.props.setPageHeader("");
        this.props.setHeaderElements([]);
        this.context.handlers = [];
        // this.setState({stocks:[]});
    }

    onSaveStockButtonClick(ev) {
        const { t } = this.props;
        const _this = this;
        var demo = [];
        this.state.stocks.map((elm, index) => {
            demo.push(parseInt(elm.current.value));
        })
        var data = JSON.stringify({
            "stock_id": demo,
            "responsible_employee_id": _this.employeeRef.current.value
        });
        var config = {
            method: 'post',
            url: `${getEndPoint()}/api/stocks/debit-add`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                ReactSwal.fire({
                    title: <span>{t("misc.successfull", { ns: "translations" })}</span>,
                    html: <span>{t("operations.successMessage", { ns: "translations" })}</span>,
                    icon: '',
                    showCloseButton: true,
                    focusConfirm: false,
                    confirmButtonText: t("misc.ok", { ns: "translations" }),
                })

            })
        _this.componentDidMount();
    }
    onAddStockButtonClick(ev) {


        this.state.stocks.push(React.createRef());
        this.setState({
            stocks: this.state.stocks
        });
    }

    onRemoveStockButtonClick(ev, ref, index) {
        this.state.stocks.splice(index, 1);
        this.setState({
            stocks: this.state.stocks
        });
    }

    render() {
        const { t } = this.props;

        return (
            <>
                <div className="card p-1 m-0">
                    <div className="card-body p-1 m-0">

                        <div className='row'>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="employee">{t("employee")}</label>
                                    <select ref={this.employeeRef} id="employee" name="employee" data-placeholder={t("placeholders.selectEmployee")} className="form-control select" data-fouc>
                                        <option value="0">Yükleniyor...</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-12">
                                {this.state.stocks && this.state.stocks.map((element, index) => {
                                    return (
                                        <div key={`stocks-${index}`} className="form-group">
                                            {index !== 0 && <a href="#" onClick={(ev) => this.onRemoveStockButtonClick(ev, element, index)}>
                                                <span className="mr-2 text-danger"><i className="fas fa-window-close"></i></span>
                                            </a>}
                                            <label htmlFor="stock">{t("stock")} {index + 1}</label>
                                            <select ref={element} id="stock" name="stock" data-placeholder={t("placeholders.selectStock")} className="form-control select" data-fouc>
                                                <option value="0">Yükleniyor...</option>
                                            </select>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                    </div>
                </div>
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps())(
    withTranslation(["debit-add", "translations"])(DebitAdd)
);