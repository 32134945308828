import { createSlice } from '@reduxjs/toolkit'

export const breadcrumbsSlice = createSlice({
  name: 'breadcrumbs',
  initialState: {
    value: [],
  },
  reducers: {
    setBreadcrumbs(state, action) {

        state.value = action.payload;
    }
  },
})

export const { setBreadcrumbs } = breadcrumbsSlice.actions

export default breadcrumbsSlice.reducer