import React, { Component } from "react";
import { ReactSession } from "react-client-session";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import i18n, { getLanguages } from "../i18n/i18n";
import $ from "jquery";
import * as bootstrap from "bootstrap";
import Functions from "../class/functions";

import { connect } from "react-redux";
import { EventHandlerContext } from "../contexts/event-handler-context";

import { setTheme } from "../features/layout/theme-slice";
import { setUser } from "../features/user/user-slice";
import Swal from "sweetalert2";
import axios from "axios";
import { getEndPoint } from "../state/api/index";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const mapStateToProps = (state) => ({
  redux: {
    pageHeader: state.pageHeader,
    breadcrumbs: state.breadcrumbs,
    headerElements: state.headerElements,
    theme: state.theme,
    user: state.user,
  },
});
const mapDispatchToProps = () => ({
  setTheme: setTheme,
  setUser: setUser,
});

class Layout extends Component {
  constructor(props) {
    super(props);

    this.userData = ReactSession.get("userData");
    const theme =
      ReactSession.get("theme") === null ||
      ReactSession.get("theme") === undefined
        ? "light"
        : ReactSession.get("theme");
    this.state = {
      pathname: props.router.location.pathname,
      visibilityOfGoBackButton: this.props.router.location.pathname !== "/",
      handlers: [],
      addEventHandler: (c, h) => {
        let eventHandlers = this.state.handlers;
        eventHandlers.push({
          componentId: c,
          handler: h,
        });
        this.setState({
          handlers: eventHandlers,
        });
      },
      clearEventHandlers: () => {
        this.setState({ handlers: [] });
      },
      theme: {
        type: theme,
      },
      //themeObject: require(``)
    };

    $("#theme").prop("href", `../css/all-${theme}.min.css`);
  }

  componentDidMount() {
    this.processSidebarActivity();

    var dropdownElementList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="dropdown"]')
    );
    var dropdownList = dropdownElementList.map(function (dropdownToggleEl) {
      let dropdown = new bootstrap.Dropdown(dropdownToggleEl);
      $(dropdown).on("click", function (ev) {
        dropdown.show();
      });
      return dropdown;
    });
    if (cookies.get("warningCriticalStocks") == undefined) {
      ReactSession.remove("warningCriticalStocks");
    }
    if (ReactSession.get("warningCriticalStocks") == undefined) {
      var config = {
        method: "get",
        url: `${getEndPoint()}/api/stocks/critical-list`,
        headers: {
          "Content-Type": "application/json",
        },
      };
      axios(config).then(function (response) {
        var ciriticalStockList = "";
        response.data.data.forEach((element, index) => {
          var miktar =
            element.get_hareket_stock_list_giris_sum_miktar -
            element.get_hareket_stock_list_cikis_sum_miktar;
          ciriticalStockList +=
            "Stok Adı: " +
            element.stock_name +
            " - Stok Miktarı: " +
            miktar +
            "\n <br/>";
        });

        Swal.fire(
          "Kritik Stok Miktarının Altındaki Ürünler!",
          ciriticalStockList,
          "error"
        );
        cookies.set("warningCriticalStocks", "true", {
          path: "/",
          expires: new Date(Date.now() + 7200000),
        });
        ReactSession.set("warningCriticalStocks", "notificationNot");
      });
    }
  }

  componentDidUpdate() {
    if (this.props.router.location.pathname !== this.state.pathname) {
      this.setState({
        visibilityOfGoBackButton: this.props.router.location.pathname !== "/",
        pathname: this.props.router.location.pathname,
      });
    }
  }

  processSidebarActivity() {
    if (this.props.DisableLayout === true) return;

    $(".nav-item-submenu").each((index, element) => {
      $(element).find("ul").css("display", "none");
      $(element).find("ul").parent().removeClass("nav-item-open");
    });
    $(".nav-sidebar>.nav-item>a[class*='active']").removeClass("active");
    let link = window.location.pathname;

    let matchedItems = $("ul.nav-sidebar").find("a[href$='" + link + "']");

    matchedItems.addClass("active");
    if (matchedItems.parent().parent().hasClass("nav-group-sub")) {
      matchedItems.parent().parent().css("display", "block");
    }
    if (matchedItems.parent().parent().parent().hasClass("nav-item-submenu")) {
      matchedItems.parent().parent().parent().addClass("nav-item-open");
    }
    Functions.changeTitle(matchedItems.last().text());

    $(".nav:not(.nav-tabs):not(.nav-pills)>.nav-item>.nav-link").on(
      "click",
      function (ev) {
        //if ($(this).parent().parent().hasClass("nav-tabs") || $(this).parent().parent().hasClass("nav-pills")) return;
        $(
          ".nav:not(.nav-tabs):not(.nav-pills)>.nav-item>.nav-link"
        ).removeClass("active");
        $(this).addClass("active");
        Functions.changeTitle($(this).text());

        // if (!$(this).parent().parent().parent().hasClass("nav-item-submenu")) {
        //     var openedMenus = $(".nav-item-submenu.nav-item-open");
        //     openedMenus.find("ul").slideUp(250);
        //     openedMenus.removeClass("nav-item-open");
        // }
      }
    );
    // $(".nav-link").on("click", function (ev) {
    //     $(".nav-sidebar>.nav-item>.nav-link").removeClass("active");
    //     $(".nav-group-sub>.nav-item>.nav-link").removeClass("active");
    //     $(this).addClass("active");
    // });
  }

  onChangeLanguageClick(ev, language) {
    ReactSession.set("selectedLanguage", language);
    i18n.changeLanguage(language);
  }

  onGoBackButtonClick(ev) {
    let location = window.location.pathname;
    let locations = location.split("/");
    locations.pop();
    let joinedLocations = locations.join("/");
    let newLocation =
      locations.length === 1 ? "/" + joinedLocations : joinedLocations;
    this.props.router.navigate(newLocation);
    this.processSidebarActivity();
  }

  onThemeChangeButtonClick(ev) {
    const theme =
      ev.target.checked === true ? { type: "dark" } : { type: "light" };
    $("#theme").prop("href", `../css/all-${theme.type}.min.css`);
    ReactSession.set("theme", theme.type);
    this.setState({ theme });
  }

  render() {
    const { t } = this.props;
    const placeholder = require("../img/placeholders/placeholder.jpg");
    const logo = require("../img/logo/kobis.png");

    return (
      <EventHandlerContext.Provider value={this.state}>
        {this.props.DisableLayout === false ? (
          <>
            <noscript>{t("noscript", { ns: "translations" })}</noscript>
            <div className="navbar navbar-expand-lg navbar-dark navbar-static">
              <div className="d-flex flex-1 d-lg-none">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-target="#navbar-mobile"
                >
                  <i className="icon-paragraph-justify3"></i>
                </button>
                <button
                  className="navbar-toggler sidebar-mobile-main-toggle"
                  type="button"
                >
                  <i className="icon-transmission"></i>
                </button>
              </div>

              <div
                className="navbar-brand text-center text-lg-center p-2"
                onClick={() => this.processSidebarActivity()}
              >
                <Link target="_blank" to="/" className="d-inline-block">
                  <img
                    src={logo}
                    className="d-none d-sm-block"
                    style={{ height: "75px", width: "auto" }}
                    alt=""
                  />
                </Link>
              </div>

              <div
                className="collapse navbar-collapse order-2 order-lg-1"
                id="navbar-mobile"
              >
                <ul className="navbar-nav ml-lg-auto">
                  <li className="nav-item d-flex justify-content-center align-items-center align-content-center ">
                    <div className="navbar-nav-link custom-control custom-switch">
                      <i className="mr-3 fas fa-sun"></i>
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        onChange={(ev) => this.onThemeChangeButtonClick(ev)}
                        id="themeToggler"
                        defaultChecked={
                          ReactSession.get("theme") === null
                            ? false
                            : ReactSession.get("theme") === "dark"
                            ? true
                            : false
                        }
                      />
                      <label
                        className="custom-control-label pl-0"
                        htmlFor="themeToggler"
                      ></label>
                      <i className="ml-3 fas fa-moon"></i>
                    </div>
                  </li>
                  <li className="nav-item dropdown">
                    {/* <a href="#abc" className="navbar-nav-link" data-bs-toggle="dropdown">
                                                    <i className="icon-earth"></i>
                                                    <span className="d-lg-none ml-3">{t("languages.language", { ns: "translations" })}</span>
                                                </a> */}

                    <div className="dropdown-menu dropdown-menu-right dropdown-content wmin-lg-300">
                      <div className="dropdown-content-header">
                        <span className="font-weight-semibold">
                          {t("languages.language", { ns: "translations" })}
                        </span>
                      </div>

                      <div className="dropdown-content-body dropdown-scrollable">
                        <ul className="media-list">
                          {getLanguages().map((language, index) => {
                            return (
                              <li key={language} className="media">
                                <div className="mr-3">
                                  <img
                                    src={`../img/flags/${language}.png`}
                                    width="36"
                                    height="36"
                                    className="rounded-circle"
                                    alt=""
                                  />
                                </div>
                                <div className="media-body align-self-center">
                                  <a
                                    onClick={(ev) =>
                                      this.onChangeLanguageClick(ev, language)
                                    }
                                    href={`#${language}`}
                                    className="media-title font-weight-bold h-100 p-0 m-0"
                                  >
                                    {t(`languages.${language}`, {
                                      ns: "translations",
                                    })}
                                  </a>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <ul className="navbar-nav flex-row order-1 order-lg-2 flex-1 flex-lg-0 justify-content-end align-items-center">
                <li className="nav-item nav-item-dropdown-lg dropdown dropdown-user h-100">
                  <a
                    href="#abc"
                    className="navbar-nav-link navbar-nav-link-toggler dropdown-toggle d-inline-flex align-items-center h-100"
                    data-bs-toggle="dropdown"
                  >
                    <img
                      src={placeholder}
                      className="rounded-pill mr-lg-2"
                      height="34"
                      alt=""
                    />
                    <span className="d-none d-lg-inline-block">
                      {this.userData.name}
                    </span>
                  </a>

                  <div className="dropdown-menu dropdown-menu-right">
                    <Link
                      onClick={() => this.processSidebarActivity()}
                      to="/settings/profile"
                      className="dropdown-item"
                    >
                      <i className="icon-cog"></i>{" "}
                      {t("pages.settings.profile", { ns: "translations" })}
                    </Link>
                    <div className="dropdown-divider"></div>
                    <Link to="/logout" className="dropdown-item">
                      <i className="icon-switch2"></i>{" "}
                      {t("pages.logout", { ns: "translations" })}
                    </Link>
                  </div>
                </li>
              </ul>
            </div>

            <div className="page-content">
              <div className="sidebar sidebar-dark sidebar-main sidebar-expand-lg">
                <div className="sidebar-content">
                  <div className="sidebar-section sidebar-user my-1">
                    <div className="sidebar-section-body">
                      <div className="media">
                        <a href="#abc" className="mr-3">
                          <img
                            src={placeholder}
                            className="rounded-circle"
                            alt=""
                          />
                        </a>

                        <div className="media-body">
                          <div className="font-weight-semibold">
                            {this.userData.name}
                          </div>
                          <div className="font-size-sm line-height-sm opacity-50"></div>
                        </div>

                        <div className="ml-3 align-self-center">
                          <button
                            type="button"
                            className="btn btn-outline-light-100 text-white border-transparent btn-icon rounded-pill btn-sm sidebar-control sidebar-main-resize d-none d-lg-inline-flex"
                          >
                            <i className="icon-transmission"></i>
                          </button>

                          <button
                            type="button"
                            className="btn btn-outline-light-100 text-white border-transparent btn-icon rounded-pill btn-sm sidebar-mobile-main-toggle d-lg-none"
                          >
                            <i className="icon-cross2"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="sidebar-section">
                    <ul className="nav nav-sidebar" data-nav-type="accordion">
                      <li className="nav-item-header">
                        <div className="text-uppercase font-size-xs line-height-xs">
                          Main
                        </div>{" "}
                        <i className="icon-menu" title="Main"></i>
                      </li>
                      <li className="nav-item">
                        <Link target="_blank" to="/" className="nav-link">
                          <i className="fas fa-home"></i>
                          <span className="fa-margin-fix">
                            {t("pages.dashboard", { ns: "translations" })}
                          </span>
                        </Link>
                      </li>

                      <li className="nav-item nav-item-submenu">
                        <Link target="_blank" to="/stocks" className="nav-link">
                          <i className="fas fa-box fa-margin-fix"></i>
                          <span className="fa-margin-fix">
                            {t("pages.stocks.stocks", { ns: "translations" })}
                          </span>
                        </Link>

                        <ul
                          className="nav nav-group-sub"
                          data-submenu-title="Stocks"
                        >
                          <li className="nav-item">
                            <Link
                              target="_blank"
                              to="/stocks"
                              className="nav-link"
                            >
                              <i className="fas fa-box"></i>
                              Stok Listesi
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              target="_blank"
                              to="/stocks/add"
                              className="nav-link"
                            >
                              <i className="fas fa-plus"></i>
                              Yeni Stok Kartı
                            </Link>
                          </li>

                          <li className="nav-item">
                            <Link
                              target="_blank"
                              to="/stocks/entry-stock"
                              className="nav-link"
                            >
                              <i className="fas fa-minus-square"></i>
                              Stok Girişi
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              target="_blank"
                              to="/stocks/out-stock"
                              className="nav-link"
                            >
                              <i className="fas fa-minus-square"></i>
                              Stok Çıkışı
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              target="_blank"
                              to="/stocks/entries-stock"
                              className="nav-link"
                            >
                              <i className="fas fa-minus-square"></i>
                              Stok Giriş Raporu
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              target="_blank"
                              to="/stocks/counting-stock"
                              className="nav-link"
                            >
                              <i className="fas fa-minus-square"></i>
                              Stok Çıkış Raporu
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item nav-item-submenu">
                        <Link
                          target="_blank"
                          to="/storages"
                          className="nav-link"
                        >
                          <i className="fas fa-warehouse"></i>
                          <span>
                            {t("pages.storages.storages", {
                              ns: "translations",
                            })}
                          </span>
                        </Link>

                        <ul
                          className="nav nav-group-sub"
                          data-submenu-title="Storages"
                        >
                          <li className="nav-item">
                            <Link
                              target="_blank"
                              to="/storages"
                              className="nav-link"
                            >
                              <i className="fas fa-warehouse"></i>
                              <span>
                                {t("pages.storages.storages", {
                                  ns: "translations",
                                })}
                              </span>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              target="_blank"
                              to="/storages/counting"
                              className="nav-link"
                            >
                              <i className="fas fa-hashtag fa-margin-fix"></i>
                              <span className="fa-margin-fix">
                                {t("pages.storages.counting", {
                                  ns: "translations",
                                })}
                              </span>
                            </Link>
                          </li>

                          <li className="nav-item">
                            <Link
                              target="_blank"
                              to="/storages/transfer"
                              className="nav-link"
                            >
                              <i className="fas fa-exchange-alt fa-margin-fix"></i>
                              <span className="fa-margin-fix">
                                {t("pages.storages.transfer", {
                                  ns: "translations",
                                })}
                              </span>
                            </Link>
                          </li>

                          <li className="nav-item">
                            <Link
                              target="_blank"
                              to="/storages/add"
                              className="nav-link"
                            >
                              <i className="fas fa-plus fa-margin-fix"></i>
                              <span className="fa-margin-fix">
                                {t("pages.storages.add", {
                                  ns: "translations",
                                })}
                              </span>
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item nav-item-submenu">
                        <Link target="_blank" to="Debit" className="nav-link">
                          <i className="fas fa-money-bill"></i>
                          <span>
                            {t("pages.debit.debit", { ns: "translations" })}
                          </span>
                        </Link>

                        <ul
                          className="nav nav-group-sub"
                          data-submenu-title="Debit"
                        >
                          <li className="nav-item">
                            <Link
                              target="_blank"
                              to="/debit/tracking"
                              className="nav-link"
                            >
                              <i className="fas fa-credit-card"></i>
                              {t("pages.debit.tracking", {
                                ns: "translations",
                              })}
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              target="_blank"
                              to="/debit/add"
                              className="nav-link"
                            >
                              <i className="fas fa-plus fa-margin-fix"></i>
                              <span className="fa-margin-fix">
                                {t("pages.debit.add", { ns: "translations" })}
                              </span>
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item nav-item-submenu">
                        <Link target="_blank" to="/talep" className="nav-link">
                          <i className="fas fa-file-excel mr-3 "></i>
                          <span>Otomatik Talep Oluşturma</span>
                        </Link>
                      </li>
                      <li className="nav-item nav-item-submenu">
                        <Link
                          target="_blank"
                          to="/fiyat-analizi"
                          className="nav-link"
                        >
                          <i className="fas fa-money-check-alt mr-3 "></i>
                          <span>Fiyat Geçmişi</span>
                        </Link>
                      </li>
                      <li className="nav-item nav-item-submenu">
                        <Link target="_blank" to="/birim" className="nav-link">
                          <i className="fas fa-box mr-3 "></i>
                          <span>Birim</span>
                        </Link>
                      </li>
                      <li className="nav-item nav-item-submenu">
                        <Link
                          target="_blank"
                          to="/personel"
                          className="nav-link"
                        >
                          <i className="fas fa-user-friends"></i>
                          <span className="fa-margin-fix">
                            Personel Tanımları
                          </span>
                        </Link>

                        <ul
                          className="nav nav-group-sub"
                          data-submenu-title="Personel"
                        >
                          <li className="nav-item">
                            <Link
                              target="_blank"
                              to="/personel"
                              className="nav-link"
                            >
                              <i className="fas fa-user-friends"></i>
                              <span className="fa-margin-fix">Personeller</span>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              target="_blank"
                              to="/personel/ekle"
                              className="nav-link"
                            >
                              <i className="fas fa-plus fa-margin-fix"></i>
                              <span className="fa-margin-fix">
                                {t("pages.company.add", { ns: "translations" })}
                              </span>
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item nav-item-submenu">
                        <Link
                          target="_blank"
                          to="/company"
                          className="nav-link"
                        >
                          <i className="fas fa-industry  fa-margin-fix"></i>
                          <span className="fa-margin-fix">Firma Tanımları</span>
                        </Link>

                        <ul
                          className="nav nav-group-sub"
                          data-submenu-title="Company"
                        >
                          <li className="nav-item">
                            <Link
                              target="_blank"
                              to="/company"
                              className="nav-link"
                            >
                              <i className="fas fa-industry fa-margin-fix"></i>
                              <span className="fa-margin-fix">Firmalar</span>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              target="_blank"
                              to="/company/add"
                              className="nav-link"
                            >
                              <i className="fas fa-plus fa-margin-fix"></i>
                              <span className="fa-margin-fix">
                                {t("pages.company.add", { ns: "translations" })}
                              </span>
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item nav-item-submenu">
                        <Link target="_blank" to="/users" className="nav-link">
                          <i className="fas fa-user fa-margin-fix"></i>
                          <span className="fa-margin-fix">
                            {t("pages.users.users", { ns: "translations" })}
                          </span>
                        </Link>

                        <ul
                          className="nav nav-group-sub"
                          data-submenu-title="user"
                        >
                          <li className="nav-item">
                            <Link
                              target="_blank"
                              to="/users"
                              className="nav-link"
                            >
                              <i className="fas fa-user"></i>
                              {t("pages.users.users", { ns: "translations" })}
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              target="_blank"
                              to="/users/add"
                              className="nav-link"
                            >
                              <i className="fas fa-plus"></i>

                              {t("pages.users.add", { ns: "translations" })}
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item nav-item-submenu">
                        <Link
                          target="_blank"
                          to="/qr-code"
                          className="nav-link"
                        >
                          <i className="fas fa-qrcode "></i>
                          <span className="fa-margin-fix">QR</span>
                        </Link>
                      </li>
                      <li className="nav-item nav-item-submenu">
                        <Link
                          target="_blank"
                          to="/settings"
                          className="nav-link"
                        >
                          <i className="fas fa-cog  fa-margin-fix"></i>
                          <span className="fa-margin-fix">
                            {t("pages.settings.settings", {
                              ns: "translations",
                            })}
                          </span>
                        </Link>

                        <ul
                          className="nav nav-group-sub"
                          data-submenu-title="Profile"
                        >
                          <li className="nav-item">
                            <Link
                              target="_blank"
                              to="/settings/profile"
                              className="nav-link"
                            >
                              <i className="fas fa-user fa-margin-fix"></i>
                              <span className="fa-margin-fix">
                                {t("pages.settings.profile", {
                                  ns: "translations",
                                })}
                              </span>
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="content-wrapper">
                <div className="page-header page-header-light">
                  <div className="page-header-content header-elements-lg-inline">
                    <div className="page-title d-flex">
                      <h4>
                        <i
                          onClick={(ev) => this.onGoBackButtonClick(ev)}
                          className={
                            this.state.visibilityOfGoBackButton
                              ? "icon-arrow-left52 mr-2"
                              : "d-none icon-arrow-left52 mr-2"
                          }
                        ></i>{" "}
                        {this.props.redux.pageHeader.value}
                      </h4>
                      <a
                        href="#"
                        className="header-elements-toggle text-body d-lg-none"
                      >
                        <i className="icon-more"></i>
                      </a>
                    </div>

                    <div className="header-elements d-none">
                      <div className="d-flex justify-content-center">
                        <EventHandlerContext.Consumer>
                          {(value) =>
                            this.props.redux.headerElements.value &&
                            this.props.redux.headerElements.value.map(
                              (HeaderElement, index) => {
                                const {
                                  componentId,
                                  translation,
                                  icon,
                                  ...excludedProps
                                } = HeaderElement;
                                let eventHandler = value.handlers.find(
                                  (x) =>
                                    x.componentId === HeaderElement.componentId
                                );
                                //const aElement = React.useRef()
                                return (
                                  <a
                                    id={componentId}
                                    onClick={
                                      eventHandler &&
                                      ((ev) => eventHandler.handler(ev))
                                    }
                                    key={`headerelement-${index}`}
                                    {...excludedProps}
                                    data-toggle="modal"
                                    data-target="#Modal"
                                  >
                                    {HeaderElement.icon && (
                                      <i
                                        className={`${HeaderElement.icon}`}
                                      ></i>
                                    )}
                                    {HeaderElement.translation && (
                                      <span>
                                        {t(
                                          HeaderElement.translation.key,
                                          HeaderElement.translation.opts
                                        )}
                                      </span>
                                    )}
                                  </a>
                                );
                              }
                            )
                          }
                        </EventHandlerContext.Consumer>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="content-inner">
                  <div className="content">
                    {this.props.Content && this.props.Content}
                  </div>
                </div>
                <div className="navbar navbar-expand-lg navbar-light border-bottom-0 border-top">
                  <div className="text-center d-lg-none w-100">
                    <button
                      type="button"
                      className="navbar-toggler dropdown-toggle"
                      data-bs-toggle="collapse"
                      data-target="#navbar-footer"
                    >
                      <i className="icon-unfold mr-2"></i>
                      Footer Section
                    </button>
                  </div>

                  <div className="navbar-collapse collapse" id="navbar-footer">
                    <span className="navbar-text">
                      © {new Date().getFullYear()} - Ferofen Bike
                    </span>

                    <ul className="navbar-nav ml-lg-auto">
                      <li className="nav-item">
                        <a href="#" className="navbar-nav-link" target="_blank">
                          <i className="icon-home2 mr-2"></i> v1.4
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          this.props.Content && this.props.Content
        )}
      </EventHandlerContext.Provider>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(withTranslation(["layout", "translations"])(Functions.withRouter(Layout)));
