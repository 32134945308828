import React, { Component } from "react";
import QRCode from "qrcode";
import Functions from "../class/functions";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { setBreadcrumbs } from "../features/active-page/sub-pages-slice";
import { setPageHeader } from "../features/active-page/page-header-slice";
import { setHeaderElements } from "../features/active-page/header-elements-slice";
import axios from "axios";

import $ from "jquery";
const qrCodesList = [[" "]];
const mapStateToProps = (state) => ({
  redux: {
    pageHeader: state.pageHeader,
    breadcrumbs: state.breadcrumbs,
    headerElements: state.headerElements,
  },
});
const mapDispatchToProps = () => ({
  setPageHeader,
  setBreadcrumbs,
  setHeaderElements,
});

class QrCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      qrCodes: [[React.createRef()]],
    };
    this.pageStatus = true;
    this.qrCodeRef = React.createRef();
    this.qrCodeRef2 = React.createRef();
    this.qrCodeValue = React.createRef();
    this.qrList = this.qrList.bind(this);
    // this.qrCodeLoad = this.qrCodeLoad.bind(this);
  }
  componentDidUpdate() {
    const _this = this;

    this.state.qrCodes.map((ref, index) => {
      console.log(
        qrCodesList[index] == ""
          ? (qrCodesList[index] = " ")
          : qrCodesList[index]
      );
      QRCode.toCanvas(
        ref.current,
        qrCodesList[index],
        {
          scale: 12,
          errorCorrectionLevel: "L",
        },
        function (error) {
          if (error) console.error(error);
        }
      );

      if (this.pageStatus != true) {
        if (index == this.state.qrCodes.length - 1) {
          Functions.printCanvasALL(
            _this.qrCodeRef.current,
            _this.state.qrCodes,
            $("#qrGenislik").val(),
            $("#qrGenislik").val(),
            $("#qrText").val().split("\n")
          );
        }
      }
    });
    this.pageStatus = true;
  }
  componentDidMount() {
    const { t } = this.props;
    const _this = this;

    this.props.setPageHeader("QR Code");
    this.props.setHeaderElements([]);
    this.props.setBreadcrumbs([]);
    QRCode.toCanvas(
      this.qrCodeRef.current,
      "1",
      {
        scale: 12,
      },
      function (error) {
        if (error) console.error(error);
      }
    );
  }
  onGenerateQRCodeClick(ev) {
    if (this.qrCodeRef == null || this.qrCodeRef.current == null) return;

    QRCode.toCanvas(
      this.qrCodeRef.current,
      this.qrCodeValue.current.value,
      {
        scale: 12,
      },
      function (error) {
        if (error) console.error(error);
      }
    );
  }
  // qrCodeLoad(e) {
  //     this.state.qrCodes.map((ref, index) => {
  //         console.log('girdi');
  //         console.log(this.state);
  //         QRCode.toCanvas(ref.current, "1", {
  //             scale: 12
  //         }, function (error) {
  //             if (error) console.error(error);
  //         })
  //     })
  // }
  onRemoveStockButtonClick(ev, element, index) {
    this.state.qrCodes.splice(index, 1);
    qrCodesList.splice(index, 1);
    this.setState({
      qrCodes: this.state.qrCodes,
    });

    this.componentDidUpdate();
  }
  // onAddStockButtonClick(ev) {

  //     this.qrList()
  //     this.qrCodeLoad()
  //     // this.state.qrCodes.push(React.createRef());
  //     // this.setState({
  //     //     qrCodes: this.state.qrCodes
  //     // });
  // }
  // onGenerateQRCodeClick(ev) {
  //     if (this.qrCodeRef == null || this.qrCodeRef.current == null) return;

  //     QRCode.toCanvas(this.qrCodeRef.current, this.qrCodeValue.current.value, {
  //         scale: 12
  //     }, function (error) {
  //         if (error) console.error(error);
  //     })
  // }
  // componentDidUpdate() {

  // }

  onGenerateQrListClick(ev) {
    if (this.qrCodeRef == null || this.qrCodeRef.current == null) return;

    QRCode.toCanvas(
      this.qrCodeRef.current,
      this.qrCodeValue.current.value,
      {
        scale: 12,
      },
      function (error) {
        if (error) console.error(error);
      }
    );

    ///

    var qrTextList = $("#qrText").val();
    var qrExplodeText = qrTextList.split("\n");
  }

  qrList() {
    const _this = this;
    this.pageStatus = false;

    var qrTextList = $("#qrText").val();
    var qrExplodeText = qrTextList.split("\n");
    this.state.qrCodes.splice(0, this.state.qrCodes.length);
    qrCodesList.splice(0, qrCodesList.length);
    qrExplodeText.forEach((element, index) => {
      this.state.qrCodes.push(React.createRef());
      qrCodesList.push(element);
      this.setState({
        qrCodes: this.state.qrCodes,
      });
    });
  }

  render() {
    return (
      <div className="homepage">
        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <div className="input-group">
                <canvas
                  className="form-control h-auto p-2"
                  ref={this.qrCodeRef}
                ></canvas>
              </div>
              <div className="input-group mt-2">
                <input
                  ref={this.qrCodeValue}
                  className="form-control"
                  placeholder="QR Kod Oluşturulacak Yazı"
                  type="text"
                />
              </div>
              <div className="input-group mt-2">
                <div className="p-1 w-50">
                  <button
                    onClick={() => this.onGenerateQRCodeClick()}
                    className="btn btn-primary w-100"
                  >
                    <i className="fas fa-qrcode m-2"></i> Oluştur
                  </button>
                </div>
                <div className="p-1 w-50">
                  <button
                    onClick={() =>
                      Functions.printCanvas(this.qrCodeRef.current)
                    }
                    className="btn btn-primary w-100"
                  >
                    <i className="fas fa-print m-2"></i> Yazdır
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="form-group">
              <div className="input-group mb-2">
                <div className="col-6 px-0">
                  <input
                    className="form-control"
                    placeholder="Genişlik (CM)"
                    type="text"
                    id="qrGenislik"
                  />
                </div>
                <div className="col-6 px-0">
                  <input
                    className="form-control"
                    placeholder="Yükseklik (CM)"
                    type="text"
                    id="qrYukseklik"
                  />
                </div>
              </div>
              <div className="input-group">
                <textarea
                  id="qrText"
                  style={{ height: "514px" }}
                  className="form-control"
                  placeholder="Oluşturulacak QR Listesi"
                  type="text"
                />
              </div>

              <div className="input-group mt-2">
                <div className="input-group ">
                  {/* <div className='p-1 w-50'>
                                        <button onClick={() => this.onGenerateQRCodeClick()} className="btn btn-primary w-100"><i className='fas fa-qrcode m-2'></i> Listeye Ekle</button>
                                    </div> */}
                  {/* <div className='p-1 w-50'>  
                                    <button onClick={() => this.onGenerateQrListClick()} className="btn btn-primary w-100"><i className='fas fa-qrcode m-2'></i> Oluştur</button>
                                </div> */}
                  <div className="p-1 col">
                    <button
                      onClick={this.qrList}
                      className="btn btn-primary w-100"
                    >
                      <i className="fas fa-print m-2"></i>Listeyi Oluştur
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-4">
            {this.state.qrCodes &&
              this.state.qrCodes.map((element, index) => {
                return (
                  <div hidden key={index} className="form-group ">
                    {index !== 0 && (
                      <a
                        href="#"
                        onClick={(ev) =>
                          this.onRemoveStockButtonClick(ev, element, index)
                        }
                      >
                        <span className="mr-2 text-danger">
                          <i className="fas fa-window-close"></i>
                        </span>
                      </a>
                    )}
                    <div className="input-group ">
                      <canvas
                        className="form-control h-auto p-2"
                        ref={element}
                      ></canvas>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(withTranslation(["homepage", "translations"])(Functions.withRouter(QrCode)));
