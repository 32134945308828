import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { setBreadcrumbs } from "../../features/active-page/sub-pages-slice";
import { setPageHeader } from "../../features/active-page/page-header-slice";
import { setHeaderElements } from "../../features/active-page/header-elements-slice";
import { EventHandlerContext } from "../../contexts/event-handler-context";
import Functions from "../../class/functions";
import $ from "jquery";
import "select2";
import axios from "axios";
import { getEndPoint } from "../../state/api/index";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { Chart } from "react-google-charts";
import { DateRangePickerHandler } from "../../js/plugins/pickers/daterangepicker.js";
import moment from "moment";

const ReactSwal = withReactContent(Swal);
Functions.importThemeStyles();

const mapStateToProps = (state) => ({
  redux: {
    pageHeader: state.pageHeader,
    breadcrumbs: state.breadcrumbs,
    headerElements: state.headerElements,
  },
});
const mapDispatchToProps = () => ({
  setPageHeader,
  setBreadcrumbs,
  setHeaderElements,
});

class FiyatAnalizi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: "",
    };
    this.ilkTarih = React.createRef();
    this.sonTarih = React.createRef();
    this.stockCode = React.createRef();
    const _this = this;
    var config = {
      method: "get",
      url: `${getEndPoint()}/api/stocks/all`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config).then(function (response) {
      var selectData = response.data.data;
      $(_this.stockCode.current).empty().trigger("change");
      var selects = $(_this.stockCode.current);

      var option = new Option("Lütfen Seçim Yapınız...", 0, true, true);
      selects.append(option).trigger("change");
      selectData.forEach((elm, index) => {
        var option = new Option(elm.stock_name, elm.stock_code, false, false);
        selects.append(option).trigger("change");
      });
    });
  }

  componentDidMount() {
    const { t } = this.props;
    let _this = this;

    this.props.setPageHeader("Fiyat Analizi");
    DateRangePickerHandler(this.ilkTarih.current, {
      parentEl: ".content-inner",
      showDropdowns: true,
      singleDatePicker: true,
      locale: t("dateRangePickerLocalization", { ns: "translations" }),
    });
    DateRangePickerHandler(this.sonTarih.current, {
      parentEl: ".content-inner",
      showDropdowns: true,
      singleDatePicker: true,
      locale: t("dateRangePickerLocalization", { ns: "translations" }),
    });
    $(this.stockCode.current).select2({
      width: "100%",
    });
  }

  handleClickFilter = () => {
    var jsonData = JSON.stringify({
      gc: "1",
      filters: [
        {
          expression: "=",
          column: "StokKodu",
          value: $(this.stockCode.current).val(),
        },
        {
          expression: ">",
          column: "created_at",
          value: moment($(this.ilkTarih.current).val(), "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          ),
        },
        {
          expression: "<",
          column: "created_at",
          value: moment($(this.sonTarih.current).val(), "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          ),
        },
      ],
    });
    var config = {
      method: "put",
      url: `${getEndPoint()}/api/stocks/gc-list`,
      headers: {
        "Content-Type": "application/json",
      },
      data: jsonData,
    };
    axios(config).then((response) => {
      var google = window.google;
      var resData = response.data.data;
      resData.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
      if (!resData.length) {
        Swal.fire("Hata", "Filtrelere Göre Veri Bulunamadı!", "error");
        return;
      }
      let drawLineChart = () => {
        let line_chart_element = document.getElementById("google-line");

        var datas = [];
        var Header = [{ type: "string", id: "Data" }, "Fiyat"];
        datas.push(Header);
        for (var i = 0; i < resData.length; i++) {
          var temp = [];

          temp.push(new Date(resData[i].created_at).toLocaleDateString());
          temp.push(parseFloat(resData[i].price));
          console.log(temp);
          datas.push(temp);
        }
        var data = new google.visualization.arrayToDataTable(datas);
        let options = {
          fontName: "Roboto",
          height: 400,
          fontSize: 15,
          chartArea: {
            left: "5%",
            width: "94%",
            height: 350,
          },
          pointSize: 7,
          curveType: "function",
          tooltip: {
            textStyle: {
              fontName: "Roboto",
              fontSize: 13,
            },
          },
          vAxis: {
            titleTextStyle: {
              fontSize: 13,
              italic: false,
              color: "#333",
            },
            textStyle: {
              color: "#333",
            },
            baselineColor: "#ccc",
            gridlines: {
              color: "#eee",
              count: 10,
            },
            minValue: 0,
          },
          hAxis: {
            textStyle: {
              color: "#333",
            },
          },
          legend: {
            position: "top",
            alignment: "center",
            textStyle: {
              color: "#333",
            },
          },
          series: {
            0: { color: "#EF5350" },
            1: { color: "#66BB6A" },
          },
        };

        options = {
          fontName: "Roboto",
          height: 650,
          fontSize: 12,
          chartArea: {
            left: "5%",
            width: "94%",
            height: 350,
          },
          pointSize: 7,
          curveType: "function",
          backgroundColor: "transparent",
          tooltip: {
            textStyle: {
              fontName: "Roboto",
              fontSize: 13,
            },
          },

          legend: {
            position: "top",
            alignment: "center",
            textStyle: {
              fontSize: 15,
              color: "black",
            },
          },
          series: {
            0: { color: "#EF5350" },
            1: { color: "#66BB6A" },
          },
        };

        let line_chart = new google.visualization.ColumnChart(
          line_chart_element
        );
        line_chart.draw(data, options);
      };

      google.charts.load("current", {
        callback: function () {
          drawLineChart();

          var sidebarToggle = document.querySelectorAll(".sidebar-control");
          if (sidebarToggle) {
            sidebarToggle.forEach(function (togglers) {
              togglers.addEventListener("click", drawLineChart);
            });
          }

          var resizeLineBasic;
          window.addEventListener("resize", function () {
            clearTimeout(resizeLineBasic);
            resizeLineBasic = setTimeout(function () {
              drawLineChart();
            }, 200);
          });
        },
        packages: ["corechart"],
      });
    });
  };

  render() {
    return (
      <Fragment>
        <div className="card mb-1">
          <div className="card-body py-2">
            <div className="row">
              <div className="col-sm-2">
                <div className="d-block w-100 mt-2">
                  <span>Stok Adı</span>
                  <div className="d-flex">
                    <select
                      ref={this.stockCode}
                      name="stockCode"
                      className="selectList2 form-control"
                    >
                      <option value="0">Yükleniyor...</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="col-sm-2">
                <div className="d-block w-100 mt-2">
                  <span>İlk Tarih</span>
                  <div className="d-flex">
                    <input
                      ref={this.ilkTarih}
                      type="text"
                      className="form-control w-100 daterange-single"
                      defaultValue=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="d-block w-100 mt-2">
                  <span>Son Tarih</span>
                  <div className="d-flex">
                    <input
                      ref={this.sonTarih}
                      type="text"
                      className="form-control w-100 daterange-single"
                      defaultValue=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-2 mt-4">
                <button
                  onClick={this.handleClickFilter}
                  type="button"
                  className="btn btn-primary w-100"
                >
                  Filtrele
                </button>
              </div>
            </div>
          </div>
        </div>
        <div id="google-line"></div>
      </Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(withTranslation(["stock-list", "translations"])(FiyatAnalizi));
