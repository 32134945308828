import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { setBreadcrumbs } from '../../features/active-page/sub-pages-slice';
import { setPageHeader } from '../../features/active-page/page-header-slice';
import { setHeaderElements } from '../../features/active-page/header-elements-slice';
import { setUser } from '../../features/user/user-slice';
import { EventHandlerContext } from '../../contexts/event-handler-context';
import Functions from '../../class/functions';
import $ from "jquery";
import intlTelInput from 'intl-tel-input';
import "intl-tel-input/build/css/intlTelInput.min.css";
import "datatables.net";
import "datatables.net-responsive";
import "datatables.net-select";
import * as bootstrap from "bootstrap";
import axios from 'axios';
import { getEndPoint } from "../../state/api/index"
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const ReactSwal = withReactContent(Swal);

Functions.importThemeStyles();

const mapStateToProps = state => ({
    redux: {
        pageHeader: state.pageHeader,
        breadcrumbs: state.breadcrumbs,
        headerElements: state.headerElements,
        user: state.user
    }
});
const mapDispatchToProps = () => ({
    setPageHeader,
    setBreadcrumbs,
    setHeaderElements,
    setUser
});

class Profile extends Component {
    static contextType = EventHandlerContext

    constructor(props) {
        super(props);

        this.debitHistoryListTableRef = React.createRef();
        this.currentPassword = React.createRef();
        this.newPassword = React.createRef();
        this.name = React.createRef();
        this.phone = React.createRef();
        this.email = React.createRef();
        this.address = React.createRef();
        this.debitHistoryListTable = null;
    }

    componentDidMount() {
        const { t } = this.props;
        const _this = this;
        this.props.setPageHeader(t("pages.settings.profile", { ns: "translations" }));
        this.props.setHeaderElements([
            {
                componentId: "save-user-data",
                href: "#", translation: { key: "misc.save", opts: { ns: "translations" } },
                className: "btn btn-link btn-float text-body", icon: "fas fa-user text-primary",
            },
            {
                componentId: "save-password",
                href: "#", translation: { key: "misc.save", opts: { ns: "translations" } },
                className: "btn btn-link btn-float text-body", icon: "fas fa-key text-primary",
            }
        ]);
        this.context.addEventHandler("save-user-data", (ev) => this.onSaveUserDataButtonClick(ev));
        this.context.addEventHandler("save-password", (ev) => this.onSavePasswordButtonClick(ev));

        $("input[type$='tel']").each((index, telInput) => {
            intlTelInput(telInput,
                {
                    autoPlaceholder: "polite",
                    geoIpLookup: (success, failure) => {
                        //success(countryCode)
                    },
                    preferredCountries: ["tr", "us", "gb"],
                    nationalMode: false,
                    seperateDialCode: true,
                    utilsScript: require("intl-tel-input/build/js/utils"),
                    customContainer: "w-100",
                    formatOnDisplay: true
                }
            )
        });

        if (this.debitHistoryListTable != undefined)
            this.debitHistoryListTable.destroy();

        this.debitHistoryListTable = $(".datatable-pagination").DataTable({
            pagingType: 'simple_numbers',
            autoWidth: false,
            responsive: true,
            processing: true,
            serverSide: true,
            lengthMenu: [[50, 100, 150, -1], [50, 100, 150, "Hepsi"]],
            ajax: {
                url: `${getEndPoint()}/api/stocks/debit-list`,
                type: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                xhrFields: {
                    withCredentials: true
                },
                crossDomain: true,
                dataFilter: function (data) {
                    var json = $.parseJSON(data);
                    json.data = json.data;
                    json.recordsFiltered = json.result.recordsTotal
                    json.recordsTotal = json.result.recordsTotal
                    return JSON.stringify(json);
                },
            },
            fnServerParams: (aoData) => {
                aoData["filters"] = [{ expression: "=", column: "responsible_employee_id", value: String(_this.props.redux.user.value.id) },
                { "expression": "order", "column": aoData.columns[aoData.order[0].column].data, "value": aoData.order[0].dir },];
                aoData["page"] = aoData.start / aoData.length;
                aoData["per_page"] = aoData.length;
            },
            "columns": [
                { data: "id", "orderable": "false" },
                { data: "stock_code", "orderable": "false" },
                { data: "stock_name", "orderable": "false" },
                { data: "barcode_number", "orderable": "false" },
                {
                    data: "id", render: function (data, type, row) {
                        return row.get_hareket_stock_list_giris_sum_miktar - row.get_hareket_stock_list_cikis_sum_miktar;
                        // return get_hareket_stock_list_giris_sum_miktar
                    }
                },
                { data: "vat_ratio", "orderable": "false" },
                { data: "price", "orderable": "false" },
            ],
            columnDefs: [
                {
                    data: '',
                    defaultContent: '',
                    orderable: false,
                    className: '',
                    targets: 0
                }
            ],
            select: {
                style: 'multi',
                selector: 'td:nth-child(1)'
            },
            order: [[1, 'asc']],
            language: {
                ...(t("plugins.dataTables", { ns: "translations" })),
                paginate: {
                    'next': $('html').attr('dir') === 'rtl' ? t("plugins.dataTables.paginate.next", { ns: "translations" }) + '&larr;' : t("plugins.dataTables.paginate.next", { ns: "translations" }) + ' &rarr;',
                    'previous': $('html').attr('dir') === 'rtl' ? '&rarr; ' + t("plugins.dataTables.paginate.previous", { ns: "translations" }) : '&larr; ' + t("plugins.dataTables.paginate.previous", { ns: "translations" })
                }
            }
        });

        var triggerTabList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tab"]'))
        triggerTabList.forEach(function (triggerEl) {
            var tabTrigger = new bootstrap.Tab(triggerEl)

            triggerEl.addEventListener('click', function (event) {
                event.preventDefault()
                tabTrigger.show()
            })
        })
    }

    componentWillUnmount() {
        this.props.setPageHeader("");
        this.props.setHeaderElements([]);
        this.context.handlers = [];
    }

    onSaveUserDataButtonClick(ev) {
        const { t } = this.props;
        const _this = this;
        ReactSwal.fire({
            title: <span>{t("misc.warning", { ns: "translations" })}</span>,
            html: <span>{t("operations.areYouSure", { ns: "translations" })}</span>,
            icon: '',
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: t("misc.yes", { ns: "translations" }),
            cancelButtonText: t("misc.cancel", { ns: "translations" }),
        }).then((a) => {
            var data = JSON.stringify({
                "id": _this.props.redux.user.value.id,
                "name": _this.name.current.value,
                "phone": _this.phone.current.value,
                "role": _this.props.redux.user.value.role,
                "email": _this.email.current.value,
                "address": _this.address.current.value
            });

            var config = {
                method: 'post',
                url: `${getEndPoint()}/api/users/edit`,
                headers: {
                    'Content-Type': 'application/json',
                },

                data: data
            };

            axios(config)
                .then(function (response) {
                    ReactSwal.fire({
                        title: <span>{t("misc.successfull", { ns: "translations" })}</span>,
                        html: <span>{t("operations.successMessage", { ns: "translations" })}</span>,
                        icon: '',
                        showCloseButton: true,
                        focusConfirm: false,
                        confirmButtonText: t("misc.ok", { ns: "translations" }),
                    })
                    _this.props.setUser(response.data)
                    Functions.setUserData(response.data[0])
                    _this.componentDidMount();
                })
        })
    }

    onSavePasswordButtonClick(ev) {
        const _this = this;
        const { t } = this.props;

        ReactSwal.fire({
            title: <span>{t("misc.warning", { ns: "translations" })}</span>,
            html: <span>{t("operations.areYouSure", { ns: "translations" })}</span>,
            icon: '',
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: t("misc.yes", { ns: "translations" }),
            cancelButtonText: t("misc.cancel", { ns: "translations" }),
        }).then((a) => {
            var data = JSON.stringify({
                "email": _this.props.redux.user.value.email,
                "current_password": _this.currentPassword.current.value,
                "new_password": _this.newPassword.current.value
            });

            var config = {
                method: 'post',
                url: `${getEndPoint()}/api/users/change-password`,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: data
            };

            axios(config)
                .then(function (response) {

                    ReactSwal.fire({
                        title: <span>{t("misc.successfull", { ns: "translations" })}</span>,
                        html: <span>{t("operations.successMessage", { ns: "translations" })}</span>,
                        icon: '',
                        showCloseButton: true,
                        focusConfirm: false,
                        confirmButtonText: t("misc.ok", { ns: "translations" }),
                    })
                    _this.componentDidMount();

                })
        })

    }

    render() {
        const { t } = this.props;
        return (
            <div className="card m-1 p-1">
                <div className="card-header">
                    <h6 className="card-title">
                        {t("profileSettings")}
                    </h6>
                </div>
                <div className="card-body">
                    <ul className="nav nav-pills nav-pills-bordered nav-justified">
                        <li className="nav-item"><a href="#profile-data" className="nav-link active" data-bs-toggle="tab">{t("tabs.profileData")}</a></li>
                        <li className="nav-item"><a href="#change-password" className="nav-link" data-bs-toggle="tab">{t("tabs.changePassword")}</a></li>
                        <li className="nav-item"><a href="#debit-history" className="nav-link" data-bs-toggle="tab">{t("tabs.debitHistory")}</a></li>
                    </ul>

                    <div className="tab-content">
                        <div className="tab-pane fade show active" id="profile-data">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="name">{t("name")}</label>
                                        <input defaultValue={this.props.redux.user.value.name} ref={this.name} name="name" placeholder={t("name")} type="text" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="phone">{t("phone")}</label>
                                        <input defaultValue={this.props.redux.user.value.phone} ref={this.phone} name="phone" type="tel" className="form-control w-100" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="email">{t("email")}</label>
                                        <input defaultValue={this.props.redux.user.value.email} ref={this.email} name="email" type="email" className="form-control w-100" />
                                    </div>

                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="address">{t("address")}</label>
                                        <textarea defaultValue={this.props.redux.user.value.address} ref={this.address} style={{ height: "217px" }} name="address" placeholder={t("address")} rows="5" type="text" className="form-control"></textarea>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="change-password">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="currentPassword">{t("currentPassword")}</label>
                                        <input ref={this.currentPassword} name="currentPassword" className="form-control" type="text" placeholder={t("placeholders.currentPassword")} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="newPassword">{t("newPassword")}</label>
                                        <input ref={this.newPassword} name="newPassword" className="form-control" type="text" placeholder={t("placeholders.newPassword")} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="debit-history">
                            <table ref={this.debitHistoryListTableRef} className="table table-striped table-hover dt-responsive nowrap datatable-pagination w-100">
                                <thead className='w-100'>
                                    <tr>
                                      
                                        <th>ID</th>
                                        <th>{t("table.header.stockCode")}</th>
                                        <th>{t("table.header.stockName")}</th>
                                        <th>{t("table.header.barcodeNumber")}</th>
                                        <th>{t("table.header.qua")}</th>
                                        <th>{t("table.header.vatRatio")}</th>
                                        <th>{t("table.header.price")}</th>
                                    </tr>
                                </thead>
                                <tbody className='w-100'>

                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps())(
    withTranslation(['settings-profile', 'translations'])(Profile)
);