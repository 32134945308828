import React from 'react'
import { ReactSession } from 'react-client-session'
import { Navigate, Outlet } from 'react-router-dom';
const userData = ReactSession.get('userData');

const AuthControl = () => {
    if (userData === undefined) {

     return  <Navigate to="/login" />

    }
    else {
        return <Outlet />

    }
}

export default AuthControl;

