import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    accessToken: null,
    token: null,
};

const apiSlice = createSlice({
    name: "api",
    initialState,
    reducers: {
        resetToken: state => {
            console.log(state)
            for (const [key, value] of Object.entries(initialState)) {
                state[key] = value;
            }
        },
        setToken: {
            reducer: (state, { payload }) => {
                state.accessToken = payload?.xAccessToken;
                state.token = payload?.accessToken;
            },
        },
    },
});

export const {  setToken, resetToken } = apiSlice.actions;

export default apiSlice.reducer;