import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ReactSession } from "react-client-session";
import $ from "jquery";
import axios from "axios";
import { getEndPoint } from "../state/api/index";
let selectData = [];
export default class Functions {
  static setUserData(userData) {
    ReactSession.set("userData", userData);
  }
  static deleteUserData() {
    ReactSession.remove("userData");
    // window.location.href = "/login"
  }

  static printCanvas(element) {
    if (element.nodeName !== "CANVAS") {
      console.error("Element 'canvas' tipinde olmalı", element.nodeName);
      return;
    }

    let dataUrl = element.toDataURL();
    let windowContent = `
            <!DOCTYPE html>
            <html>
            <head><title>QR Code</title></head>
            <body>
            <img src="${dataUrl}">
            </body>
            </html>
        `;
    var printWin = window.open("", "", "width=340,height=260");
    printWin.document.open();
    printWin.document.write(windowContent);
    printWin.document.addEventListener(
      "load",
      function () {
        printWin.focus();
        printWin.print();
        printWin.document.close();
        printWin.close();
      },
      true
    );
  }

  static printCanvasALL(elements, qrList, genislik, yukseklik, qrCodeList) {
    // console.log( qrList[0] );
    // if (qrList[0].nodeName !== 'CANVAS') {
    //     console.error("Element 'canvas' tipinde olmalı", qrList[0].nodeName)
    //     return
    // }
    let qrgenislik = 3;
    let qryukseklik = 3;

    if (genislik != "") qrgenislik = genislik;

    if (yukseklik != "") qryukseklik = yukseklik;

    let windowContent = `
            <!DOCTYPE html>
            <html>
            <head><title>QR Code - </title></head>
            <body style="width: 20cm;    height: 29cm; margin:0; padding:0 ; "> 
        `;
    windowContent = `
        <table class="no-print" style="width: 100%">
        <tbody>
        <tr> <td class="no-print" style="width: 100%; margin-bottom:50px; text-align: center" onClick="yazdir()"  >  Yazdır </td>   </tr>

        </tbody>
    </table>
    `;

    qrList.map((element, index) => {
      windowContent += `<div style="display:inline-block;vertical-align:top;text-align:center"><img src="${String(
        element.current.toDataURL()
      )}" style="margin-top:25px; width:${
        parseInt(qrgenislik) * (96 / 2.54)
      }px; height: ${
        parseInt(qryukseklik) * (96 / 2.54)
      }px; "><label style="display:block">${qrCodeList[index]}</label></div>`;
      element = "";
    });
    windowContent += `


        <style>
        .no-print{

            background: red;
            position: absolute;
            top: 0;
            height: 1cm;

        }
        @media  print
        {
            .no-print, .no-print *
            {
                display: none !important;
            }

            td, tr,table {

                border : 0px solid #989797;
            }


        }
    </style>

        <script src="https://code.jquery.com/jquery-1.11.3.js"></script>
        <script >
        $( document ).ready(function() {
            window.print();
     
             });

             function yazdir()
             {
                 window.print();
             }

             

             </script>
        `;
    windowContent += `
            </body>
            </html>
        `;
    var printWin = window.open("", "", "width=755,height=1096");
    printWin.document.open();
    printWin.document.write(windowContent);
    // printWin.print()
    printWin.addEventListener(
      "load",
      function () {
        printWin.print();
      },
      true
    );
    // printWin.document.addEventListener(
    //     'load',
    //     function () {
    //         printWin.focus()
    //         printWin.print()
    //         printWin.document.close()
    //         printWin.close()
    //     },
    //     true
    // )
  }

  static changeTitle(title) {
    document.title = `Kobis | ${title}`;
  }

  static withRouter(Component) {
    function ComponentWithRouterProp(props) {
      let location = useLocation();
      let navigate = useNavigate();
      let params = useParams();
      if (window.location.pathname == location.pathname) {
      }
      return (
        <Component {...props} router={{ location, navigate, params }}>
          {props.children}
        </Component>
      );
    }

    return ComponentWithRouterProp;
  }
  static getFormatLocaleDateHours(data) {
    if (data == "") {
      return "";
    }

    if (data == undefined) {
      return "";
    }
    const [dateComponents, timeComponents] = data.split(" ");
    const [day, month, year] = dateComponents.split("/");
    var newDate = `${year}-${month}-${day}`;
    return `${dateComponents}`;
  }
  static getFormatLocaleDateList(data) {
    if (data == "") {
      return "";
    }

    if (data == undefined) {
      return "";
    }
    const [dateComponents, timeComponents] = data.split(" ");
    const [year, month, day] = dateComponents.split("-");
    var newDate = `${day}-${month}-${year}`;

    return newDate;
  }
  static getFormatDBDateHours(data) {
    if (data == "") {
      return "";
    }

    if (data == undefined) {
      return "";
    }
    const [dateComponents, timeComponents] = data.split(" ");
    const [year, month, day] = dateComponents.split("/");
    console.log(year, month, day);
    var newDate = `${year}-${month}-${day}`;
    return `${newDate}`;
  }
  static upperCaseTr(str) {
    var str = String(str);
    return str
      .toLocaleLowerCase("tr-TR")
      .replace(/(?:^|\s|,|;|!|:|-|\.|\?)[a-z0-9ğçşüöı]/g, function (match) {
        return match.toLocaleUpperCase("tr-TR");
      });
  }
  static createSelectList(ref, t, selectType) {
    const _this = this;

    $(ref.current).select2({
      width: "100%",
      height: "40px",
      language: {
        noResults: () => t("plugins.select2.noResults", { ns: "translations" }),
        searching: () => t("plugins.select2.searching", { ns: "translations" }),
        maximumSelected: () =>
          t("plugins.select2.maximumSelected", { ns: "translations" }),
        loadingMore: () =>
          t("plugins.select2.loadingMore", { ns: "translations" }),
        inputTooShort: () =>
          t("plugins.select2.inputTooShort", { ns: "translations" }),
        inputTooLong: () =>
          t("plugins.select2.inputTooLong", { ns: "translations" }),
        errorLoading: () =>
          t("plugins.select2.errorLoading", { ns: "translations" }),
      },
    });

    var config = {
      method: "get",
      url: `${getEndPoint()}/api/stocks/all`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    switch (selectType) {
      case "employee":
        config = {
          method: "get",
          url: `${getEndPoint()}/api/users/all`,
          headers: {
            "Content-Type": "application/json",
          },
        };
        axios(config).then(function (response) {
          selectData = response.data.data;
          $(ref.current).empty().trigger("change");

          var selects = $(ref.current);

          var option = new Option("Lütfen Seçim Yapınız...", 0, true, true);
          selects.append(option).trigger("change");
          selectData.forEach((elm, index) => {
            var option = new Option(elm.name, elm.id, false, false);
            selects.append(option).trigger("change");
          });
        });
        break;
      case "stocks":
        config = {
          method: "get",
          url: `${getEndPoint()}/api/stocks/all`,
          headers: {
            "Content-Type": "application/json",
          },
        };
        axios(config).then(function (response) {
          selectData = response.data.data;
          $(ref.current).empty().trigger("change");
          var selects = $(ref.current);

          var option = new Option("Lütfen Seçim Yapınız...", 0, true, true);
          selects.append(option).trigger("change");
          selectData.forEach((elm, index) => {
            var option = new Option(
              elm.stock_name +
                " -- " +
                t("qua", {
                  ns: "translations",
                  itemCount:
                    elm.get_hareket_stock_list_giris_sum_miktar -
                    elm.get_hareket_stock_list_cikis_sum_miktar,
                }),
              elm.id,
              false,
              false
            );
            selects.append(option).trigger("change");
          });
        });
        break;
      case "storages":
        var config = {
          method: "get",
          url: `${getEndPoint()}/api/storages/all`,
          headers: {},
        };

        axios(config).then(function (response) {
          selectData = response.data.data;
          $(ref.current).empty().trigger("change");
          var selects = $(ref.current);

          var option = new Option("Lütfen Seçim Yapınız...", 0, false, false);
          selects.append(option).trigger("change");
          selectData.forEach((elm, index) => {
            option = new Option(elm.name, elm.id, true, true);
            selects.append(option).trigger("change");
          });
        });
        break;
      case "companies":
        var config = {
          method: "get",
          url: `${getEndPoint()}/api/companies/all`,
          headers: {
            "Content-Type": "application/json",
          },
        };

        axios(config).then(function (response) {
          selectData = response.data.data;
          $(ref.current).empty().trigger("change");
          var selects = $(ref.current);

          var option = new Option("Lütfen Seçim Yapınız...", 0, true, true);
          selects.append(option).trigger("change");
          selectData.forEach((elm, index) => {
            var option = new Option(elm.name, elm.code, false, false);
            selects.append(option).trigger("change");
          });
        });
        break;
      case "units":
        var config = {
          method: "get",
          url: `${getEndPoint()}/api/units/all`,
          headers: {
            "Content-Type": "application/json",
          },
        };

        axios(config).then(function (response) {
          selectData = response.data.data;
          $(ref.current).empty().trigger("change");
          var selects = $(ref.current);

          var option = new Option("Lütfen Seçim Yapınız...", 0, true, true);
          selects.append(option).trigger("change");
          selectData.forEach((elm, index) => {
            var option = new Option(elm.name, elm.id, false, false);
            selects.append(option).trigger("change");
          });
        });
        break;
      case "brands":
        var config = {
          method: "post",
          url: `${getEndPoint()}/api/brands/all`,
          headers: {
            "Content-Type": "application/json",
          },
        };

        axios(config).then(function (response) {
          selectData = response.data.data;
          $(ref.current).empty().trigger("change");
          var selects = $(ref.current);

          var option = new Option("Lütfen Seçim Yapınız...", 0, true, true);
          selects.append(option).trigger("change");
          selectData.forEach((elm, index) => {
            var option = new Option(elm.name, elm.id, false, false);
            selects.append(option).trigger("change");
          });
        });
        break;
      case "personel":
        var config = {
          method: "get",
          url: `${getEndPoint()}/api/users/personel`,
          headers: {
            "Content-Type": "application/json",
          },
        };

        axios(config).then(function (response) {
          selectData = response.data.data;
          $(ref.current).empty().trigger("change");
          var selects = $(ref.current);

          var option = new Option("Lütfen Seçim Yapınız...", 0, true, true);
          selects.append(option).trigger("change");
          selectData.forEach((elm, index) => {
            var option = new Option(elm.name, elm.code, false, false);
            selects.append(option).trigger("change");
          });
        });
        break;
    }
  }

  static selectAppend(selectName, selectList) {
    $(selectName).empty().trigger("change");

    var selects = $(selectName);

    var option = new Option("Lütfen Seçim Yapınız...", 0, true, true);
    selects.append(option).trigger("change");

    selectList.forEach((elm, index) => {
      var option = new Option(elm.name, elm.id, false, false);
      selects.append(option).trigger("change");
    });
  }

  static importThemeStyles() {
    if (
      ReactSession.get("theme") !== null &&
      ReactSession.get("theme") !== undefined &&
      ReactSession.get("theme") === "dark"
    ) {
      require("@sweetalert2/theme-dark");
    } else {
      require("sweetalert2/dist/sweetalert2.min.css");
    }
  }
}
