import React, { Component, createRef } from 'react';
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { setBreadcrumbs } from '../../features/active-page/sub-pages-slice';
import { setPageHeader } from '../../features/active-page/page-header-slice';
import { setHeaderElements } from '../../features/active-page/header-elements-slice';
import { EventHandlerContext } from '../../contexts/event-handler-context';
import Functions from '../../class/functions';
import $ from "jquery";
import intlTelInput from 'intl-tel-input';
import "intl-tel-input/build/css/intlTelInput.min.css";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from 'axios';
import { getEndPoint } from "../../state/api/index"

const ReactSwal = withReactContent(Swal);

Functions.importThemeStyles();

const mapStateToProps = state => ({
    redux: {
        pageHeader: state.pageHeader,
        breadcrumbs: state.breadcrumbs,
        headerElements: state.headerElements
    }
});
const mapDispatchToProps = () => ({
    setPageHeader,
    setBreadcrumbs,
    setHeaderElements
});

class PersonelEkle extends Component {
    static contextType = EventHandlerContext

    constructor(props) {
        super(props);
        this.codeRef = createRef();
        this.userNameRef = createRef();
        this.passwordRef = createRef();
        this.emailRef = createRef();
        this.roleRef = createRef();
        this.phoneRef = createRef();
        this.adressRef = createRef();
        this.state = {

        };
    }

    componentDidMount() {
        const { t } = this.props;
        const _this = this;
        this.props.setPageHeader("Personel Ekle");
        this.props.setHeaderElements([
            {
                componentId: "add-user",
                href: "#", translation: { key: "misc.add", opts: { ns: "translations" } },
                className: "btn btn-link btn-float text-body", icon: "fas fa-plus text-primary",
            },
        ]);
        this.context.addEventHandler("add-user", () => {

            var data = JSON.stringify({
                code: this.codeRef.current.value,
                name: this.userNameRef.current.value,
                phone: this.phoneRef.current.value,
                address: this.adressRef.current.value,
            })
            var config = {
                method: 'post',
                url: `${getEndPoint()}/api/users/personel-add`,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: data
            };

            axios(config)
                .then(function (response) {
                    _this.userNameRef.current.value = ""
                    _this.phoneRef.current.value = ""
                    _this.adressRef.current.value = ""
                    ReactSwal.fire({
                        title: <span>{t("misc.successfull", { ns: "translations" })}</span>,
                        html: <span>{t("operations.success", { ns: "translations" })}</span>,
                        icon: '',
                        showCloseButton: true,
                        focusConfirm: false,
                        confirmButtonText: t("misc.ok", { ns: "translations" }),
                    })

                })







        });

        $("input[type$='tel']").each((index, telInput) => {
            intlTelInput(telInput,
                {
                    autoPlaceholder: "polite",
                    geoIpLookup: (success, failure) => {
                        //success(countryCode)
                    },
                    preferredCountries: ["tr", "us", "gb"],
                    nationalMode: false,
                    seperateDialCode: true,
                    utilsScript: require("intl-tel-input/build/js/utils"),
                    customContainer: "w-100",
                    formatOnDisplay: true
                }
            )
        });
    }

    componentWillUnmount() {
        this.props.setPageHeader("");
        this.props.setHeaderElements([]);
        this.context.handlers = [];
    }

    render() {
        const { t } = this.props;

        return (
            <div className='card p-1'>
                <div className='card-body p-1'>
                    <div className='row'>
                        <div className="col-sm-12 col-md-12">
                            <div className="form-group">
                                <label htmlFor="code">Personel Kodu</label>
                                <input ref={this.codeRef} name="code" type="text" className='form-control' />
                            </div>
                            <div className="form-group">
                                <label htmlFor="name">{t("name")}</label>
                                <input ref={this.userNameRef} name="name" type="text" className='form-control' />
                            </div>
                            <div className="form-group">
                                <label htmlFor="phone">{t("phone")}</label>
                                <input ref={this.phoneRef} name="phone" type="tel" defaultValue="+90 " className='form-control' />
                            </div>
                            <div className="form-group">
                                <label htmlFor="adress">{t("adress")}</label>
                                <input ref={this.adressRef} name="adress" type="text" className='form-control' />
                            </div>

                        </div>
                        <div className="col-sm-12 col-md-6">


                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps())(
    withTranslation(["user-add", "translations"])(PersonelEkle)
);