import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { setBreadcrumbs } from "../../features/active-page/sub-pages-slice";
import { setPageHeader } from "../../features/active-page/page-header-slice";
import { setHeaderElements } from "../../features/active-page/header-elements-slice";
import { EventHandlerContext } from "../../contexts/event-handler-context";
import $ from "jquery";
import { DateRangePickerHandler } from "../../js/plugins/pickers/daterangepicker.js";

import "datatables.net";
import "datatables.net-responsive";
import "datatables.net-buttons-dt";
import "datatables.net-select";
import "jszip";
import "pdfmake";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "datatables.net-buttons/js/buttons.colVis";
import "datatables.net-buttons/js/buttons.flash";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Functions from "../../class/functions";
import axios from "axios";
import { getEndPoint } from "../../state/api/index";
import { ReactSession } from "react-client-session";
import moment from "moment";
import { Fragment } from "react";

Functions.importThemeStyles();
const ReactSwal = withReactContent(Swal);

const mapStateToProps = (state) => ({
  redux: {
    pageHeader: state.pageHeader,
    breadcrumbs: state.breadcrumbs,
    headerElements: state.headerElements,
  },
});
const mapDispatchToProps = () => ({
  setPageHeader,
  setBreadcrumbs,
  setHeaderElements,
});

class StockList extends Component {
  static contextType = EventHandlerContext;

  constructor(props) {
    super(props);

    this.state = {
      rowsToDelete: [],
      selectDeleteItemRows: 0,
    };
    this.columnSelect = React.createRef();
    this.filterData = "";
    this.stockListTable = null;
    this.stockListTableRef = React.createRef();
    this.selectAllRef = React.createRef();
    this.filterDeleted = this.filterDeleted.bind(this);
    this.ilkTarih = React.createRef();
    this.sonTarih = React.createRef();
  }

  componentDidMount() {
    const { t } = this.props;
    let _this = this;

    // this.props.setBreadcrumbs([
    //     { href: "/", className: "", value: "Test 1" },
    //     { href: "/", className: "", value: "Test 2" },
    //     { href: "/", className: "", value: "Test 3" },
    //     { href: "/", className: "", value: "Test 4" }
    // ]);
    this.props.setPageHeader(t("pages.stocks.stocks", { ns: "translations" }));
    this.props.setHeaderElements([
      {
        componentId: "delete-stock",
        href: "#",
        translation: {
          key: "misc.deleteSelected",
          opts: { ns: "translations" },
        },
        className: "btn btn-link btn-float text-body",
        icon: "fas fa-trash text-danger",
      },
    ]); //silme alanı
    this.context.addEventHandler("delete-stock", (ev) =>
      this.onDeleteStockButtonClick(ev)
    );
    DateRangePickerHandler(this.ilkTarih.current, {
      parentEl: ".content-inner",
      showDropdowns: true,
      singleDatePicker: true,
      locale: t("dateRangePickerLocalization", { ns: "translations" }),
    });
    DateRangePickerHandler(this.sonTarih.current, {
      parentEl: ".content-inner",
      showDropdowns: true,
      singleDatePicker: true,
      locale: t("dateRangePickerLocalization", { ns: "translations" }),
    });
    $(".select").select2({
      width: "100%",
    });
    if (this.stockListTable != undefined) this.stockListTable.destroy();
    this.stockListTable = $(".datatable-pagination").DataTable({
      autoWidth: false,
      responsive: true,
      dom: "Blfrtip",
      buttons: ["copyHtml5", "excelHtml5", "csvHtml5", "pdfHtml5"],
      processing: true,
      serverSide: true,
      lengthMenu: [
        [50, 100, 150, -1],
        [50, 100, 150, "Hepsi"],
      ],
      ajax: {
        url: `${getEndPoint()}/api/stocks/all`,
        type: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        xhrFields: {
          withCredentials: true,
        },
        crossDomain: true,
        dataFilter: function (data) {
          var json = $.parseJSON(data);
          json.data = json.data;
          json.recordsFiltered = json.result.recordsTotal;
          json.recordsTotal = json.result.recordsTotal;
          return JSON.stringify(json);
        },
      },
      fnServerParams: (aoData) => {
        if (ReactSession.get("stockList") != undefined) {
          var search = ReactSession.get("stockList");
          aoData["filters"] = [
            {
              expression: "LIKE",
              column: search.column,
              value: "% " + search.value + "%",
            },
            {
              expression: "order",
              column: aoData.columns[aoData.order[0].column].data,
              value: aoData.order[0].dir,
            },
          ];
          ReactSession.remove("stockList");
        } else {
          aoData["filters"] = [
            {
              expression: "order",
              column: aoData.columns[aoData.order[0].column].data,
              value: aoData.order[0].dir,
            },
            String(aoData.search.value).toLocaleUpperCase("TR") == ""
              ? null
              : {
                  expression: "LIKE",
                  column: $(".select").val(),
                  value:
                    "%" +
                    String(aoData.search.value).toLocaleUpperCase("TR") +
                    "%",
                },
            _this.filterData != "" ? _this.filterData[0] : null,
            _this.filterData != "" ? _this.filterData[1] : null,
            _this.filterData != "" ? _this.filterData[2] : null,
            _this.filterData != "" ? _this.filterData[3] : null,
          ];
        }

        aoData["page"] = aoData.start / aoData.length;
        aoData["per_page"] = aoData.length;
      },
      columns: [
        { data: "id", orderable: "true" },
        {
          data: "id",
          render: function (data, type, row) {
            return null;
          },
        },
        { data: "stock_storage.name", orderable: false },
        { data: "stock_code", orderable: "false" },
        { data: "stock_name", orderable: "false" },
        { data: "barcode_number", orderable: "false" },
        { data: "get_unit[0].name", orderable: false },
        {
          data: "id",
          render: function (data, type, row) {
            return (
              row.get_hareket_stock_list_giris_sum_miktar -
              row.get_hareket_stock_list_cikis_sum_miktar
            );
            // return get_hareket_stock_list_giris_sum_miktar
          },
          orderable: false,
        },
        { data: "price", orderable: "false" },

        {
          data: "created_at",
          render: function (data, type, row) {
            if (data == null) {
              return " ";
            }
            return Functions.getFormatLocaleDateList(data.substring(0, 10));
          },
        },
        {
          data: "id",
          render: function (data, type, row) {
            return (
              '<a href="/stock/' +
              data +
              '" class="btn demo btn-warning"> Detay </a>'
            );
          },
        },
      ],
      columnDefs: [
        {
          defaultContent: "",
          orderable: false,
          className: "select-checkbox",
          targets: 1,
          checkboxes: {
            selectRow: true,
          },
        },
      ],
      select: {
        style: "multi",
        selector: "td:nth-child(2)",
      },
      order: [[3, "asc"]],
      language: {
        ...t("plugins.dataTables", { ns: "translations" }),
        paginate: {
          next:
            $("html").attr("dir") === "rtl"
              ? t("plugins.dataTables.paginate.next", { ns: "translations" }) +
                "&larr;"
              : t("plugins.dataTables.paginate.next", { ns: "translations" }) +
                " &rarr;",
          previous:
            $("html").attr("dir") === "rtl"
              ? "&rarr; " +
                t("plugins.dataTables.paginate.previous", {
                  ns: "translations",
                })
              : "&larr; " +
                t("plugins.dataTables.paginate.previous", {
                  ns: "translations",
                }),
        },
      },
      lengthMenu: [
        [50, 100, 150, -1],
        [50, 100, 150, "Hepsi"],
      ],
    });

    this.stockListTable.on("select", (e, dt, type, indexes) =>
      this.onStockTableSelect(e, dt, type, indexes)
    );
    this.stockListTable.on("deselect", (e, dt, type, indexes) =>
      this.onStockListTableDeselect(e, dt, type, indexes)
    );
  }

  componentWillUnmount() {
    this.props.setPageHeader("");
    this.props.setHeaderElements([]);
    this.context.handlers = [];
  }

  onDeleteStockButtonClick(ev) {
    const { t } = this.props;
    const _this = this;
    if (this.state.selectDeleteItemRows === 0) {
      ReactSwal.fire({
        title: (
          <span>
            {t("operations.deleteMultiple", {
              ns: "translations",
              itemCount: 1,
            })}
          </span>
        ),
        html: ``,
        icon: "error",
        showCloseButton: true,
        confirmButtonText: t("misc.ok", { ns: "translations" }),
      });
      return;
    }

    ReactSwal.fire({
      title: (
        <span>
          {t("operations.wouldYouLikeToDeleteRecords", { ns: "translations" })}
        </span>
      ),
      html: (
        <span>
          {t("operations.itemsToDelete", {
            ns: "translations",
            itemCount: this.state.selectDeleteItemRows,
          })}
        </span>
      ),
      icon: "",
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: t("misc.yes", { ns: "translations" }),
      cancelButtonText: t("misc.cancel", { ns: "translations" }),
    }).then((a) => {
      if (a.isConfirmed) {
        var data = JSON.stringify({
          ids: [_this.state.rowsToDelete],
        });

        var config = {
          method: "post",
          url: `${getEndPoint()}` + "/api/stocks/delete",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios(config).then(function (response) {
          ReactSwal.fire({
            title: <span>{t("misc.successfull", { ns: "translations" })}</span>,
            html: (
              <span>
                {t("operations.itemsDeleted", {
                  ns: "translations",
                  itemCount: _this.state.selectDeleteItemRows,
                })}
              </span>
            ),
            icon: "",
            showCloseButton: true,
            focusConfirm: false,
            confirmButtonText: t("misc.ok", { ns: "translations" }),
          });
          $.fn.dataTable.tables({ api: true }).ajax.reload();
        });
      }
    });
  }

  onSelectAllButtonClick(ev) {
    $(ev.target)
      .parent()
      .find("#selectAll")
      .prop("checked", (index, oldPropertyValue) => {
        $(this.selectAllRef.current).prop("checked", !oldPropertyValue);
      });

    if ($(ev.target).parent().find("#selectAll").is(":checked")) {
      this.stockListTable.rows().select();
      this.setState({
        rowsToDelete: this.stockListTable.rows({ selected: true }).count(),
      });
    } else {
      this.stockListTable.rows().deselect();
      this.setState({
        rowsToDelete: 0,
      });
    }
  }

  onStockTableSelect(e, dt, type, indexes) {
    this.state.rowsToDelete.push(dt.data().id);
    this.setState({
      rowsToDelete: this.state.rowsToDelete,
      selectDeleteItemRows: this.stockListTable
        .rows({ selected: true })
        .count(),
    });
    if (
      this.stockListTable.rows({ selected: true }).count() ===
      this.stockListTable.rows().count()
    )
      $(this.selectAllRef.current).prop("checked", true);
  }
  onStockListTableDeselect(e, dt, type, indexes) {
    var filteredArray = this.state.rowsToDelete.filter(function (f) {
      return f !== dt.data().id;
    });
    this.setState({
      rowsToDelete: filteredArray,
      selectDeleteItemRows: this.stockListTable
        .rows({ selected: true })
        .count(),
    });
    if (
      this.stockListTable.rows({ selected: true }).count() !==
      this.stockListTable.rows().count()
    )
      $(this.selectAllRef.current).prop("checked", false);
  }
  filterDeleted() {
    ReactSession.remove("stockList");
    this.componentDidMount();
  }
  handleClickFilter = () => {
    this.filterData = [
      {
        expression: ">",
        column: "created_at",
        value: moment($(this.ilkTarih.current).val(), "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        ),
      },
      {
        expression: "<",
        column: "created_at",
        value:
          moment($(this.sonTarih.current).val(), "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          ) + " 23:59:59",
      },
    ];

    this.componentDidMount();
  };
  render() {
    const { t } = this.props;

    return (
      <Fragment>
        <div className="card mb-1">
          <div className="card-body py-2">
            <div className="row">
              <div className="col-sm-2">
                <div className="d-block w-100 mt-2">
                  <span>İlk Tarih</span>
                  <div className="d-flex">
                    <input
                      ref={this.ilkTarih}
                      type="text"
                      className="form-control w-100 daterange-single"
                      defaultValue=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="d-block w-100 mt-2">
                  <span>Son Tarih</span>
                  <div className="d-flex">
                    <input
                      ref={this.sonTarih}
                      type="text"
                      className="form-control w-100 daterange-single"
                      defaultValue=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-2 mt-4">
                <button
                  onClick={this.handleClickFilter}
                  type="button"
                  className="btn btn-primary w-100"
                >
                  Filtrele
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body mt-4">
            <div className="w-100">
              <table
                ref={this.stockListTableRef}
                id="table"
                className="table table-striped table-hover dt-responsive nowrap datatable-pagination w-100"
              >
                <thead className="w-100">
                  <tr>
                    <th>
                      <label>Aranılacak Alan</label>
                    </th>
                    <th>
                      <div className="form-group">
                        <select className="select" ref={this.columnSelect}>
                          <option value="stock_name">Stok Adı</option>
                          <option value="stock_code">Stok Kodu</option>
                          <option value="barcode_number">
                            Barkod Numarası
                          </option>
                        </select>
                      </div>
                    </th>
                    <th>
                      <div className="form-group">
                        <a
                          onClick={this.filterDeleted}
                          className="btn btn-warning"
                        >
                          Filtreleri Temizle{" "}
                        </a>
                      </div>
                    </th>
                  </tr>
                  <tr>
                    <th>ID</th>
                    <th className="select-checkbox h-100">
                      <div
                        className="custom-control custom-switch d-flex justify-content-center align-items-center align-content-center"
                        onClick={(ev) => this.onSelectAllButtonClick(ev)}
                      >
                        <input
                          ref={this.selectAllRef}
                          type="checkbox"
                          className="custom-control-input"
                          id="selectAll"
                        />
                        <label
                          className="custom-control-label"
                          style={{ paddingLeft: "0" }}
                          htmlFor="selectAll"
                        ></label>
                      </div>
                    </th>

                    <th>{t("table.header.storage")}</th>
                    <th>{t("table.header.stockCode")}</th>
                    <th>{t("table.header.stockName")}</th>
                    <th>Barkod Numarası</th>
                    <th>{t("table.header.unit")}</th>
                    <th>{t("table.header.qua")}</th>
                    <th>{t("table.header.price")}</th>
                    <th>Tarih</th>
                    <th>İşlem</th>
                    {/* <th>{t("table.header.programAmount")}</th>
                                    <th>{t("table.header.countingAmount")}</th>
                                    <th>{t("table.header.countingDifference")}</th>
                                    <th>{t("table.header.storage")}</th> */}
                  </tr>
                </thead>
                <tbody className="w-100"></tbody>
              </table>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(withTranslation(["stock-list", "translations"])(StockList));
