import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { setBreadcrumbs } from '../../features/active-page/sub-pages-slice';
import { setPageHeader } from '../../features/active-page/page-header-slice';
import { setHeaderElements } from '../../features/active-page/header-elements-slice';
import { EventHandlerContext } from '../../contexts/event-handler-context';
import $ from "jquery";
import "datatables.net";
import "datatables.net-responsive";
import "datatables.net-select";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Functions from '../../class/functions';
import axios from 'axios';
import { getEndPoint } from "../../state/api/index"

Functions.importThemeStyles();

const ReactSwal = withReactContent(Swal);

const mapStateToProps = state => ({
    redux: {
        pageHeader: state.pageHeader,
        breadcrumbs: state.breadcrumbs,
        headerElements: state.headerElements
    }
});
const mapDispatchToProps = () => ({
    setPageHeader,
    setBreadcrumbs,
    setHeaderElements
});
let filter = 0;
class Sayim extends Component {
    static contextType = EventHandlerContext

    constructor(props) {
        super(props);
        this.stockRef = React.createRef();
        this.depoRef = React.createRef();

        this.createSelect = (ref, t, selectType) => {
            $(ref.current).select2({
                width: "100%",
                language: {
                    noResults: () => t("plugins.select2.noResults", { ns: "translations" }),
                    searching: () => t("plugins.select2.searching", { ns: "translations" }),
                    maximumSelected: () => t("plugins.select2.maximumSelected", { ns: "translations" }),
                    loadingMore: () => t("plugins.select2.loadingMore", { ns: "translations" }),
                    inputTooShort: () => t("plugins.select2.inputTooShort", { ns: "translations" }),
                    inputTooLong: () => t("plugins.select2.inputTooLong", { ns: "translations" }),
                    errorLoading: () => t("plugins.select2.errorLoading", { ns: "translations" }),
                }
            });
            Functions.createSelectList(ref, t, selectType);
            $(".selectTo").val(filter).trigger("change");
        }
    }

    componentDidMount() {
        const { t } = this.props;
        let _this = this;

        this.createSelect(this.depoRef, t, 'storages');
        this.props.setPageHeader(t("pages.storages.counting", { ns: "translations" }));

        if (this.stockListTable != undefined)
            this.stockListTable.destroy();

        this.stockListTable = $(".datatable-pagination").DataTable({
            pagingType: 'simple_numbers',
            autoWidth: false,
            responsive: true,
            processing: true,
            lengthMenu: [[50, 100, 150, -1], [50, 100, 150, "Hepsi"]],
            serverSide: true,
            dom: 'Blfrtip',
            buttons: [
                'copyHtml5',
                'excelHtml5',
                'csvHtml5',
                'pdfHtml5'
            ],
            ajax: {
                url: `${getEndPoint()}/api/stocks/all`,
                type: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                xhrFields: {
                    withCredentials: true
                },
                crossDomain: true,
                dataFilter: function (data) {
                    var json = $.parseJSON(data);
                    json.data = json.data;
                    json.recordsFiltered = json.result.recordsTotal
                    json.recordsTotal = json.result.recordsTotal
                    return JSON.stringify(json);
                },
            },
            fnServerParams: (aoData) => {
                aoData["filters"] = [
                    { expression: "=", column: "storage_id", value: filter },
                    { expression: "LIKE", column: $('.selectFilter').val(), value: '%' + String(aoData.search.value).toLocaleUpperCase('TR') + '%' },
                    { "expression": "order", "column": aoData.columns[aoData.order[0].column].data, "value": aoData.order[0].dir },
                ];
                aoData["page"] = aoData.start / aoData.length;
                aoData["per_page"] = aoData.length;
            },
            "columns": [
                { data: "id", "orderable": "false" },
                { data: "stock_code", "orderable": "false" },
                {
                    data: "stock_name", render: function (data, type, row) {
                        if (data == null) {
                            return ' ';
                        }
                        return data
                    }
                },
                {
                    data: "stock_brand", render: function (data, type, row) {
                        if (data == null) {
                            return ' ';
                        }
                        return data
                    }
                },
                {
                    data: "id", render: function (data, type, row) {
                        if (data == null) {
                            return ' ';
                        }
                        return row.get_hareket_stock_list_giris_sum_miktar - row.get_hareket_stock_list_cikis_sum_miktar
                    }
                },
                {
                    data: "price", render: function (data, type, row) {
                        if (data == null) {
                            return ' ';
                        }
                        return data
                    }
                },
                {
                    data: "created_at", render: function (data, type, row) {
                        if (data == null) {
                            return ' ';
                        }
                        return Functions.getFormatLocaleDateList(data.substring(0, 10));
                    }
                },

            ],
            order: [[0, 'asc']],
            language: {
                ...(t("plugins.dataTables", { ns: "translations" })),
                paginate: {
                    'next': $('html').attr('dir') === 'rtl' ? t("plugins.dataTables.paginate.next", { ns: "translations" }) + '&larr;' : t("plugins.dataTables.paginate.next", { ns: "translations" }) + ' &rarr;',
                    'previous': $('html').attr('dir') === 'rtl' ? '&rarr; ' + t("plugins.dataTables.paginate.previous", { ns: "translations" }) : '&larr; ' + t("plugins.dataTables.paginate.previous", { ns: "translations" })
                }
            }
        });
        $('.selectFilter').select2({
            width: '100%'
        });

        $('.selectTo').on('select2:select', function (e) {
            filter = e.params.data.id;
            $.fn.dataTable.tables({ api: true }).ajax.reload();

        });
    }

    componentWillUnmount() {
        this.props.setPageHeader("");
        this.props.setHeaderElements([]);
        this.context.handlers = [];
    }


    render() {
        const { t } = this.props;

        return (
            <>

                <div className="card p-1 m-0">
                    <div className="card-body p-1 m-0">

                        <div className='row'>

                            <div className="col-md-12 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="depo">{t("table.header.pleaseSelectStorage")}</label>

                                    <select ref={this.depoRef} name="depo" data-placeholder={t("placeholders.transferTo")} className="form-control select selectTo" data-fouc>
                                        <option value="0">Yükleniyor...</option>
                                    </select>
                                </div>
                            </div>


                        </div>

                    </div>
                </div>

                <div className="card p-1 m-0">
                    <div className="card-body p-1 m-0">
                        <div className='row'>
                            <div className="col-md-12 col-sm-12">
                                <table className="table table-striped table-hover dt-responsive nowrap datatable-pagination w-100">
                                    <thead className='w-100'>
                                        <tr>
                                            <th>
                                                <label>Aranılacak Alan</label>

                                            </th>
                                            <th>
                                                <div className='form-group'>
                                                    <select className='selectFilter' ref={this.columnSelect}>
                                                        <option value="stock_name">Stok Adı</option>
                                                        <option value="stock_code">Stok Kodu</option>
                                                        <option value="barcode_number">Barkod Numarası</option>
                                                    </select>
                                                </div>
                                            </th>

                                        </tr>
                                        <tr>
                                            <th>ID</th>
                                            <th>Stok Kodu</th>
                                            <th>Stok Adı</th>
                                            <th>Stok Markası</th>
                                            <th>Stok Miktarı</th>
                                            <th>Fiyatı</th>

                                        </tr>

                                    </thead>
                                    <tbody className='w-100'>

                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            </ >
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps())(
    withTranslation(["storage-counting", "translations"])(Sayim)
);