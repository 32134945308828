import { config } from "../../config";

export const getEndPoint = () => {
  return config.endPointStage.url;
};

export const getReCaptcha = () => {
  return config.endPointStage.reCaptcha;
};

export const getUploadsImgUri = () => {
  return config.endPointStage.uploadsImgUri;
};

export const uploadsFaturaUri = () => {
  return config.endPointStage.uploadsFaturaUri;
};
export const getQueryStringParams = (query) => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split("&")
        .reduce((params, param) => {
          let [key, value] = param.split("=");
          params[key] = value
            ? decodeURIComponent(value.replace(/\+/g, " "))
            : "";
          return params;
        }, {})
    : {};
};
