import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { setBreadcrumbs } from "../../features/active-page/sub-pages-slice";
import { setPageHeader } from "../../features/active-page/page-header-slice";
import { setHeaderElements } from "../../features/active-page/header-elements-slice";
import { EventHandlerContext } from "../../contexts/event-handler-context";
import Functions from "../../class/functions";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import $ from "jquery";
import { getEndPoint } from "../../state/api/index";
import ImageUploader from "react-images-upload";
import { useState } from "react";

Functions.importThemeStyles();

const ReactSwal = withReactContent(Swal);
let image = null;
const mapStateToProps = (state) => ({
  redux: {
    pageHeader: state.pageHeader,
    breadcrumbs: state.breadcrumbs,
    headerElements: state.headerElements,
  },
});
const mapDispatchToProps = () => ({
  setPageHeader,
  setBreadcrumbs,
  setHeaderElements,
});

class StockAdd extends Component {
  static contextType = EventHandlerContext;

  constructor(props) {
    super(props);
    this.Stocklist = createRef();
    const { t } = this.props;

    this.stock_code = createRef();
    this.custom_stock_code = createRef();
    this.stock_name = createRef();
    this.stock_brand = createRef();
    this.barcode_number = createRef();
    this.qr_code = createRef();
    this.ozellikler = createRef();
    this.description = createRef();
    this.storage_id = createRef();
    this.minimum_stock = createRef();
    this.maximum_stock = createRef();
    this.vat_ratio = createRef();
    this.price = createRef();
    this.shelf_no = createRef();
    this.sub_shelf_no = createRef();
    this.unit_id = createRef();
    this.unit_id_2 = createRef();
    this.company_id = createRef();
    this.responsible_employee_id = createRef();

    this.createSelect = (ref, t, selectType) => {
      Functions.createSelectList(ref, t, selectType);
    };
    this.validateStatus = false;
    this.validateData = false;
    this.stockID = 0;
    this.state = {
      qrCode: [React.createRef()],
      stockList: "",
      stockData: "",
    };

    const _this = this;

    var stocks = {
      method: "get",
      url: `${getEndPoint()}/api/stocks/all`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(stocks).then(function (response) {
      var selectData = response.data.data.map(
        (stockAll) => stockAll.stock_code
      );
      $(_this.stock_code.current).empty().trigger("change");
      var selects = $(_this.stock_code.current);

      var defaultOption = new Option("Lütfen seçim yapınız...", "");
      selects.append(defaultOption).trigger("change");

    
      var customOption = new Option("Yeni Stok Kodu Oluştur", "custom");
      selects.append(customOption).trigger("change");

      const filterNumbers = () => {
        const numbers = {};
        selectData.forEach((item) => {
          const match = item.match(/\d+$/);
          if (match) {
            const number = match[0];
            const prefix = item.slice(0, item.length - number.length);
            if (
              !(prefix in numbers) ||
              parseInt(number) > parseInt(numbers[prefix])
            ) {
              numbers[prefix] = number;
            }
          }
        });
        return numbers;
      };

      const mergedData = () => {
        const filteredNumbers = filterNumbers();
        return Object.entries(filteredNumbers).map(
          ([prefix, number]) => prefix + number
        );
      };

      const incrementedData = () => {
        const mergedResults = mergedData();
        return mergedResults.map((data) => {
          const prefix = data.slice(0, -2);
          const number = parseInt(data.slice(-2)) + 1;
          return prefix + ("00" + number).slice(-2);
        });
      };

      const options = incrementedData().map((data, index) => {
        return new Option(data, data);
      });

      selects.append(options);

      selects.on("change", function () {
        var selectedValue = selects.val();
        if (selectedValue === "custom") {
          var customValue = $(_this.custom_stock_code.current).val();
          if (customValue) {
            selects.val(customValue);
            _this.stock_code.current.value = customValue; 
          }
          $(_this.custom_stock_code.current).show(); 
        } else {
          $(_this.custom_stock_code.current).hide(); 
        }
      });
    });

    var config = {
      method: "get",
      url: `${getEndPoint()}/api/companies/all`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios(config).then(function (response) {
      var selectData = response.data.data;
      $(_this.company_id.current).empty().trigger("change");
      var selects = $(_this.company_id.current);

      var option = new Option("Lütfen Seçim Yapınız...", 0, true, true);
      selects.append(option).trigger("change");
      selectData.forEach((elm, index) => {
        var option = new Option(elm.name, elm.id, false, false);
        selects.append(option).trigger("change");
      });
    });

    this.createSelect(this.storage_id, t, "storages");
    this.createSelect(this.responsible_employee_id, t, "employee");
    this.createSelect(this.unit_id, t, "units");
  }

  componentDidMount() {
    const { t } = this.props;
    const _this = this;

    $(".select2Select").select2({
      width: "100%",
      language: {
        noResults: () => t("plugins.select2.noResults", { ns: "translations" }),
        searching: () => t("plugins.select2.searching", { ns: "translations" }),
        maximumSelected: () =>
          t("plugins.select2.maximumSelected", { ns: "translations" }),
        loadingMore: () =>
          t("plugins.select2.loadingMore", { ns: "translations" }),
        inputTooShort: () =>
          t("plugins.select2.inputTooShort", { ns: "translations" }),
        inputTooLong: () =>
          t("plugins.select2.inputTooLong", { ns: "translations" }),
        errorLoading: () =>
          t("plugins.select2.errorLoading", { ns: "translations" }),
      },
    });

    this.stock_code.current.value = "";
    this.custom_stock_code.current.value="";
    this.stock_name.current.value = "";
    this.stock_brand.current.value = "";
    this.barcode_number.current.value = "";
    this.qr_code.current.value = "";
    this.ozellikler.current.value = "";
    this.description.current.value = "";
    this.storage_id.current.value = "";
    this.minimum_stock.current.value = "";
    this.maximum_stock.current.value = "";
    this.vat_ratio.current.value = "";
    this.price.current.value = "";
    this.shelf_no.current.value = "";
    this.sub_shelf_no.current.value = "";
    this.unit_id.current.value = "";
    this.unit_id_2.current.value = "";
    this.company_id.current.value = "";
    //this.responsible_employee_id.current.value = ""

    this.props.setPageHeader(t("pages.stocks.add", { ns: "translations" }));
    this.props.setHeaderElements([
      {
        componentId: "add-stock",
        href: "#",
        translation: { key: "misc.add", opts: { ns: "translations" } },
        className: "btn btn-link btn-float text-body",
        icon: "fas fa-plus text-primary",
      },
    ]);

    $(this.Stocklist.current).on("select2:select", function (e) {
      var data = e.params.data;

      this.qr_code.current.value = "";
      this.ozellikler.current.value = "";
      this.description.current.value = "";
      this.storage_id.current.value = "";
      this.minimum_stock.current.value = "";
      this.maximum_stock.current.value = "";
      this.vat_ratio.current.value = "";
      this.price.current.value = "";
      this.shelf_no.current.value = "";
      this.sub_shelf_no.current.value = "";
      this.unit_id.current.value = "";
      this.unit_id_2.current.value = "";
      this.company_id.current.value = "";
      this.responsible_employee_id.current.value = "";

      var stockData = _this.state.stockList.filter((x) => x.id == data.id);
      _this.stockID = data.id;
      _this.stock_code.current.value = stockData[0].stock_code;
      _this.stock_name.current.value = stockData[0].stock_name;
      _this.stock_brand.current.value = stockData[0].stock_brand;
      _this.ozellikler.current.value = stockData[0].ozellikler;
      _this.barcode_number.current.value = stockData[0].barcode_number;
      _this.qr_code.current.value = stockData[0].qr_code;
      $(_this.storage_id.current)
        .val(String(stockData[0].storage_id))
        .trigger("change");
      _this.minimum_stock.current.value = stockData[0].minimum_stock;
      _this.maximum_stock.current.value = stockData[0].maximum_stock;
      _this.vat_ratio.current.value = stockData[0].vat_ratio;
      _this.price.current.value = stockData[0].price;
      _this.shelf_no.current.value = stockData[0].shelf_no;
      _this.sub_shelf_no.current.value = stockData[0].sub_shelf_no;
      $(_this.unit_id.current)
        .val(String(stockData[0].unit_id))
        .trigger("change");
      _this.unit_id_2.current.value = stockData[0].unit_id_2;
      $(_this.company_id.current)
        .val(String(stockData[0].company_id))
        .trigger("change");
      $(_this.responsible_employee_id.current)
        .val(String(stockData[0].stock_storage.responsible_employee_id))
        .trigger("change");
    });

    this.context.addEventHandler("add-stock", () => {
      var balance = this.price.current.value.replace(",", ".");
      let formData = new FormData();
      var selectedValue = this.stock_code.current.value;
      if (selectedValue === "custom") {
        formData.append("stock_code", this.custom_stock_code.current.value);
      } else {
        formData.append("stock_code", this.stock_code.current.value);
      }
      formData.append("id", parseInt(this.stockID));
      //formData.append("stock_code", this.stock_code.current.value);
      formData.append("stock_name", this.stock_name.current.value);
      formData.append("stock_brand", this.stock_brand.current.value);
      formData.append("barcode_number", this.barcode_number.current.value);
      formData.append("qr_code", this.qr_code.current.value);
      formData.append("ozellikler", this.ozellikler.current.value);
      formData.append("description", this.description.current.value);
      formData.append("storage_id", parseInt(this.storage_id.current.value));
      formData.append(
        "minimum_stock",
        parseInt(
          this.minimum_stock.current.value == ""
            ? "0"
            : this.minimum_stock.current.value
        )
      ); //int
      formData.append(
        "maximum_stock",
        parseInt(
          this.maximum_stock.current.value == ""
            ? "0"
            : this.maximum_stock.current.value
        )
      );
      formData.append(
        "vat_ratio",
        parseInt(
          this.vat_ratio.current.value == ""
            ? "0"
            : this.vat_ratio.current.value
        )
      );
      formData.append(
        "price",
        parseFloat(balance == "" ? "0" : balance).toFixed(2)
      );
      formData.append("shelf_no", this.shelf_no.current.value);
      formData.append("sub_shelf_no", this.sub_shelf_no.current.value);
      formData.append("unit_id", parseInt(this.unit_id.current.value));
      formData.append("unit_id_2", this.unit_id_2.current.value);
      formData.append("company_id", parseInt(this.company_id.current.value));
      //formData.append("responsible_employee_id", parseInt(this.responsible_employee_id.current.value))
      const employee = localStorage.getItem("__react_session__");
      const obj_employee = JSON.parse(employee);
      formData.append(
        "responsible_employee_id",
        parseInt(obj_employee.userData.id)
      );
      if (image != null) {
        for (let i = 0; i < image.length; i++) {
          formData.append(`files[${i}]`, image[i]);
        }
      } else {
        formData.append(`files[0]`, " ");
      }
      if (formData.get("company_id") == "0") {
        ReactSwal.fire("Hata", "Eksik Parametre Girildi: Şirket,", "error");
        return null;
      }
      if (formData.get("stock_code") == "") {
        ReactSwal.fire("Hata", "Eksik Parametre Girildi: Stok Kodu,", "error");
        return null;
      }
      if (formData.get("unit_id") == "0") {
        ReactSwal.fire("Hata", "Eksik Parametre Girildi: Birim Tipi,", "error");
        return null;
      }
      // if (formData.get("responsible_employee_id") == "0") {
      //     ReactSwal.fire('Hata', 'Eksik Parametre Girildi: Sorumlu Görevli,', 'error')
      //     return null
      // }
      if (formData.get("storage_id") == "0") {
        ReactSwal.fire("Hata", "Eksik Parametre Girildi: Depo,", "error");
        return null;
      }
      if (formData.get("minimum_stock" <= 0)) {
        ReactSwal.fire(
          "Hata",
          "Eksik Parametre Girildi: Minimum Stok,",
          "error"
        );
        return null;
      }

      if (formData.get("maximum_stock" <= 0)) {
        ReactSwal.fire(
          "Hata",
          "Eksik Parametre Girildi: Maksimum Stok,",
          "error"
        );
        return null;
      }
      if (formData.get("stock_name") == "") {
        ReactSwal.fire("Hata", "Eksik Parametre Girildi: Stok İsmi,", "error");
        return null;
      }
      if (formData.get("storage_id") == "") {
        ReactSwal.fire("Hata", "Eksik Parametre Girildi: Depo,", "error");
        return null;
      }
      if (formData.get("minimum_stock") == "") {
        ReactSwal.fire(
          "Hata",
          "Eksik Parametre Girildi: Minimum Stok,",
          "error"
        );
        return null;
      }
      if (formData.get("maximum_stock") == "") {
        console.log("girdi");
        ReactSwal.fire(
          "Hata",
          "Eksik Parametre Girildi: Maksimum Stok,",
          "error"
        );
        return null;
      }
      if (formData.get("vat_ratio") == NaN) {
        ReactSwal.fire("Hata", "Eksik Parametre Girildi: KDV,", "error");
        return null;
      }
      if (balance == NaN) {
        ReactSwal.fire("Hata", "Eksik Parametre Girildi: Fiyat,", "error");
        return null;
      }

      if (formData.get("unit_id") == "") {
        ReactSwal.fire("Hata", "Eksik Parametre Girildi: Birim Tipi,", "error");
        return null;
      }
      if (formData.get("company_id") == "") {
        ReactSwal.fire("Hata", "Eksik Parametre Girildi: company_id,", "error");
        return null;
      }

      var config = {
        method: "post",
        url: `${getEndPoint()}/api/stocks/add`,
        headers: {
          "Content-Type": "multipart/form-data",
          boundary: "WebKitFormBoundaryyrV7KO0BoCBuDbTL",
          charset: "utf-8",
        },
        data: formData,
      };

      axios(config)
        .then(function (response) {
          ReactSwal.fire({
            title: <span>{t("misc.successfull", { ns: "translations" })}</span>,
            html: (
              <span>{t("operations.success", { ns: "translations" })}</span>
            ),
            icon: "",
            showCloseButton: true,
            focusConfirm: false,
            confirmButtonText: t("misc.ok", { ns: "translations" }),
          });
        })
        .catch(function (error) {
          console.log(error.response.data.data[0]);
          if (error.response.data.data[0] == "validation.unique") {
            ReactSwal.fire("Hata", "Bu Stok kodu Mevcut", "error");
            return;
          }
          ReactSwal.fire(
            "Hata",
            "Eksik Parametre Girildi: Lütfen Tüm Alanları Kontrol Ediniz,",
            "error"
          );
        });
      // _this.componentDidMount();
    });
  }

  componentWillUnmount() {
    this.props.setPageHeader("");
    this.props.setHeaderElements([]);
    this.context.handlers = [];
  }

  onAddStockButtonClick(ev) {}

  render() {
    const { t } = this.props;

    return (
      <div>
        <div className="card p-1 m-0">
          <div className="card-body p-1 m-0">
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="stockName">{t("stockName")}</label>
                  <input
                    ref={this.stock_name}
                    required
                    name="stock_name"
                    type="text"
                    className="form-control"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="stockBarcode">{t("stockBarcode")}</label>
                  <input
                    ref={this.barcode_number}
                    required
                    name="barcode_number"
                    type="text"
                    className="form-control"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="shelfNo">{t("shelfNo")}</label>
                  <input
                    ref={this.shelf_no}
                    name="shelf_no"
                    type="text"
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="unit_id">{t("unit")}</label>
                  <select
                    ref={this.unit_id}
                    required
                    name="unit_id"
                    className="select2Select form-control"
                  ></select>
                </div>
                {/* <div className="form-group">
                                    <label htmlFor="responsible_employee_id">{t("responsible_employee_id")}</label>
                                    <select ref={this.responsible_employee_id} name="responsible_employee_id" className="select2Select form-control">

                                    </select>
                                </div> */}
                <div className="form-group">
                  <label htmlFor="maximumStock">Maksimum Stok</label>
                  <input
                    ref={this.maximum_stock}
                    name="maximum_stock"
                    type="number"
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="minimumStock">{t("minimumStock")}</label>
                  <input
                    defaultValue={"0"}
                    ref={this.minimum_stock}
                    name="minimum_stock"
                    type="number"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="qr_code">QR Kod</label>
                  <input
                    ref={this.qr_code}
                    required
                    id="qr_code"
                    type="text"
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="company_id">{t("company_id")}</label>
                  <select
                    ref={this.company_id}
                    required
                    name="company_id"
                    type="number"
                    className="select2Select form-control"
                  ></select>
                </div>
                <div className="form-group">
                  <label htmlFor="subShelfNo">{t("subShelfNo")}</label>
                  <input
                    ref={this.sub_shelf_no}
                    name="sub_shelf_no"
                    type="text"
                    className="form-control"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="unit_id_2">{t("unit")} 2</label>
                  <input
                    ref={this.unit_id_2}
                    name="unit_id_2"
                    className="form-control"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="stock_brand">{t("brand_id")}</label>
                  <input
                    ref={this.stock_brand}
                    id="stock_brand"
                    name="stock_brand"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="storage_id">{t("storage_id")}</label>
                  <select
                    required
                    ref={this.storage_id}
                    name="storage_id"
                    className="select2Select form-control"
                  ></select>
                </div>
                <div className="form-group">
                  <label htmlFor="stockCode">{t("stockCode")}</label>
                  {/* <input ref={this.stock_code} required id="stock_code" type="text" className='form-control' /> */}
                  <select
                    ref={this.stock_code}
                    required
                    name="stock_code"
                    type="text"
                    className="select2Select form-control"
                  ></select>
                  <input
                    ref={this.custom_stock_code}
                    name="custom_stock_code"
                    type="text"
                    className="form-control"
                    placeholder="Stok kodu girin..."
                    style={{ display: "none" }} // input alanını gizle
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="vatRatio">{t("vatRatio")}</label>
                  <input
                    ref={this.vat_ratio}
                    required
                    name="vat_ratio"
                    type="number"
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="price">{t("price")}</label>
                  <input
                    ref={this.price}
                    required
                    name="price"
                    type="text"
                    className="form-control"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="maximumStock">Özellikler</label>
                  <input
                    ref={this.ozellikler}
                    name="ozellikler"
                    type="text"
                    className="form-control"
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="description">{t("description")}</label>
                  <textarea
                    ref={this.description}
                    name="description"
                    className="form-control"
                  />
                </div>
              </div>

              <div className="col-md-12">
                <ImageUploader
                  withIcon={true}
                  buttonText="Lütfen Resim Seçiniz"
                  onChange={(file) => {
                    image = file;
                  }}
                  imgExtension={[".jpg", ".jpeg", ".png"]}
                  maxFileSize={5242880}
                  withPreview={true}
                  label="Max Dosyas Boyutu 5MB, Sadece JPG-JPEG-PNG Formatları Kabul Edilmektedir."
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(withTranslation(["stock-add", "translations"])(StockAdd));