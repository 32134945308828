import React, { Component } from "react";
import QRCode from "qrcode";
import Functions from "../class/functions";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { setBreadcrumbs } from "../features/active-page/sub-pages-slice";
import { setPageHeader } from "../features/active-page/page-header-slice";
import { setHeaderElements } from "../features/active-page/header-elements-slice";
import { getEndPoint } from "../state/api/index";
import axios from "axios";

const mapStateToProps = (state) => ({
  redux: {
    pageHeader: state.pageHeader,
    breadcrumbs: state.breadcrumbs,
    headerElements: state.headerElements,
  },
});
const mapDispatchToProps = () => ({
  setPageHeader,
  setBreadcrumbs,
  setHeaderElements,
});

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stock: 0,
      storage: 0,
      entries: 0,
      counting: 0,
    };
    this.qrCodeRef = React.createRef();
    this.qrCodeValue = React.createRef();
  }

  componentDidMount() {
    const { t } = this.props;
    const _this = this;

    this.props.setPageHeader(t("pages.dashboard", { ns: "translations" }));
    this.props.setHeaderElements([]);
    this.props.setBreadcrumbs([]);

    var config = {
      method: "get",
      url: `${getEndPoint()}/api/home-page-list`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios(config).then(function (response) {
      _this.setState({
        stock: response.data.data.stock,
        storage: response.data.data.storage,
        entries: response.data.data.entries,
        counting: response.data.data.counting,
      });
    });
  }

  render() {
    return (
      <div className="homepage">
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <div className="card bg-teal text-white">
                <div className="card-body">
                  <div className="d-flex">
                    <h3 className="font-weight-semibold mb-0">
                      {this.state.stock}
                    </h3>
                  </div>

                  <div>Son 30 Gündeki Yeni Stok Sayısı</div>
                </div>
                <div className="container-fluid"></div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <div className="card bg-danger text-white">
                <div className="card-body">
                  <div className="d-flex">
                    <h3 className="font-weight-semibold mb-0">
                      {this.state.storage}
                    </h3>
                  </div>

                  <div>Son 30 Gündeki Yeni Depo Sayısı</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <div className="card bg-primary text-white">
                <div className="card-body">
                  <div className="d-flex">
                    <h3 className="font-weight-semibold mb-0">
                      {this.state.entries}
                    </h3>
                  </div>

                  <div>Son 30 Gündeki Stok Giriş Sayısı</div>
                </div>
                <div className="container-fluid"></div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <div className="card bg-primary text-white">
                <div className="card-body">
                  <div className="d-flex">
                    <h3 className="font-weight-semibold mb-0">
                      {this.state.counting}
                    </h3>
                  </div>

                  <div>Son 30 Gündeki Stok Çıkışı Sayısı</div>
                </div>
                <div className="container-fluid"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(withTranslation(["homepage", "translations"])(Functions.withRouter(Home)));
