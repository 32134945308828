import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { setBreadcrumbs } from "../../features/active-page/sub-pages-slice";
import { setPageHeader } from "../../features/active-page/page-header-slice";
import { setHeaderElements } from "../../features/active-page/header-elements-slice";
import { EventHandlerContext } from "../../contexts/event-handler-context";
import Functions from "../../class/functions";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import $ from "jquery";
import { getEndPoint, getUploadsImgUri } from "../../state/api/index";
import ImageUploader from "react-images-upload";
import QRCode from "qrcode";

Functions.importThemeStyles();

const ReactSwal = withReactContent(Swal);
let image = null;
let stockData = new Array();
let stockDatas;
var pathArray = window.location.pathname.split("/");

const mapStateToProps = (state) => ({
  redux: {
    pageHeader: state.pageHeader,
    breadcrumbs: state.breadcrumbs,
    headerElements: state.headerElements,
  },
});
const mapDispatchToProps = () => ({
  setPageHeader,
  setBreadcrumbs,
  setHeaderElements,
});

class StockUpdate extends Component {
  static contextType = EventHandlerContext;

  constructor(props) {
    super(props);
    const { t } = this.props;
    this.Stocklist = createRef();
    this.stock_code = createRef();
    this.stock_name = createRef();
    this.barcode_number = createRef();
    this.qr_code = createRef();
    this.stock_brand = createRef();
    this.ozellikler = createRef();
    this.description = createRef();
    this.storage_id = createRef();
    this.minimum_stock = createRef();
    this.maximum_stock = createRef();
    this.alert1 = createRef();
    this.quantity = createRef();
    this.vat_ratio = createRef();
    this.price = createRef();
    this.shelf_no = createRef();
    this.sub_shelf_no = createRef();
    this.unit_id = createRef();
    this.unit_id_2 = createRef();
    this.company_id = createRef();

    this.createSelect = (ref, t, selectType) => {
      Functions.createSelectList(ref, t, selectType);
    };
    this.validateStatus = false;
    this.validateData = false;
    this.stockID = 0;
    this.state = {
      qrCode: [React.createRef()],
      stockList: "",
      stockDataImage: [],
      stockDataImageData: [],
    };
    const _this = this;

    var config = {
      method: "get",
      url: `${getEndPoint()}/api/companies/all`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios(config).then(function (response) {
      var selectData = response.data.data;
      $(_this.company_id.current).empty().trigger("change");
      var selects = $(_this.company_id.current);
      var option = new Option("Lütfen Seçim Yapınız...", 0, true, true);
      selects.append(option).trigger("change");
      selectData.forEach((elm, index) => {
        var option = new Option(elm.name, elm.id, false, false);
        selects.append(option).trigger("change");
      });
    });
    this.createSelect(this.storage_id, t, "storages");
  }

  componentDidMount() {
    const { t } = this.props;
    const _this = this;
    $(".select2Select").select2({
      width: "100%",
      language: {
        noResults: () => t("plugins.select2.noResults", { ns: "translations" }),
        searching: () => t("plugins.select2.searching", { ns: "translations" }),
        maximumSelected: () =>
          t("plugins.select2.maximumSelected", { ns: "translations" }),
        loadingMore: () =>
          t("plugins.select2.loadingMore", { ns: "translations" }),
        inputTooShort: () =>
          t("plugins.select2.inputTooShort", { ns: "translations" }),
        inputTooLong: () =>
          t("plugins.select2.inputTooLong", { ns: "translations" }),
        errorLoading: () =>
          t("plugins.select2.errorLoading", { ns: "translations" }),
      },
    });
    this.createSelect(this.unit_id, t, "units");
    $("#storage").value = "1";
    // this.props.setBreadcrumbs([
    //     { href: "/", className: "", value: "Test 1" },
    //     { href: "/", className: "", value: "Test 2" },
    //     { href: "/", className: "", value: "Test 3" },
    //     { href: "/", className: "", value: "Test 4" }
    // ]);

    var config = {
      method: "PUT",
      url: `${getEndPoint()}/api/stocks/all`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        filters: [{ expression: "=", column: "id", value: pathArray[2] }],
      }),
    };
    axios(config).then((response) => {
      stockData = response.data.data;
      _this.setState({
        stockDataImage: stockData[0].stock_images,
      });
      _this.stockID = pathArray[2];
      _this.stock_code.current.value = stockData[0].stock_code;
      _this.stock_name.current.value = stockData[0].stock_name;
      _this.stock_brand.current.value = stockData[0].stock_brand;
      _this.ozellikler.current.value = stockData[0].ozellikler;
      _this.description.current.value = stockData[0].description;
      _this.barcode_number.current.value = stockData[0].barcode_number;
      _this.qr_code.current.value = stockData[0].qr_code;
      $(_this.storage_id.current)
        .val(String(stockData[0].storage_id))
        .trigger("change");
      _this.minimum_stock.current.value = stockData[0].minimum_stock;
      _this.maximum_stock.current.value = stockData[0].maximum_stock;
      _this.alert1.current.checked = stockData[0].alert1;
      _this.vat_ratio.current.value = stockData[0].vat_ratio;
      _this.price.current.value = stockData[0].price;
      _this.quantity.current.value =
        stockData[0].get_hareket_stock_list_giris_sum_miktar -
        stockData[0].get_hareket_stock_list_cikis_sum_miktar;
      _this.shelf_no.current.value = stockData[0].shelf_no;
      _this.sub_shelf_no.current.value = stockData[0].sub_shelf_no;
      $(_this.unit_id.current).val(stockData[0].unit_id).trigger("change");
      _this.unit_id_2.current.value = stockData[0].unit_id_2;
      $(_this.company_id.current)
        .val(String(stockData[0].company_id))
        .trigger("change");
    });

    this.props.setPageHeader("Stok Güncelle");
    this.props.setHeaderElements([
      {
        componentId: "add-stock",
        href: "#",
        translation: { key: "Kaydet", opts: { ns: "translations" } },
        className: "btn btn-link btn-float text-body",
        icon: "fas fa-plus text-primary",
      },
    ]);

    this.context.addEventHandler("add-stock", () => {
      var balance = this.price.current.value.replace(",", ".");
      let formData = new FormData();
      formData.append("id", pathArray[2]);
      formData.append("stock_code", this.stock_code.current.value);
      formData.append("stock_name", this.stock_name.current.value);
      formData.append("stock_brand", this.stock_brand.current.value);
      formData.append("barcode_number", this.barcode_number.current.value);
      formData.append("qr_code", this.qr_code.current.value);
      formData.append("ozellikler", this.ozellikler.current.value);
      formData.append("description", this.description.current.value);
      formData.append("storage_id", parseInt(this.storage_id.current.value));
      formData.append(
        "minimum_stock",
        parseInt(this.minimum_stock.current.value)
      ); //int
      formData.append(
        "maximum_stock",
        parseInt(this.maximum_stock.current.value)
      );
      formData.append("alert1", this.alert1.current.checked);
      formData.append("vat_ratio", parseInt(this.vat_ratio.current.value));
      formData.append("price", parseFloat(balance).toFixed(2));
      formData.append("shelf_no", this.shelf_no.current.value);
      formData.append("sub_shelf_no", this.sub_shelf_no.current.value);
      formData.append("unit_id", parseInt(this.unit_id.current.value));
      formData.append("unit_id_2", this.unit_id_2.current.value);
      formData.append("company_id", parseInt(this.company_id.current.value));
      if (image != null) {
        for (let i = 0; i < image.length; i++) {
          formData.append(`files[${i}]`, image[i]);
        }
      } else {
        formData.append(`files[0]`, " ");
      }
      // console.log(formData.getAll());
      // if (formData.get("company_id") == "0") {
      //   ReactSwal.fire("Hata", "Eksik Parametre Girildi: Şirket,", "error");
      //   return null;
      // }
      if (formData.get("stock_code") == "") {
        ReactSwal.fire("Hata", "Eksik Parametre Girildi: Stok Kodu,", "error");
        return null;
      }
      if (formData.get("unit_id") == "0") {
        ReactSwal.fire("Hata", "Eksik Parametre Girildi: Birim Tipi,", "error");
        return null;
      }
      if (formData.get("storage_id") == "0") {
        ReactSwal.fire("Hata", "Eksik Parametre Girildi: Depo,", "error");
        return null;
      }
      if (formData.get("stockCode") == "") {
        ReactSwal.fire("Hata", "Eksik Parametre Girildi: Stok Kodu,", "error");
        return null;
      }
      if (formData.get("minimum_stock" <= 0)) {
        ReactSwal.fire(
          "Hata",
          "Eksik Parametre Girildi: Minimum Stok,",
          "error"
        );
        return null;
      }

      if (formData.get("maximum_stock" <= 0)) {
        ReactSwal.fire(
          "Hata",
          "Eksik Parametre Girildi: Maksimum Stok,",
          "error"
        );
        return null;
      }
      if (formData.get("stock_name") == "") {
        ReactSwal.fire("Hata", "Eksik Parametre Girildi: Stok İsmi,", "error");
        return null;
      }

      if (formData.get("storage_id") == "") {
        ReactSwal.fire("Hata", "Eksik Parametre Girildi: Depo,", "error");
        return null;
      }
      if (formData.get("minimum_stock") == "") {
        ReactSwal.fire(
          "Hata",
          "Eksik Parametre Girildi: Minimum Stok,",
          "error"
        );
        return null;
      }
      if (formData.get("maximum_stock") == "") {
        ReactSwal.fire(
          "Hata",
          "Eksik Parametre Girildi: Maksimum Stok,",
          "error"
        );
        return null;
      }
      if (formData.get("vat_ratio") == NaN) {
        ReactSwal.fire("Hata", "Eksik Parametre Girildi: KDV,", "error");
        return null;
      }
      if (balance == NaN) {
        ReactSwal.fire("Hata", "Eksik Parametre Girildi: Fiyat,", "error");
        return null;
      }

      if (formData.get("unit_id") == "") {
        ReactSwal.fire("Hata", "Eksik Parametre Girildi: Birim Tipi,", "error");
        return null;
      }
      if (formData.get("company_id") == "") {
        ReactSwal.fire("Hata", "Eksik Parametre Girildi: company_id,", "error");
        return null;
      }

      var config = {
        method: "post",
        url: `${getEndPoint()}/api/stocks/add`,
        headers: {
          "Content-Type": "multipart/form-data",
          boundary: "WebKitFormBoundaryyrV7KO0BoCBuDbTL",
          charset: "utf-8",
        },
        data: formData,
      };

      axios(config).then(function (response) {
        ReactSwal.fire({
          title: <span>{t("misc.successfull", { ns: "translations" })}</span>,
          html: <span>{t("operations.success", { ns: "translations" })}</span>,
          icon: "",
          showCloseButton: true,
          focusConfirm: false,
          confirmButtonText: t("misc.ok", { ns: "translations" }),
        });
      });
      // _this.componentDidMount();
    });
    setTimeout(() => {
      document.getElementById("stock_code").click();
    }, 5000);
  }

  componentWillUnmount() {
    this.props.setPageHeader("");
    this.props.setHeaderElements([]);
    this.context.handlers = [];
  }

  onAddStockButtonClick(ev) {}
  imageRemoves(e) {
    // e.target.id
    const _this = this;
    var config = {
      method: "post",
      url: `${getEndPoint()}/api/stocks/images/delete`,
      headers: {
        "Content-Type": "multipart/form-data",
        boundary: "WebKitFormBoundaryyrV7KO0BoCBuDbTL",
        charset: "utf-8",
      },
      data: JSON.stringify({
        ids: e.target.id,
      }),
    };

    axios(config).then(function (response) {
      _this.componentDidMount();
    });
  }
  onGenerateQRCode(ev) {
    if (this.qr_code == null || this.qr_code.current == null) return;

    QRCode.toCanvas(
      this.qr_code.current,
      this.stock_code.current.value,
      {
        scale: 8,
      },
      function (error) {
        if (error) console.error(error);
      }
    );
  }
  render() {
    const { t } = this.props;
    const __this = this;

    return (
      <div>
        <div className="card p-1 m-0">
          <div className="card-body p-1 m-0">
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="stockName">{t("stockName")}</label>
                  <input
                    ref={this.stock_name}
                    required
                    name="stock_name"
                    type="text"
                    className="form-control"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="stockBarcode">{t("stockBarcode")}</label>
                  <input
                    ref={this.barcode_number}
                    required
                    name="barcode_number"
                    type="text"
                    className="form-control"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="shelfNo">{t("shelfNo")}</label>
                  <input
                    ref={this.shelf_no}
                    name="shelf_no"
                    type="text"
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="unit_id">{t("unit")}</label>
                  <select
                    ref={this.unit_id}
                    required
                    name="unit_id"
                    className="select2Select form-control"
                  ></select>
                </div>
                <div className="form-group">
                  <label htmlFor="maximumStock">Maksimum Stok</label>
                  <input
                    ref={this.maximum_stock}
                    name="maximum_stock"
                    type="number"
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="alert1">Uyarıda Gösterilsin mi?</label>
                  <input
                    ref={this.alert1}
                    name="alert1"
                    type="checkbox"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="qr_code">QR Kod</label>
                  <canvas
                    className="form-control mx-auto"
                    ref={this.qr_code}
                  ></canvas>
                </div>
                <div className="form-group">
                  <label htmlFor="company_id">{t("company_id")}</label>
                  <select
                    ref={this.company_id}
                    name="company_id"
                    type="number"
                    className="select2Select form-control"
                  ></select>
                </div>
                <div className="form-group">
                  <label htmlFor="subShelfNo">{t("subShelfNo")}</label>
                  <input
                    ref={this.sub_shelf_no}
                    name="sub_shelf_no"
                    type="text"
                    className="form-control"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="unit_id_2">{t("unit")} 2</label>
                  <input
                    ref={this.unit_id_2}
                    name="unit_id_2"
                    className="form-control"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="stock_brand">{t("brand_id")}</label>
                  <input
                    ref={this.stock_brand}
                    id="stock_brand"
                    name="stock_brand"
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="minimumStock">{t("minimumStock")}</label>
                  <input
                    defaultValue={"0"}
                    ref={this.minimum_stock}
                    name="minimum_stock"
                    type="number"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="storage_id">{t("storage_id")}</label>
                  <select
                    required
                    ref={this.storage_id}
                    name="storage_id"
                    className="select2Select form-control"
                    id="storage"
                  ></select>
                </div>
                <div className="form-group">
                  <label htmlFor="stockCode">{t("stockCode")}</label>
                  <input
                    ref={this.stock_code}
                    required
                    id="stock_code"
                    type="text"
                    className="form-control"
                    onChange={() => this.onGenerateQRCode()}
                    onClick={() => this.onGenerateQRCode()}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="vatRatio">{t("vatRatio")}</label>
                  <input
                    ref={this.vat_ratio}
                    required
                    name="vat_ratio"
                    type="number"
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="price">{t("price")}</label>
                  <input
                    ref={this.price}
                    required
                    name="price"
                    type="text"
                    className="form-control"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="maximumStock">Özellikler</label>
                  <input
                    ref={this.ozellikler}
                    name="ozellikler"
                    type="text"
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="quantity">{t("quantity")}</label>
                  <input
                    ref={this.quantity}
                    disabled
                    readOnly
                    name="quantity"
                    type="number"
                    className="form-control"
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="description">{t("description")}</label>
                  <textarea
                    ref={this.description}
                    name="description"
                    className="form-control"
                  />
                </div>
              </div>

              {this.state.stockDataImage.map(function (images, index) {
                return (
                  <div
                    key={index}
                    data-id={images.id}
                    className="col-md-4 uploadPicturesWrapper "
                  >
                    <div className="fileContainer">
                      <div
                        className="uploadPictureContainer"
                        style={{ width: "100%" }}
                      >
                        <a
                          onClick={(e) => __this.imageRemoves(e)}
                          id={images.id}
                          data-id={images.id}
                          className="deleteImage"
                        >
                          X
                        </a>
                        <button
                          type="button"
                          class="btn"
                          data-bs-toggle="modal"
                          data-bs-target={"#img-" + images.id}
                        >
                          <img
                            src={`${getUploadsImgUri()}` + images.url}
                            className="uploadPicture"
                            alt="preview"
                          />
                        </button>
                        <div
                          class="modal fade"
                          id={"img-" + images.id}
                          tabindex="-1"
                          aria-labelledby={"img-" + images.id + "-Label"}
                          aria-hidden="true"
                        >
                          <div class="modal-dialog">
                            <div class="modal-content">
                              <div class="modal-header">
                                <button
                                  type="button"
                                  class="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div class="modal-body">
                                <img
                                  src={`${getUploadsImgUri()}` + images.url}
                                  className="uploadPicture"
                                  alt="preview"
                                />
                              </div>
                              <div class="modal-footer">
                                <button
                                  type="button"
                                  class="btn btn-secondary"
                                  data-bs-dismiss="modal"
                                >
                                  Kapat
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

              <div className="col-md-12">
                <ImageUploader
                  withIcon={true}
                  buttonText="Lütfen Resim Seçiniz"
                  onChange={(file) => {
                    image = file;
                  }}
                  imgExtension={[".jpg", ".jpeg", ".png"]}
                  maxFileSize={5242880}
                  withPreview={true}
                  label="Max Dosyas Boyutu 5MB, Sadece JPG-JPEG-PNG Formatları Kabul Edilmektedir."
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(withTranslation(["stock-add", "translations"])(StockUpdate));
