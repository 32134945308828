import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { setBreadcrumbs } from '../../features/active-page/sub-pages-slice';
import { setPageHeader } from '../../features/active-page/page-header-slice';
import { setHeaderElements } from '../../features/active-page/header-elements-slice';
import { EventHandlerContext } from '../../contexts/event-handler-context';
import Functions from '../../class/functions';
import $ from "jquery";
import "select2";
import axios from 'axios';
import { getEndPoint } from "../../state/api/index"
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const ReactSwal = withReactContent(Swal);
Functions.importThemeStyles();

const mapStateToProps = state => ({
    redux: {
        pageHeader: state.pageHeader,
        breadcrumbs: state.breadcrumbs,
        headerElements: state.headerElements
    }
});
const mapDispatchToProps = () => ({
    setPageHeader,
    setBreadcrumbs,
    setHeaderElements
});

class TalepForm extends Component {
    static contextType = EventHandlerContext

    constructor(props) {
        super(props);
        this.stockRef = React.createRef();
        this.employeeRef = React.createRef();

        this.checkedStorage = React.createRef();
        this.transferFromRef = React.createRef();
        this.transferToRef = React.createRef();
        this.firmaRef = React.createRef();
        this.onChangeEvent = this.onChangeEvent.bind(this);
        this.stockList = [];
        this.stockData = [];
        this.stocks_editted_data = [];
        this.state = {
            stocks: [
                React.createRef(),
            ],
            stockList: []

        };
        this.createSelect = (ref, t, selectType) => {
            $(ref.current).select2({
                width: "100%",
                language: {
                    noResults: () => t("plugins.select2.noResults", { ns: "translations" }),
                    searching: () => t("plugins.select2.searching", { ns: "translations" }),
                    maximumSelected: () => t("plugins.select2.maximumSelected", { ns: "translations" }),
                    loadingMore: () => t("plugins.select2.loadingMore", { ns: "translations" }),
                    inputTooShort: () => t("plugins.select2.inputTooShort", { ns: "translations" }),
                    inputTooLong: () => t("plugins.select2.inputTooLong", { ns: "translations" }),
                    errorLoading: () => t("plugins.select2.errorLoading", { ns: "translations" }),
                }
            });
            Functions.createSelectList(ref, t, selectType);

        }
        var config = {
            method: 'get',
            url: `${getEndPoint()}/api/stocks/all`,
            headers: {
                'Content-Type': 'application/json',
            },
        };

        axios(config)
            .then(response => {
                this.stockList = response.data.data
                this.stockData = response.data.data
                this.createSelects()
            })

        this.createSelects = () => {
            const { t } = this.props;
            const _this = this;
            this.state.stocks.map((ref, index) => {

                if (ref === undefined) return;
                if (ref === null) return;

                $(ref.current).select2({
                    width: "100%",
                    language: {
                        noResults: () => t("plugins.select2.noResults", { ns: "translations" }),
                        searching: () => t("plugins.select2.searching", { ns: "translations" }),
                        maximumSelected: () => t("plugins.select2.maximumSelected", { ns: "translations" }),
                        loadingMore: () => t("plugins.select2.loadingMore", { ns: "translations" }),
                        inputTooShort: () => t("plugins.select2.inputTooShort", { ns: "translations" }),
                        inputTooLong: () => t("plugins.select2.inputTooLong", { ns: "translations" }),
                        errorLoading: () => t("plugins.select2.errorLoading", { ns: "translations" }),
                    }
                });

                if ($(ref.current).val() != "0") {
                    return;
                }
                var selects = $(ref.current);


                if ($(ref.current).val() !== 0) {
                    if ($(ref.current).val() != undefined) {
                        $(ref.current).empty().trigger("change");

                        var option = new Option(
                            'Lütfen Seçim Yapınız...',
                            0,
                            true,
                            true);
                        selects.append(option).trigger('change');
                        this.stockData.forEach((elm, index) => {

                            var option = new Option(
                                elm.stock_code + ' - ' + elm.stock_name,
                                elm.id,
                                false,
                                false);
                            selects.append(option).trigger('change');
                        });
                    }

                }

            });
        };
        this.storageCountingListTable = null;
        this.storageCountingListTableRef = React.createRef();
        this.selectAllRef = React.createRef();
    }

    componentDidMount() {
        const { t } = this.props;
        let _this = this;

        this.createSelect(this.transferFromRef, t);
        this.createSelect(this.transferToRef, t, 'storages');
        this.props.setPageHeader("Otomatik Talep Oluşturma");
        this.props.setHeaderElements([
            {
                componentId: "add-stock",
                href: "#", translation: { key: "misc.add", opts: { ns: "translations" } },
                className: "btn btn-link btn-float text-body", icon: "fas fa-plus text-primary",
            },
            {
                componentId: "save-counting",
                href: "#", translation: { key: "Excele Kaydet", opts: { ns: "translations" } },
                className: "btn btn-link btn-float text-body", icon: "fas fa-save text-primary",
            }
        ]);
        this.context.addEventHandler("save-counting", (ev) => this.onSaveStockButtonClick(ev));

        this.context.addEventHandler("add-stock", (ev) => this.onAddStockButtonClick(ev));
        this.createSelect(this.employeeRef, t, "employee");
        this.createSelect(this.firmaRef, t, 'personel');
        this.onChangeEvent();


    }

    onChangeEvent(e) {
        const _this = this;
        $('.stockSelect').on('select2:select', function (e) {
            var data = e.params.data;
            var dataList = _this.stockList;
            dataList.forEach((elm, index) => {
                if (parseInt(data.id) == elm.id) {
                    var malzeme = $('.malzemeClass')[$(this).data('id')].value = elm.ozellikler;
                    var barcode = $('.urunClass')[$(this).data('id')].value = elm.stock_name;
                    var marka = $('.markaClass')[$(this).data('id')].value = elm.stock_brand;
                }
            })


        });
    }
    onSaveStockButtonClick(ev) {
        const { t } = this.props;
        const _this = this;
        var dataList = this.stockList;
        var stockLists = [];
        var stockListsMiktar = [];
        dataList.forEach((elm, index) => {
            this.state.stocks.map((data, index) => {
                if (parseInt(data.current.value) == elm.id) {
                    elm.adet = $('.AdetClass')[$(data.current).data('id')].value
                    // elm.malzeme = $('.malzemeClass')[$(data.current).data('id')].value
                    // elm.barcode = $('.urunClass')[$(data.current).data('id')].value
                    // elm.marka = $('.markaClass')[$(data.current).data('id')].value
                    stockListsMiktar.push(elm.adet);

                    stockLists.push(elm.id);
                }
            })

        });

        var config = {
            method: 'get',
            url: `${getEndPoint()}/excel?id=${String(stockLists)}&miktar=1${String(stockListsMiktar)}`,
            headers: {
                'Content-Type': 'application/json',
            },
        };
        if (stockLists.length != 0) {
            window.open(`${getEndPoint()}/excel?id=${String(stockLists)}&miktar=${String(stockListsMiktar)}`);

        }
        else {
            ReactSwal.fire('Hata', 'Stok Seçmediniz', 'error')
        }
    }

    onAddStockButtonClick(ev) {


        this.state.stocks.push(React.createRef());
        this.setState({
            stocks: this.state.stocks
        });
    }
    componentDidUpdate() {
        this.createSelects();
        this.onChangeEvent();

    }
    componentWillUnmount() {
        this.props.setPageHeader("");
        this.props.setHeaderElements([]);
        this.context.handlers = [];
    }

    onRemoveStockButtonClick(ev, ref, index) {
        this.state.stocks.splice(index, 1);
        this.setState({
            stocks: this.state.stocks
        });
    }


    render() {
        const { t } = this.props;

        return (
            <>
                <div className="card p-1 m-0">
                    <div className="card-body p-1 m-0">


                        {this.state.stocks && this.state.stocks.map((element, index) => {
                            return (
                                <div key={index} className='row'>

                                    <div className="col-md-2">

                                        <div key={`stocks-${index}`} className="form-group">
                                            {index !== 0 && <a href="#" onClick={(ev) => this.onRemoveStockButtonClick(ev, element, index)}>
                                                <span className="mr-2 text-danger"><i className="fas fa-window-close"></i></span>
                                            </a>}
                                            <label htmlFor="stock">{t("stock")} {index + 1}</label>
                                            <select ref={element} id={"stock" + index} name="stock" data-id={index} data-placeholder={t("placeholders.selectStock")} className="form-control stockSelect select" data-fouc>
                                                <option value="0">Lütfen Bekleyiniz...</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <label htmlFor="urun">Ürün Adı </label>

                                        <input className='form-control urunClass' disabled name='urun' type="text" />
                                    </div>
                                    <div className='col-md-3'>
                                        <label htmlFor="malzeme">Marka </label>

                                        <input className='form-control markaClass' disabled name='marka' type="text" />
                                    </div>
                                    <div className='col-md-3'>
                                        <label htmlFor="malzeme">Malzeme </label>

                                        <input className='form-control malzemeClass' disabled name='malzeme' type="text" />
                                    </div>
                                    <div className='col-md-1'>
                                        <label htmlFor="adet">Adedi </label>

                                        <input defaultValue="0" className='form-control AdetClass' name='adet' type="text" />
                                    </div>
                                </div>

                            );
                        })}

                    </div>
                </div>
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps())(
    withTranslation(["debit-add", "translations"])(TalepForm)
);