import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { setBreadcrumbs } from '../../features/active-page/sub-pages-slice';
import { setPageHeader } from '../../features/active-page/page-header-slice';
import { setHeaderElements } from '../../features/active-page/header-elements-slice';
import { EventHandlerContext } from '../../contexts/event-handler-context';
import $ from "jquery";
import "datatables.net";
import "datatables.net-responsive";
import "datatables.net-buttons-dt";
import "datatables.net-select";
import "jszip";
import "pdfmake";
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.print';
import 'datatables.net-buttons/js/buttons.colVis';
import 'datatables.net-buttons/js/buttons.flash';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Functions from '../../class/functions';
import axios from 'axios';
import { getEndPoint } from "../../state/api/index"
import 'bootstrap/dist/js/bootstrap';

Functions.importThemeStyles();
const ReactSwal = withReactContent(Swal);

const mapStateToProps = state => ({
    redux: {
        pageHeader: state.pageHeader,
        breadcrumbs: state.breadcrumbs,
        headerElements: state.headerElements
    }
});
const mapDispatchToProps = () => ({
    setPageHeader,
    setBreadcrumbs,
    setHeaderElements
});

class Unit extends Component {
    static contextType = EventHandlerContext

    constructor(props) {
        super(props);

        this.state = {
            rowsToDelete: "",
            selectDeleteItemRows: 0
        };
        this.columnSelect = React.createRef();

        this.stockListTable = null;
        this.stockListTableRef = React.createRef();
        this.selectAllRef = React.createRef();
    }

    componentDidMount() {
        const { t } = this.props;
        let _this = this;

        // this.props.setBreadcrumbs([
        //     { href: "/", className: "", value: "Test 1" },
        //     { href: "/", className: "", value: "Test 2" },
        //     { href: "/", className: "", value: "Test 3" },
        //     { href: "/", className: "", value: "Test 4" }
        // ]);
        this.props.setPageHeader('Birim');
        // this.props.setHeaderElements([
        //     {
        //         componentId: "unit-add",
        //         href: "#", translation: { key: "misc.new_Unit", opts: { ns: "translations" } },
        //         className: "btn btn-link btn-float text-body ", icon: "fas fa-plus text-primary",
        //     }
        // ]);
        // this.context.addEventHandler("unit-add", (ev) => this.onAddClick(ev));

        $('.select').select2({
            width: '100%'
        });
        if (this.stockListTable != undefined)
            this.stockListTable.destroy();
        this.stockListTable = $(".datatable-pagination").DataTable({
            autoWidth: false,
            responsive: true,
            dom: 'Blfrtip',
            buttons: [
                'copyHtml5',
                'excelHtml5',
                'csvHtml5',
                'pdfHtml5'
            ],
            processing: true, searching: false,
            serverSide: true,
            lengthMenu: [[50, 100, 150, -1], [50, 100, 150, "Hepsi"]],
            ajax: {
                url: `${getEndPoint()}/api/units/all`,
                type: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                xhrFields: {
                    withCredentials: true
                },
                crossDomain: true,
                dataFilter: function (data) {
                    var json = $.parseJSON(data);
                    json.data = json.data;
                    json.recordsFiltered = json.result.recordsTotal
                    json.recordsTotal = json.result.recordsTotal
                    return JSON.stringify(json);
                },
            },
            fnServerParams: (aoData) => {
                aoData["filters"] = [{ expression: "LIKE", column: $('.select').val(), value: '%' + String(aoData.search.value).toLocaleUpperCase('TR') + '%' },
                { "expression": "order", "column": aoData.columns[aoData.order[0].column].data, "value": aoData.order[0].dir }];
                aoData["page"] = aoData.start / aoData.length;
                aoData["per_page"] = aoData.length;
            },
            "columns": [
                { data: "id", "orderable": "true" },
                { data: "name", "orderable": "false" },

            ],

            // columnDefs: [
            //     {
            //         data: '',
            //         defaultContent: '',
            //         orderable: false,
            //         className: 'select-checkbox',
            //         targets: 1,
            //     }

            // ],
            order: [[0, 'asc']],
            language: {
                ...(t("plugins.dataTables", { ns: "translations" })),
                paginate: {
                    'next': $('html').attr('dir') === 'rtl' ? t("plugins.dataTables.paginate.next", { ns: "translations" }) + '&larr;' : t("plugins.dataTables.paginate.next", { ns: "translations" }) + ' &rarr;',
                    'previous': $('html').attr('dir') === 'rtl' ? '&rarr; ' + t("plugins.dataTables.paginate.previous", { ns: "translations" }) : '&larr; ' + t("plugins.dataTables.paginate.previous", { ns: "translations" })
                }
            },
            lengthMenu: [[50, 100, 150, -1], [50, 100, 150, "Hepsi"]]


        });

        // this.stockListTable.on('select', (e, dt, type, indexes) => this.onStockListTableSelect(e, dt, type, indexes));
        // this.stockListTable.on('deselect', (e, dt, type, indexes) => this.onStockListTableDeselect(e, dt, type, indexes));

        $('.close').on('click', function () {
            window.$('#Modals').hide();
        })

    }

    componentWillUnmount() {
        this.props.setPageHeader("");
        this.props.setHeaderElements([]);
        this.context.handlers = [];
    }

    // onDeleteStockButtonClick(ev) {
    //     const { t } = this.props;
    //     const _this=this;
    //     if (this.state.selectDeleteItemRows === 0) {
    //         ReactSwal.fire({
    //             title: <span>{t("operations.deleteMultiple", { ns: "translations", itemCount: 1 })}</span>,
    //             html: ``,
    //             icon: 'error',
    //             showCloseButton: true,
    //             confirmButtonText: t("misc.ok", { ns: "translations" }),
    //         });
    //         return;
    //     }

    //     ReactSwal.fire({
    //         title: <span>{t("operations.wouldYouLikeToDeleteRecords", { ns: "translations" })}</span>,
    //         html: <span>{t("operations.itemsToDelete", { ns: "translations", itemCount: this.state.rowsToDelete })}</span>,
    //         icon: '',
    //         showCloseButton: true,
    //         showCancelButton: true,
    //         focusConfirm: false,
    //         confirmButtonText: t("misc.yes", { ns: "translations" }),
    //         cancelButtonText: t("misc.cancel", { ns: "translations" }),
    //     }).then((a) => {
    //         if (a.isConfirmed) {

    //             var data = JSON.stringify({
    //                 "ids": [
    //                     _this.state.rowsToDelete
    //                 ]
    //             });

    //             var config = {
    //                 method: 'post',
    //                 url: 'http://stockv9x.ferofen.com/api/stocks/delete',
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                 },
    // xhrFields: {
    //     withCredentials: true
    // },
    // crossDomain: true,
    //                 data: data
    //             };
    //             console.log(data);
    //             axios(config)
    //                 .then(function (response) {
    //                     ReactSwal.fire({
    //                         title: <span>{t("misc.successfull", { ns: "translations" })}</span>,
    //                         html: <span>{t("operations.itemsDeleted", { ns: "translations", itemCount: this.state.rowsToDelete })}</span>,
    //                         icon: '',
    //                         showCloseButton: true,
    //                         focusConfirm: false,
    //                         confirmButtonText: t("misc.ok", { ns: "translations" }),
    //                     })
    //                     _this.componentDidMount()
    //                 })
    //         }
    //     });
    // }

    // onSelectAllButtonClick(ev) {
    //     $(ev.target).parent().find("#selectAll").prop("checked", (index, oldPropertyValue) => {
    //         $(this.selectAllRef.current).prop("checked", !oldPropertyValue);
    //     });

    //     if ($(ev.target).parent().find("#selectAll").is(':checked')) {
    //         this.stockListTable.rows().select();
    //         this.setState({
    //             rowsToDelete: this.stockListTable.rows({ selected: true }).count()
    //         });
    //     } else {
    //         this.stockListTable.rows().deselect();
    //         this.setState({
    //             rowsToDelete: 0
    //         });
    //     }
    // }

    // onStockListTableSelect(e, dt, type, indexes) {
    //     var stateData = this.state.rowsToDelete
    //     if (stateData !== "") {
    //         stateData = String(stateData + ',')
    //     }
    //     this.setState({
    //         rowsToDelete: String(stateData + dt.data().id),
    //         selectDeleteItemRows: this.storageListTable.rows({ selected: true }).count()
    //     });

    //     if (this.stockListTable.rows({ selected: true }).count() === this.stockListTable.rows().count())
    //         $(this.selectAllRef.current).prop("checked", true);
    // }

    // onStockListTableDeselect(e, dt, type, indexes) {
    //     this.setState({
    //         rowsToDelete: this.stockListTable.rows({ selected: true }).count()
    //     });

    //     if (this.stockListTable.rows({ selected: true }).count() !== this.stockListTable.rows().count())
    //         $(this.selectAllRef.current).prop("checked", false);
    // }
    addClickHandler() {
        const _this = this;
        var name = $('#name').val();
        var data = {
            "name": $('#name').val()
        }
        var config = {
            method: 'post',
            url: `${getEndPoint()}/api/units/add`,
            headers: {
                // 'Content-Type': 'application/json',
                'Content-Type': 'application/json',
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                window.$('#Modals').hide();
                Swal.fire('Başarılı', 'İşlem Başarılı Bir Şekilde Gerçekleştirildi', 'success')
                _this.componentDidMount();
            })
    }
    showModal() {
        window.$('#Modals').show();

    }
    render() {
        const { t } = this.props;

        return (

            <div className='card'>

                <button onClick={this.showModal} className='btn btn-success'>
                    Ekle
                </button>

                <div className='card-body mt-4'>
                    <div className='w-100'>
                        <table ref={this.stockListTableRef} className="table table-striped table-hover dt-responsive nowrap datatable-pagination w-100">
                            <thead className='w-100'>

                                <tr>
                                    <th>ID</th>

                                    <th>İsim</th>

                                </tr>
                            </thead>
                            <tbody className='w-100'>

                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="modal " id="Modals" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Birim Ekle</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label htmlFor="name">Birim İsmi</label>
                                    <input id='name' name="name" className='form-control' />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button id='unitAdd' onClick={this.addClickHandler} type="button" className="btn btn-primary">Kaydet</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps())(
    withTranslation(["stock-list", "translations"])(Unit)
);