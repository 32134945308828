import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { setBreadcrumbs } from '../../features/active-page/sub-pages-slice';
import { setPageHeader } from '../../features/active-page/page-header-slice';
import { setHeaderElements } from '../../features/active-page/header-elements-slice';
import { EventHandlerContext } from '../../contexts/event-handler-context';
import $, { data } from "jquery";

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Functions from '../../class/functions';
import axios from 'axios';
import 'select2';
import { DateRangePickerHandler } from '../../js/plugins/pickers/daterangepicker.js'
import { getEndPoint, uploadsFaturaUri } from "../../state/api/index"
import { ReactSession } from 'react-client-session';
Functions.importThemeStyles();
let cikisIndex = 0;
const ReactSwal = withReactContent(Swal);
var donguCount = 0;
var faturaUri = null;
const mapStateToProps = state => ({
    redux: {
        pageHeader: state.pageHeader,
        breadcrumbs: state.breadcrumbs,
        headerElements: state.headerElements
    }
});
const mapDispatchToProps = () => ({
    setPageHeader,
    setBreadcrumbs,
    setHeaderElements
});

class StockOutV2 extends Component {
    static contextType = EventHandlerContext

    constructor(props) {
        super(props);
        const _this = this;
        this.stockRef = React.createRef();
        this.employeeRef = React.createRef();
        var stockDataConst;
        this.checkedStorage = React.createRef();
        this.transferFromRef = React.createRef();
        this.transferToRef = React.createRef();
        this.islemNoRef = React.createRef();
        this.personelRef = React.createRef();
        this.onChangeEvent = this.onChangeEvent.bind(this);
        this.stockList = [];
        this.stocks_editted_data = [];
        if (ReactSession.get('entryData') == undefined) {
            stockDataConst = [React.createRef(),]
        } else {
            stockDataConst = ReactSession.get('entryData');
        }
        this.state = {
            stocks: stockDataConst,
            stockList: [],
            cikisList: [],
            cikisStockList: [],
            donguCount: 0,
            selectedFile: null

        };

        this.createSelect = (ref, t, selectType) => {
            $(ref.current).select2({
                width: "100%",
                language: {
                    noResults: () => t("plugins.select2.noResults", { ns: "translations" }),
                    searching: () => t("plugins.select2.searching", { ns: "translations" }),
                    maximumSelected: () => t("plugins.select2.maximumSelected", { ns: "translations" }),
                    loadingMore: () => t("plugins.select2.loadingMore", { ns: "translations" }),
                    inputTooShort: () => t("plugins.select2.inputTooShort", { ns: "translations" }),
                    inputTooLong: () => t("plugins.select2.inputTooLong", { ns: "translations" }),
                    errorLoading: () => t("plugins.select2.errorLoading", { ns: "translations" }),
                }
            });
            Functions.createSelectList(ref, t, selectType);

        }

        this.createSelects = () => {
            const { t } = this.props;
            const _this = this;
            this.state.stocks.map((ref, index) => {
                if (ref === undefined || ref === null) return;
                $(ref.current).select2({
                    width: "100%",
                    language: {
                        noResults: () => t("plugins.select2.noResults", { ns: "translations" }),
                        searching: () => t("plugins.select2.searching", { ns: "translations" }),
                        maximumSelected: () => t("plugins.select2.maximumSelected", { ns: "translations" }),
                        loadingMore: () => t("plugins.select2.loadingMore", { ns: "translations" }),
                        inputTooShort: () => t("plugins.select2.inputTooShort", { ns: "translations" }),
                        inputTooLong: () => t("plugins.select2.inputTooLong", { ns: "translations" }),
                        errorLoading: () => t("plugins.select2.errorLoading", { ns: "translations" }),
                    }
                });


                if ($(ref.current).val() != "0") {
                    return;
                }
                if ($(ref.current).val() !== 0) {
                    if ($(ref.current).val() != undefined) {
                        $(ref.current).empty().trigger("change");

                        var selects = $(ref.current);
                        var option = new Option(
                            'Lütfen Bir Seçim Yapınız...',
                            0,
                            true,
                            true);
                        selects.append(option).trigger('change');
                        this.state.stockList.forEach((elm, index) => {
                            var nameSelect = elm.qr_code != null ? elm.qr_code : "QR Bulunamadı"
                            nameSelect = nameSelect == "" ? "QR Bulunamadı" : nameSelect


                            var option = new Option(

                                elm.stock_name + ' ( ' + nameSelect + ' ) ',
                                elm.id,
                                false,
                                false);
                            selects.append(option).trigger('change');

                        });
                    }

                }
            });
        };
        this.storageCountingListTable = null;
        this.storageCountingListTableRef = React.createRef();
        this.selectAllRef = React.createRef();
        this.cikisListesi = this.cikisListesi.bind(this);
        this.getStockList = this.getStockList.bind(this);
        this.changeSelectTaslak = this.changeSelectTaslak.bind(this);
        this.faturaAc = this.faturaAc.bind(this);


    }
    componentDidMount() {
        const { t } = this.props;
        let _this = this;
        $('#faturaButton').hide()
        this.cikisListesi()
        this.createSelect(this.transferFromRef, t);
        this.createSelect(this.transferToRef, t, 'storages');
        this.getStockList()
        DateRangePickerHandler($('#date'), {
            parentEl: '.content-inner',
            showDropdowns: true,
            singleDatePicker: true,
            locale: t("dateRangePickerLocalization", { ns: "translations" })
        });
        this.props.setPageHeader("Stok Çıkışı");
        this.props.setHeaderElements([
            {
                componentId: "onceki",
                href: "#", translation: { key: "Önceki" },
                className: "btn btn-link btn-float text-body", icon: "fas fa-angle-left text-primary",
            },
            {
                componentId: "son_kayit",
                href: "#", translation: { key: "Son Kaydı Getir" },
                className: "btn btn-link btn-float text-body", icon: "fas fa-search text-primary",
            },
            {
                componentId: "sonraki",
                href: "#", translation: { key: "Sonraki" },
                className: "btn btn-link btn-float text-body", icon: "fas fa-angle-right text-primary",
            },

            {
                componentId: "add-stock",
                href: "#", translation: { key: "misc.add", opts: { ns: "translations" } },
                className: "btn btn-link btn-float text-body", icon: "fas fa-plus text-primary",
            },
            {
                componentId: "save-counting",
                href: "#", translation: { key: "misc.save", opts: { ns: "translations" } },
                className: "btn btn-link btn-float text-body", icon: "fas fa-save text-primary",
            }
        ]);
        this.context.addEventHandler("save-counting", (ev) => this.onSaveStockButtonClick(ev));

        this.context.addEventHandler("add-stock", (ev) => this.onAddStockButtonClick(ev));
        this.context.addEventHandler("onceki", (ev) => this.onceki(ev));
        this.context.addEventHandler("sonraki", (ev) => this.sonraki(ev));
        this.context.addEventHandler("son_kayit", (ev) => this.son_kayit(ev));

        this.createSelect(this.personelRef, t, 'personel')

        this.onChangeEvent();
        $('#searchIslemNo').select2()



        $('.vergiClass').on('change', function (e) {
            _this.toplamHesapla()
        });


        $('.priceClass').on('change', function (e) {
            _this.toplamHesapla()
        });

        $('.miktarClass').on('change', function (e) {
            _this.toplamHesapla()
        });


    }
    getStockList() {
        const _this = this;
        var config = {
            method: 'get',
            url: `${getEndPoint()}/api/stocks/all`,
            headers: {
                'Content-Type': 'application/json',
            },
        };
        axios(config)
            .then(function (response) {
                _this.setState({
                    stockList: response.data.data
                })
                _this.stockList = response.data.data
            })
    }

    cikisListesi() {
        const _this = this;
        var config = {
            method: 'put',
            url: `${getEndPoint()}/api/stocks/gc-taslak-list`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: JSON.stringify({
                "gc": "0",
                "IslemTipi": $('.cikisTipi').val()
            })

        };

        axios(config)
            .then(function (response) {
                var lastItem = response.data.data[response.data.data.length - 1]
                if (lastItem == undefined)
                    return
                _this.islemNoRef.current.value = lastItem.IslemNo
                _this.setState({
                    cikisList: response.data.data
                })
                cikisIndex = response.data.data.length - 1

                if (ReactSession.get('IslemNo') != undefined) {
                    $('.IslemNo').val(ReactSession.get('IslemNo')).trigger("change");
                    _this.cikisData(ReactSession.get('IslemNo'))
                    ReactSession.remove('IslemNo')
                }
                else {
                    var newIslemNo = lastItem.IslemNo.split('-')
                    newIslemNo[1].slice(0, -1)
                    var str = "" + parseInt(newIslemNo[1])
                    const ans = str.padStart(4, '0');
                    var newNumber = parseInt(ans) + 1
                    var newItem = String(newNumber)
                    $('.islemNo').val(newIslemNo[0] + '-' + newItem.padStart(5, "0")).trigger("change");

                }



                var selectIslemNo = $('#searchIslemNo')
                $(selectIslemNo).empty()
                var option = new Option(
                    "Aramak İstediğiniz İşlem No'yu Seçiniz",
                    "0",
                    true,
                    true);
                selectIslemNo.append(option).trigger('change');
                response.data.data.forEach((elm, index) => {
                    var option = new Option(
                        elm.IslemNo,
                        elm.IslemNo,
                        false,
                        false);
                    selectIslemNo.append(option).trigger('change');

                });



            })
    }
    cikisData(val) {
        const _this = this;
        var data = JSON.stringify({
            "gc": "0",
            "filters": [
                {
                    "expression": "=",
                    "column": "IslemNo",
                    "value": val
                }
            ]
        });
        var config = {
            method: 'put',
            url: `${getEndPoint()}/api/stocks/gc-list`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: data

        };
        var refCreateList = [];
        axios(config)
            .then(function (response) {
                _this.setState({
                    cikisStockList: response.data.data
                });
                response.data.data.forEach((element, index) => {
                    refCreateList.push(React.createRef());
                });

                _this.onAddStockButtonClick(refCreateList);
                _this.createSelects()
                _this.changeSelectTaslak()
            })
    }
    changeSelectTaslak() {
        const _this = this;

        var inputData = this.state.cikisStockList[0];
        faturaUri = inputData.fatura_url;
        if (inputData.fatura_url == null) {
            $('#faturaButton').hide();
        }
        else if (inputData.fatura_url == "") {
            $('#faturaButton').hide();
        }
        else {
            $('#faturaButton').show();

        }
        $('.islemNo').val(inputData.IslemNo)
        $('.depoSelect').val(inputData.depo_id).trigger('change');
        $('.cikisTipi').val(inputData.IslemTipi)
        $('.Personel').val(inputData.personelKodu).trigger('change');

        $('#date').val(getFormatDBDate(inputData.created_at))
        this.state.stocks.map((ref, index) => {
            if (_this.state.cikisStockList[index] == undefined)
                return null;
            var stockData = _this.stockList.filter(x => x.stock_code == _this.state.cikisStockList[index].StokKodu)
            if (stockData.length == 0)
                return null;

            $(ref.current).val(stockData[0].id).trigger('change');
            var miktar = $('.miktarClass')[$(ref.current).data('id')].value = _this.state.cikisStockList[index].Miktar;
            var stock = $('.stockClass')[$(ref.current).data('id')].value = _this.state.cikisStockList[index].StokKodu;
            var barcode = $('.barcodeClass')[$(ref.current).data('id')].value = _this.state.cikisStockList[index].BarkodNo;
            var price = $('.priceClass')[$(ref.current).data('id')].value = _this.state.cikisStockList[index].price;
            var vergi = $('.vergiClass')[$(ref.current).data('id')].value = _this.state.cikisStockList[index].KdvOran;
            var rafNo = $('.rafNoClass')[$(ref.current).data('id')].value = stockData[0].shelf_no;
            var subRafNo = $('.subRafNoClass')[$(ref.current).data('id')].value = stockData[0].sub_shelf_no;
        })
        _this.toplamHesapla()

    }
    son_kayit(e) {
        const _this = this;
        var val = this.state.cikisList[this.state.cikisList.length - 1]
        cikisIndex = this.state.cikisList.length - 1;
        this.cikisData(val)
    }
    onceki(e) {
        const _this = this;
        if (cikisIndex <= 0)
            return null;
        cikisIndex--;
        var val = this.state.cikisList[cikisIndex]

        this.cikisData(val)
    }

    sonraki(e) {
        const _this = this;

        if (cikisIndex == this.state.cikisList.length - 1)
            return null;
        cikisIndex = cikisIndex + 1;
        var val = this.state.cikisList[cikisIndex]

        this.cikisData(val)

    }


    onChangeEvent(e) {
        const _this = this;
        $('.stockSelect').on('select2:select', function (e) {
            var data = e.params.data;
            var dataList = _this.stockList;
            dataList.forEach((elm, index) => {
                if (parseInt(data.id) == elm.id) {

                    var miktar = $('.miktarClass')[$(this).data('id')].value = elm.get_hareket_stock_list_giris_sum_miktar - elm.get_hareket_stock_list_cikis_sum_miktar;
                    var stock = $('.stockClass')[$(this).data('id')].value = elm.stock_code;
                    var barcode = $('.barcodeClass')[$(this).data('id')].value = elm.barcode_number;
                    var price = $('.priceClass')[$(this).data('id')].value = elm.price;
                    var vergi = $('.vergiClass')[$(this).data('id')].value = elm.vat_ratio;
                    var rafNo = $('.rafNoClass')[$(this).data('id')].value = elm.shelf_no;
                    var subRafNo = $('.subRafNoClass')[$(this).data('id')].value = elm.sub_shelf_no;
                }
            })
            _this.toplamHesapla()


        });
    }
    onSaveStockButtonClick(ev) {
        const { t } = this.props;
        const _this = this;
        var dataList = this.stockList;
        var stockLists = [];
        const formData = new FormData();
        if ($(_this.personelRef.current).select2('data')[0].id == "0") {
            ReactSwal.fire({
                title: <span>Hata!</span>,
                html: <span>Personel Seçmediniz.</span>,
                icon: 'error',
                showCloseButton: true,
                focusConfirm: false,
                confirmButtonText: t("misc.ok", { ns: "translations" }),
            })
            return
        }
        if ($('#date').val() == "") {
            ReactSwal.fire({
                title: <span>Hata!</span>,
                html: <span>Tarih Girmediniz.</span>,
                icon: 'error',
                showCloseButton: true,
                focusConfirm: false,
                confirmButtonText: t("misc.ok", { ns: "translations" }),
            })
            return
        }
        // Update the formData object 
        if (this.state.selectedFile != null) {

            formData.append(
                "file",
                this.state.selectedFile,
                this.state.selectedFile.name
            );
        }
        dataList.forEach((elm, index) => {
            this.state.stocks.map((data, index) => {
                if (parseInt(data.current.value) == elm.id) {
                    elm.miktar = parseInt($('.miktarClass')[$(data.current).data('id')].value)
                    elm.stock_code = $('.stockClass')[$(data.current).data('id')].value
                    elm.barcode_number = $('.barcodeClass')[$(data.current).data('id')].value
                    elm.price = parseFloat($('.priceClass')[$(data.current).data('id')].value).toFixed(2)
                    elm.vat_ratio = parseInt($('.vergiClass')[$(data.current).data('id')].value)
                    elm.shelf_no = $('.rafNoClass')[$(data.current).data('id')].value
                    elm.sub_shelf_no = $('.subRafNoClass')[$(data.current).data('id')].value
                    elm.personelKodu = $('.Personel').select2('data')[0].id
                    elm.get_hareket_stock_list_cikis=null;
                    stockLists.push(elm);
                }
            })
        });
        formData.append('GC', 0)
        formData.append('IslemTipi', $('.cikisTipi').val())
        formData.append('IslemNo', $('.islemNo').val())
        formData.append('date', Functions.getFormatDBDateHours($('#date').val()))
        formData.append('personelKodu', $(_this.personelRef.current).select2('data')[0].text)
        formData.append('gcData', JSON.stringify(stockLists))
        var config = {
            method: 'post',
            url: `${getEndPoint()}/api/stocks/gc-add`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: formData
        };
        axios(config)
            .then(function (response) {
                ReactSwal.fire({
                    title: <span>{t("misc.successfull", { ns: "translations" })}</span>,
                    html: <span>{t("operations.successMessage", { ns: "translations" })}</span>,
                    icon: '',
                    showCloseButton: true,
                    focusConfirm: false,
                    confirmButtonText: t("misc.ok", { ns: "translations" }),
                })
                _this.setState({
                    stocks: [React.createRef()]
                })
                _this.componentDidMount();
            }).catch(function (response) {
                ReactSwal.fire({
                    title: <span>Hata!</span>,
                    html: <span>İşlem Sırasında Bir Hata Oluştu. Lütfen Parametreleri Kontrol Edip Tekrar Deneyiniz.</span>,
                    icon: '',
                    showCloseButton: true,
                    focusConfirm: false,
                    confirmButtonText: t("misc.ok", { ns: "translations" }),
                })
            })

    }
    onAddStockButtonClick(ev) {
        if (Array.isArray(ev)) {

            this.setState({
                stocks: ev
            });
        }
        else {
            this.state.stocks.push(React.createRef());
            this.setState({
                stocks: this.state.stocks
            });
        }
    }
    componentDidUpdate() {
        this.createSelects();
        const _this = this;

        this.onChangeEvent();

    }
    componentWillUnmount() {
        this.props.setPageHeader("");
        this.props.setHeaderElements([]);
        this.context.handlers = [];
    }

    onRemoveStockButtonClick(ev, ref, index) {
        this.state.stocks.splice(index, 1);
        this.setState({
            stocks: this.state.stocks
        });
    }


    onFileChange = event => {
        this.setState({ selectedFile: event.target.files[0] });
    };
    faturaAc() {
        if (faturaUri == null)
            return null;
        var url = uploadsFaturaUri();
        window.open(url + faturaUri, '_blank');

    }
    searchIslemNoReq = () => {
        var data = $('#searchIslemNo').val()
        $('.IslemNo').val(data).trigger("change");
        this.cikisData(data)
    }
    toplamHesapla = () => {

        var genelMiktar = 0.00;
        var netFiyatToplam = 0.00;
        var vergi = 0.00;
        var genelFiyatToplam = 0.00;

        var dataList = this.stockList;
        this.state.stocks.map((data, index) => {

            dataList.forEach((elm, index) => {
                if (parseInt(data.current.value) == elm.id) {

                    var vergiHsp = $('.vergiClass')[$(data.current).data('id')].value
                    var miktarHsp = parseFloat($('.miktarClass')[$(data.current).data('id')].value)
                    var fiyatHsp = parseFloat($('.priceClass')[$(data.current).data('id')].value)
                    genelMiktar += miktarHsp;

                    var genel_toplam = miktarHsp * fiyatHsp
                    netFiyatToplam += fiyatHsp * miktarHsp;
                    var kdv = Number(vergiHsp);
                    vergi += genel_toplam * kdv / 100
                    genelFiyatToplam += genel_toplam + (genel_toplam * kdv / 100);



                }
            })
        });

        $('#genelMiktar').val('Genel Miktar: ' + genelMiktar);
        $('#netFiyatToplam').val('Net Toplam: ' + netFiyatToplam);
        $('#kdvToplam').val('KDV Toplam: ' + vergi);
        $('#genelFiyatToplam').val('Genel Fiyat Toplam: ' + genelFiyatToplam);

    }
    changePrice = () => {
        this.toplamHesapla()
    }
    changeMiktar = () => {
        this.toplamHesapla()
    }
    changeVergi = () => {
        this.toplamHesapla()
    }
    render() {
        const { t } = this.props;

        return (
            <>
                <div className="card ">
                    <div className="card-body ">

                        <div className='row'>
                            <div className='col-12'>

                                <label>Ara</label>
                            </div>

                            <div className='col-10'>
                                <select className='form-control' id='searchIslemNo' >
                                    <option>Yükleniyor...</option>
                                </select>

                            </div>
                            <div className='col-2'>

                                <button onClick={this.searchIslemNoReq} className='btn btn-warning'>
                                    Getir
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card p-1 m-0">

                    <div className="card-body p-1 m-0">

                        <div className='row'>
                            {/* SG-00018 */}
                            <div className="col-md-2 col-sm-2">
                                <div className="form-group">
                                    <label htmlFor="transferTo">İşlem No</label>
                                    <input ref={this.islemNoRef} name="transferTo" data-placeholder="İşlem No" defaultValue="SG-00001" className="form-control islemNo select selectTo" />
                                </div>
                            </div>
                            <div className="col-md-2 col-sm-2">
                                <div className="form-group">
                                    <label htmlFor="transferTo">Depo</label>

                                    <select ref={this.transferToRef} name="transferTo" data-placeholder={t("placeholders.transferTo")} className="form-control depoSelect select selectTo" data-fouc>
                                        <option value="0">Yükleniyor...</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-3">
                                <div className="form-group">
                                    <label htmlFor="cikisTipi">Çıkış Tipi</label>

                                    <select onChange={this.cikisListesi} data-placeholder="Çıkış Tipi" name='cikisTipi' className="form-control cikisTipi" >
                                        <option value="11">Serbest Stok Çıkışı</option>
                                        <option value="2">İrsaliyeli Çıkış</option>
                                        <option value="3">Satış İade İrsaliyesi</option>
                                        <option value="4">Üretime Çıkış </option>
                                        <option value="5">Konsinye Alım İrsaliyesi</option>
                                        <option value="6">Depo Sayım Fişi </option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-2 col-sm-2">
                                <div className="form-group">
                                    <label htmlFor="Personel">Personel</label>

                                    <select ref={this.personelRef} data-placeholder="Personel" name='Personel' className="form-control Personel" >
                                        <option value="0">Yükleniyor...</option>
                                    </select>
                                </div>
                            </div>

                            <div className="col-md-3 col-sm-3">
                                <div className="form-group">
                                    <label htmlFor="tarih">Tarih</label>

                                    <input id="date" className='form-control' />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-9'>
                                <input type="file" onChange={this.onFileChange} />
                            </div>

                            <div className='col-3'>
                                <a id='faturaButton' onClick={this.faturaAc} href="#">Faturayı Aç</a>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="card p-1 m-1">
                    <div className="card-body p-1 m-0">

                        <div className='row'></div>

                        {this.state.stocks && this.state.stocks.map((element, index) => {
                            return (
                                <div key={index} className='col-12 row'>
                                    <div key={`stocks-${index}`} className="form-group col-md-4">
                                        {index !== 0 && <a href="#" onClick={(ev) => this.onRemoveStockButtonClick(ev, element, index)}>
                                            <span className="mr-2 text-danger"><i className="fas fa-window-close"></i></span>
                                        </a>}
                                        <label htmlFor="stock">{t("table.header.stock")} {index + 1}</label>
                                        <select ref={element} id={"stock" + index} name="stock" data-id={index} data-placeholder={t("placeholders.selectStock")} className="form-control  stockSelect select" data-fouc>
                                            <option value="0">Yükleniyor...</option>

                                        </select>
                                    </div>
                                    <div className='col'>

                                        <div className="form-group" >
                                            <label htmlFor="stock">Miktar </label>
                                            <input id={"new"} name={index} type="text" onChange={this.changePrice} className='miktarClass form-control' />
                                        </div>

                                    </div>
                                    <div className='col'>

                                        <div className="form-group" >
                                            <label htmlFor="stock">Stok No </label>
                                            <input id={"stockNo" + index} name="newQua" type="text" className='stockClass form-control' />
                                        </div>

                                    </div>
                                    <div className='col'>

                                        <div className="form-group" >
                                            <label htmlFor="stock">Barkod No </label>
                                            <input id={"barcodeNo" + index} name="newQua" type="text" className='barcodeClass form-control' />
                                        </div>

                                    </div>
                                    <div className='col'>

                                        <div className="form-group" >
                                            <label htmlFor="stock">Fiyat </label>
                                            <input id={"price" + index} name="newQua" onChange={this.changePrice} type="text" className='priceClass form-control' />
                                        </div>

                                    </div>
                                    <div className='col'>

                                        <div className="form-group" >
                                            <label htmlFor="stock">KDV </label>
                                            <input id={"vergi" + index} name="newQua" onChange={this.changePrice} type="text" className='vergiClass form-control' />
                                        </div>

                                    </div>
                                    <div className='col'>

                                        <div className="form-group" >
                                            <label htmlFor="stock">Raf No 1 </label>
                                            <input id={"rafNo" + index} name="newQua" type="text" className='rafNoClass form-control' />
                                        </div>

                                    </div>
                                    <div className='col'>

                                        <div className="form-group" >
                                            <label htmlFor="stock">Raf No 2 </label>
                                            <input id={"subRafNo" + index} name="newQua" type="text" className='subRafNoClass form-control' />
                                        </div>

                                    </div>
                                </div>
                            );
                        })}
                        <div className='row'>
                            <div className='col '>
                                <div style={{ backgroundColor: "#c9cacb" }} className="form-group ">
                                    <input readOnly value="Miktar Toplam=" className='form-control' disabled id='genelMiktar' />
                                </div>
                            </div>

                            <div className='col '>
                                <div style={{ backgroundColor: "#c9cacb" }} className="form-group ">
                                    <input readOnly value="Net Toplam=" className='form-control' disabled id='netFiyatToplam' />
                                </div>
                            </div>

                            <div className='col '>
                                <div style={{ backgroundColor: "#c9cacb" }} className="form-group">
                                    <input readOnly value="KDV Toplam=" className='form-control' disabled id='kdvToplam' />
                                </div>
                            </div>
                            <div className='col '>
                                <div style={{ backgroundColor: "#c9cacb" }} className="form-group ">
                                    <input readOnly value="Genel Toplam=" className='form-control' disabled id='genelFiyatToplam' />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps())(
    withTranslation(["storage-counting", "translations"])(StockOutV2)
);
function getFormattedDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [month, day, year].join('-');
}
function getFormatDBDate(date) {
    var d = new Date(date),
        year = d.getFullYear(),

        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [day, month, year,].join('-');
}