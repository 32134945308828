import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { setBreadcrumbs } from "../../features/active-page/sub-pages-slice";
import { setPageHeader } from "../../features/active-page/page-header-slice";
import { setHeaderElements } from "../../features/active-page/header-elements-slice";
import { EventHandlerContext } from "../../contexts/event-handler-context";
import $ from "jquery";
import "datatables.net";
import "datatables.net-responsive";
import "datatables.net-buttons-dt";
import "datatables.net-select";
import "jszip";
import "pdfmake";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "datatables.net-buttons/js/buttons.colVis";
import "datatables.net-buttons/js/buttons.flash";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Functions from "../../class/functions";
import axios from "axios";
import { getEndPoint, uploadsFaturaUri } from "../../state/api/index";
import { DateRangePickerHandler } from "../../js/plugins/pickers/daterangepicker";
import moment from "moment";

Functions.importThemeStyles();

const ReactSwal = withReactContent(Swal);

const mapStateToProps = (state) => ({
    redux: {
        pageHeader: state.pageHeader,
        breadcrumbs: state.breadcrumbs,
        headerElements: state.headerElements,
    },
});
const mapDispatchToProps = () => ({
    setPageHeader,
    setBreadcrumbs,
    setHeaderElements,
});

class StockCounting extends Component {
    static contextType = EventHandlerContext;

    constructor(props) {
        super(props);

        this.state = {
            rowsToDelete: "",
            selectDeleteItemRows: 0,
        };
        this.filterData = "";
        this.stockListTable = null;
        this.stockListTableRef = React.createRef();
        this.stockCode = React.createRef();
        this.personel = React.createRef();
        this.selectAllRef = React.createRef();
        this.ilkTarih = React.createRef();
        this.sonTarih = React.createRef();
    }
    componentDidMount() {
        const { t } = this.props;
        let _this = this;
        DateRangePickerHandler(this.ilkTarih.current, {
            parentEl: ".content-inner",
            showDropdowns: true,
            singleDatePicker: true,
            locale: t("dateRangePickerLocalization", { ns: "translations" }),
        });
        DateRangePickerHandler(this.sonTarih.current, {
            parentEl: ".content-inner",
            showDropdowns: true,
            singleDatePicker: true,
            locale: t("dateRangePickerLocalization", { ns: "translations" }),
        });

        this.props.setPageHeader("Stok Çıkış Raporu");

        $(".selectList2").select2({
            width: "100%",
            language: {
                noResults: () => t("plugins.select2.noResults", { ns: "translations" }),
                searching: () => t("plugins.select2.searching", { ns: "translations" }),
                maximumSelected: () =>
                    t("plugins.select2.maximumSelected", { ns: "translations" }),
                loadingMore: () =>
                    t("plugins.select2.loadingMore", { ns: "translations" }),
                inputTooShort: () =>
                    t("plugins.select2.inputTooShort", { ns: "translations" }),
                inputTooLong: () =>
                    t("plugins.select2.inputTooLong", { ns: "translations" }),
                errorLoading: () =>
                    t("plugins.select2.errorLoading", { ns: "translations" }),
            },
        });
        var config = {
            method: "get",
            url: `${getEndPoint()}/api/users/personel`,
            headers: {
                "Content-Type": "application/json",
            },
        };

        axios(config).then(function (response) {
            var selectData = response.data.data;
            $(_this.personel.current).empty().trigger("change");
            var selects = $(_this.personel.current);

            var option = new Option("Lütfen Seçim Yapınız...", 0, true, true);
            selects.append(option).trigger("change");
            selectData.forEach((elm, index) => {
                var option = new Option(elm.name, elm.code, false, false);
                selects.append(option).trigger("change");
            });
        });
        var config = {
            method: "PUT",
            url: `${getEndPoint()}/api/stocks/all`,
            headers: {
                "Content-Type": "application/json",
            },
            data: JSON.stringify({
                filters: [{ expression: "order", column: "stock_code", value: "ASC" }],
            }),
        };
        axios(config).then(function (response) {
            var selectData = response.data.data;
            $(_this.stockCode.current).empty().trigger("change");
            var selects = $(_this.stockCode.current);

            var option = new Option("Lütfen Seçim Yapınız...", 0, true, true);
            selects.append(option).trigger("change");
            selectData.forEach((elm, index) => {
                var option = new Option(
                    elm.stock_code + " - " + elm.stock_name,
                    elm.id,
                    false,
                    false
                );
                selects.append(option).trigger("change");
            });
        });

        if (this.stockListTable != undefined) this.stockListTable.destroy();

        this.stockListTable = $(".datatable-pagination").DataTable({
            pagingType: "simple_numbers",
            autoWidth: false,
            responsive: true,
            processing: true,
            searching: true,
            lengthMenu: [
                [50, 100, 150, -1],
                [50, 100, 150, "Hepsi"],
            ],
            serverSide: true,
            dom: "Blfrtip",
            buttons: ["copyHtml5", "excelHtml5", "csvHtml5", "pdfHtml5"],
            ajax: {
                url: `${getEndPoint()}/api/stocks/gc-list`,
                type: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
                xhrFields: {
                    withCredentials: true,
                },
                crossDomain: true,
                dataFilter: function (data) {
                    var json = $.parseJSON(data);
                    json.data = json.data;
                    json.recordsFiltered = json.result.recordsTotal;
                    json.recordsTotal = json.result.recordsTotal;
                    return JSON.stringify(json);
                },
            },
            fnServerParams: (aoData) => {
                aoData["filters"] = [
                    {
                        expression: "order",
                        column: aoData.columns[aoData.order[0].column].data,
                        value: aoData.order[0].dir,
                    },
                    {
                        expression: "LIKE",
                        column: $(".select").val(),
                        value:
                            "%" + String(aoData.search.value).toLocaleUpperCase("TR") + "%",
                    },
                ];
                var selectStokData = $(this.stockCode.current).select2("data")[0].text;
                var selectStokInfo = selectStokData.substring(
                    0,
                    selectStokData.indexOf(" ")
                );
                var selectFirmaData = $(this.personel.current).val();
                if (selectFirmaData != 0) {
                    aoData["filters"].push({ expression: "=", column: 'personelKodu', value: $(this.personel.current).val() })
                }
                if ($(this.stockCode.current).val() != 0) {

                    const selectedStokKodu = selectStokData.text.split(' - ')[0]; // Veri tabanında'da stokkodu alanında bulunan sadece stok kodunu almak için
                    aoData["filters"].push({ expression: "=", column: 'StokKodu', value: selectedStokKodu });
                }
                if (this.filterData == 1) {
                    aoData["filters"].push(
                        {
                            expression: ">",
                            column: "created_at",
                            value: moment(
                                $(this.ilkTarih.current).val(),
                                "DD/MM/YYYY"
                            ).format("YYYY-MM-DD"),
                        },
                        {
                            expression: "<",
                            column: "created_at",
                            value: moment(
                                $(this.sonTarih.current).val(),
                                "DD/MM/YYYY"
                            ).format("YYYY-MM-DD"),
                        }
                    );
                }

                aoData["gc"] = 0;
                aoData["is_entry"] = false;
                aoData["page"] = aoData.start / aoData.length;
                aoData["per_page"] = aoData.length;
            },
            columns: [
                { data: "id", orderable: "false" },
                { data: "StokKodu", orderable: "false" },
                {
                    data: "StokAdi",
                    render: function (data, type, row) {
                        if (!data.length) {
                            return " ";
                        }
                        return data;
                    },
                    orderable: false,
                },
                {
                    data: "get_stock",
                    render: function (data, type, row) {
                        if (!data.length) {
                            return " ";
                        }
                        return data[0].barcode_number;
                    },
                    orderable: false,
                },
                {
                    data: "Miktar",
                    render: function (data, type, row) {
                        if (data == null) {
                            return " ";
                        }
                        return data;
                    },
                },
                {
                    data: "KdvOran",
                    render: function (data, type, row) {
                        if (data == null) {
                            return " ";
                        }
                        return data;
                    },
                },
                {
                    data: "price",
                    render: function (data, type, row) {
                        if (data == null) {
                            return " ";
                        }
                        return data;
                    },
                },
                {
                    data: "get_personel",
                    render: function (data, type, row) {
                        if (!data.length) {
                            return "Personel Bulunamadı! ";
                        }
                        return (
                            '<a href="/personel/' + data[0].id + '">' + data[0].name + "</a> "
                        );
                    },
                    orderable: false,
                },
                {
                    data: "fatura_url",
                    render: function (data, type, row) {
                        if (data == null) {
                            return "Fatura Bulunamadı";
                        }
                        return (
                            "<a href=" +
                            uploadsFaturaUri() +
                            "/" +
                            data +
                            ' target="_blank">Faturayı Aç</a>'
                        );
                    },
                },
                {
                    data: "created_at",
                    render: function (data, type, row) {
                        if (data == null) {
                            return " ";
                        }
                        return Functions.getFormatLocaleDateHours(data.substring(0, 10));
                    },
                },
            ],
            order: [[0, "asc"]],
            language: {
                ...t("plugins.dataTables", { ns: "translations" }),
                paginate: {
                    next:
                        $("html").attr("dir") === "rtl"
                            ? t("plugins.dataTables.paginate.next", { ns: "translations" }) +
                            "&larr;"
                            : t("plugins.dataTables.paginate.next", { ns: "translations" }) +
                            " &rarr;",
                    previous:
                        $("html").attr("dir") === "rtl"
                            ? "&rarr; " +
                            t("plugins.dataTables.paginate.previous", {
                                ns: "translations",
                            })
                            : "&larr; " +
                            t("plugins.dataTables.paginate.previous", {
                                ns: "translations",
                            }),
                },
            },
        });
    }

    componentWillUnmount() {
        this.props.setPageHeader("");
        this.props.setHeaderElements([]);
        this.context.handlers = [];
    }
    handleClickFilter = () => {
        this.filterData = 1;

        $.fn.dataTable.tables({ api: true }).ajax.reload();
    };

    render() {
        const { t } = this.props;

        return (
            <div>
                <div className="card mb-1">
                    <div className="card-body py-2">
                        <div className="row">
                            <div className="col-sm-2">
                                <div className="d-block w-100 mt-2">
                                    <span>Stok Kodu ve Adı</span>
                                    <div className="d-flex">
                                        <select
                                            ref={this.stockCode}
                                            name="stockCode"
                                            className="selectList2 form-control"
                                        >
                                            <option value="0">Yükleniyor...</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-2">
                                <div className="d-block w-100 mt-2">
                                    <span>Personel</span>
                                    <div className="d-flex">
                                        <select
                                            ref={this.personel}
                                            name="personel"
                                            className="selectList2 form-control"
                                        >
                                            <option value="0">Yükleniyor...</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-2">
                                <div className="d-block w-100 mt-2">
                                    <span>İlk Tarih</span>
                                    <div className="d-flex">
                                        <input
                                            ref={this.ilkTarih}
                                            type="text"
                                            className="form-control w-100 daterange-single"
                                            defaultValue=""
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-2">
                                <div className="d-block w-100 mt-2">
                                    <span>Son Tarih</span>
                                    <div className="d-flex">
                                        <input
                                            ref={this.sonTarih}
                                            type="text"
                                            className="form-control w-100 daterange-single"
                                            defaultValue=""
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3 mt-4">
                                <button
                                    onClick={this.handleClickFilter}
                                    type="button"
                                    className="btn btn-primary w-100"
                                >
                                    Listele
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="w-100">
                            <table
                                ref={this.stockListTableRef}
                                className="table table-striped table-hover dt-responsive nowrap datatable-pagination w-100"
                            >
                                <thead className="w-100">
                                    <tr>
                                        <th>
                                            <label>Aranılacak Alan</label>
                                        </th>
                                        <th>
                                            <div className="form-group">
                                                <select className="select" ref={this.columnSelect}>
                                                    <option value="StokAdi">Stok Adı</option>
                                                    <option value="StokKodu">Stok Kodu</option>
                                                </select>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>{t("table.header.stockCode")}</th>
                                        <th>{t("table.header.stockName")}</th>
                                        <th>{t("table.header.barcodeNumber")}</th>
                                        <th>Stok Miktarı</th>
                                        <th>KDV</th>
                                        <th>Fiyat</th>
                                        <th>Personel</th>
                                        <th>Fatura</th>
                                        <th>Tarih</th>
                                    </tr>
                                </thead>
                                <tbody className="w-100"></tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps()
)(withTranslation(["storage-entries", "translations"])(StockCounting));
