import React, { Component } from "react";
import { connect } from "react-redux";
import { setUser } from "../features/user/user-slice";
import Functions from "../class/functions";
import axios from "axios";
import { withTranslation } from "react-i18next";
import { getEndPoint } from "../state/api/index";
import Cookies from "universal-cookie";
const cookies = new Cookies();

// const ReactSwal = withReactContent(Swal);

const mapStateToProps = (state) => ({
  redux: {
    user: state.user,
  },
});
const mapDispatchToProps = () => ({
  setUser: setUser,
});

class Logout extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let _this = this;

    axios({
      method: "post",
      url: `${getEndPoint()}/api/logout`,
      headers: {},
    })
      .then(function (response) {
        if (response.data.result.statusCode === 200) {
          _this.props.setUser(undefined);
          Functions.deleteUserData();
          _this.props.router.navigate("/login");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    cookies.remove("warningCriticalStocks");
  }

  render() {
    return <div></div>;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(withTranslation(["logout", "translations"])(Functions.withRouter(Logout)));
