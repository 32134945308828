import React, { Component, createRef } from 'react';
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { setBreadcrumbs } from '../../features/active-page/sub-pages-slice';
import { setPageHeader } from '../../features/active-page/page-header-slice';
import { setHeaderElements } from '../../features/active-page/header-elements-slice';
import { EventHandlerContext } from '../../contexts/event-handler-context';
import Functions from '../../class/functions';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import $ from "jquery";
import intlTelInput from 'intl-tel-input';
import "intl-tel-input/build/css/intlTelInput.min.css";
import {getEndPoint} from "../../state/api/index"

const ReactSwal = withReactContent(Swal);
Functions.importThemeStyles();

const mapStateToProps = state => ({
    redux: {
        pageHeader: state.pageHeader,
        breadcrumbs: state.breadcrumbs,
        headerElements: state.headerElements
    }
});
const mapDispatchToProps = () => ({
    setPageHeader,
    setBreadcrumbs,
    setHeaderElements
});

class CompanyAdd extends Component {
    static contextType = EventHandlerContext

    constructor(props) {
        super(props);
        this.code = createRef();
        this.name = createRef();
        this.description = createRef();
        this.address = createRef();
        this.phone = createRef();
        this.phone_2 = createRef();
        this.fax = createRef();
        this.tax_administration = createRef();
        this.account_number = createRef();
        this.responsible_id = createRef();
        this.state = {

        };
    }

    componentDidMount() {
        const { t } = this.props;
        const _this = this;

        this.code.current.value = "";
        this.name.current.value = "";
        this.description.current.value = "";
        this.address.current.value = "";
        this.phone.current.value = "";
        this.phone_2.current.value = "";
        this.fax.current.value = "";
        this.tax_administration.current.value = "";
        this.account_number.current.value = "";
        this.responsible_id.current.value = "";

        this.props.setPageHeader(t("pages.company.add", { ns: "translations" }));
        this.props.setHeaderElements([
            {
                componentId: "add-company",
                href: "#", translation: { key: "misc.add", opts: { ns: "translations" } },
                className: "btn btn-link btn-float text-body", icon: "fas fa-plus text-primary",
            },
        ]);
        $("input[type$='tel']").each((index, telInput) => {
            intlTelInput(telInput,
                {
                    autoPlaceholder: "polite",
                    geoIpLookup: (success, failure) => {
                        //success(countryCode)
                    },
                    preferredCountries: ["tr", "us", "gb"],
                    nationalMode: false,
                    seperateDialCode: true,
                    utilsScript: require("intl-tel-input/build/js/utils"),
                    customContainer: "w-100",
                    formatOnDisplay: true
                }
            )
        });
        this.context.addEventHandler("add-company", () => {

            ReactSwal.fire({
                title: <span>{t("operations.addedOperations", { ns: "translations" })}</span>,
                html: <span>{t("operations.areYouSure", { ns: "translations" })}</span>,
                icon: '',
                showCloseButton: true,
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText: t("misc.yes", { ns: "translations" }),
                cancelButtonText: t("misc.cancel", { ns: "translations" }),
            }).then((a) => {


                var data = JSON.stringify({
                    "code": this.code.current.value,
                    "name": this.name.current.value,
                    "description": this.description.current.value,
                    "address": this.address.current.value,
                    "phone": this.phone.current.value,
                    "phone_2": this.phone_2.current.value,
                    "fax": this.fax.current.value,
                    "tax_administration": this.tax_administration.current.value,
                    "account_number": parseInt(this.account_number.current.value),
                    "responsible_id": this.responsible_id.current.value,
                });

                var config = { 
                    method: 'post', 
                    url: `${getEndPoint()}/api/companies/add`,   
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: data
                };

                axios(config)
                    .then(function (response) {
                        ReactSwal.fire({
                            title: <span>{t("misc.successfull", { ns: "translations" })}</span>,
                            html: <span>{t("operations.success", { ns: "translations", itemCount: _this.state.selectDeleteItemRows })}</span>,
                            icon: '',
                            showCloseButton: true,
                            focusConfirm: false,
                            confirmButtonText: t("misc.ok", { ns: "translations" }),
                        })
                        _this.componentDidMount()
                    })
            })
        });
    }

    componentWillUnmount() {
        this.props.setPageHeader("");
        this.props.setHeaderElements([]);
        this.context.handlers = [];
    }

    onAddStockButtonClick(ev) {
    }

    render() {
        const { t } = this.props;

        return (
            <div>
                <div className="card p-1 m-0">
                    <div className="card-body p-1 m-0">

                        <div className='row'>
                            <div className="col-md-6">
                                <div className='form-group'>
                                    <label htmlFor="companyCode">{t("companyCode")}</label>
                                    <input ref={this.code} id="companyCode" type="text" className='form-control' />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="companyName">{t("companyName")}</label>
                                    <input ref={this.name} name="companyName" type="text" className='form-control' />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="companyPhone">{t("companyPhone")}</label>
                                    <input ref={this.phone} name="companyPhone" type="tel" defaultValue="+90 " className='form-control' />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="companyPhone2">{t("companyPhone")} 2</label>
                                    <input ref={this.phone_2} name="companyPhone2" type="tel" defaultValue="+90 " className='form-control' />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="companyAddress">{t("companyAddress")}</label>
                                    <textarea ref={this.address} name="companyAddress" type="text" className='form-control'>
                                    </textarea>
                                </div>

                            </div>
                            <div className="col-md-6">

                                <div className="form-group">
                                    <label htmlFor="companyFax">{t("companyFax")}</label>
                                    <input ref={this.fax} name="companyFax" type="fax" required className='form-control' />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="companyTaxAdministration">{t("companyTaxAdministration")}</label>
                                    <input ref={this.tax_administration} name="companyTaxAdministration" type="text" className='form-control' />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="companyAccountNumber">{t("companyAccountNumber")}</label>
                                    <input ref={this.account_number} name="companyAccountNumber" type="number" className='form-control' />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="companyAuthoritative">{t("companyAuthoritative")}</label>
                                    <input ref={this.responsible_id} name="companyAuthoritative" type="text" className='form-control' />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="companyDescription">{t("companyDescription")}</label>
                                    <textarea ref={this.description} style={{ height: '170px' }} name="companyDescription" type="text" className='form-control' />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps())(
    withTranslation(["company-add", "translations"])(CompanyAdd)
);