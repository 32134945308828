import { createSlice } from '@reduxjs/toolkit'
import { ReactSession } from 'react-client-session'
import Functions from '../../class/functions'
import Cookies from 'universal-cookie'
const cookies = new Cookies()

if (cookies.get('auth') == undefined) {
  Functions.deleteUserData()
}
const userData = ReactSession.get('userData')

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    value: userData
  },
  reducers: {
    setUser(state, action) {
      state.value = action.payload
    }
  }
})

export const { setUser } = userSlice.actions

export default userSlice.reducer
