export const config = {
  imgUri: "https://new.cloudfront.net", //Product
  endPointTest: {
    // url: 'http://stockv9x.ferofen.com',
    url: "https://stoktakipapi.kobis.com.tr",
    reCaptcha: "6Lc_",
    uploadsImgUri: "http://localhost:1881/uploads/",
    uploadsFaturaUri: "https://stoktakipapi.kobis.com.tr/document/",
  },
  endPointProduction: {
    url: "https://test.com",
    reCaptcha: "6Lc_",
    uploadsFaturaUri: "https://stoktakipapi.kobis.com.tr/document/",
  },
  endPointStage: {
    // url: "http://localhost:8000",
    url: "https://stoktakipapi.kobis.com.tr",
    reCaptcha: "6LfPHSw",
    uploadsFaturaUri: "https://stoktakipapi.kobis.com.tr/document/",
    // uploadsFaturaUri: "http://localhost:8000/document/",
    uploadsImgUri: "https://stoktakipapi.kobis.com.tr/stock_images/",
    // uploadsImgUri: "http://localhost:8000/stock_images/",
  },
  explorerEndPointTx: "https://xxx/tx/",
  explorerEndPointAsset: "https://xxx/asset/",
};
