import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

export function getLanguages() {
  return ['tr-TR', 'en-US'];
}

i18n.use(initReactI18next).init({
  fallbackLng: 'tr-TR',
  lng: 'tr-TR',
  returnObjects: true,
  resources: {
    // languages kısmındaki array içine yeni bir dil kodu eklenirse otomatikman aynı dosyaları aynı dil kodunda arar
    ...(getLanguages().reduce((a, v) => ({ ...a, [v]: {
      "translations": require(`./locales/${v}/translations.json`),
      "login": require(`./locales/${v}/login.json`),
      "stock-list": require(`./locales/${v}/stocks/list.json`),
      "stock-add": require(`./locales/${v}/stocks/add.json`),
      "stock-out": require(`./locales/${v}/stocks/out.json`),
      "storage-list": require(`./locales/${v}/storages/list.json`),
      "storage-add": require(`./locales/${v}/storages/add.json`),
      "storage-entries": require(`./locales/${v}/storages/entries.json`),
      "storage-checkouts": require(`./locales/${v}/storages/checkouts.json`),
      "storage-counting": require(`./locales/${v}/storages/counting.json`),
      "storage-transfer": require(`./locales/${v}/storages/transfer.json`),
      "user-list": require(`./locales/${v}/users/list.json`),
      "user-add": require(`./locales/${v}/users/add.json`),
      "debit-tracking": require(`./locales/${v}/debit/tracking.json`),
      "debit-add": require(`./locales/${v}/debit/add.json`),
      "company-list": require(`./locales/${v}/company/list.json`),
      "company-add": require(`./locales/${v}/company/add.json`),
      "settings-profile": require(`./locales/${v}/settings/profile.json`),
    }}), {}))
  },
  ns: ['translations', 'login', 
       'stock-list', 'stock-add', 
       'storage-list', 'storage-add', 'storage-entries', 'storage-checkouts', 'storage-counting', 'storage-transfer',
       'user-list', 'user-add',
       'debit-tracking', 'debit-add',
       'company-list', 'company-add',
       'settings-profile'],
  defaultNS: 'translations'
});

export default i18n;

// Summernote Localization: https://summernote.org/getting-started/#i18n-support
// DataTables Localization: https://datatables.net/plug-ins/i18n/
