import React, { Component, createRef } from 'react';
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { setBreadcrumbs } from '../../features/active-page/sub-pages-slice';
import { setPageHeader } from '../../features/active-page/page-header-slice';
import { setHeaderElements } from '../../features/active-page/header-elements-slice';
import { EventHandlerContext } from '../../contexts/event-handler-context';
import Functions from '../../class/functions';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import $ from "jquery";
import intlTelInput from 'intl-tel-input';
import "intl-tel-input/build/css/intlTelInput.min.css";
import {getEndPoint} from "../../state/api/index"

const ReactSwal = withReactContent(Swal);
Functions.importThemeStyles();

const mapStateToProps = state => ({
    redux: {
        pageHeader: state.pageHeader,
        breadcrumbs: state.breadcrumbs,
        headerElements: state.headerElements
    }
});
const mapDispatchToProps = () => ({
    setPageHeader,
    setBreadcrumbs,
    setHeaderElements
});

class StorageAdd extends Component {
    static contextType = EventHandlerContext

    constructor(props) {
        super(props);
        this.code = createRef();
        this.name = createRef();
        this.address = createRef();
        this.phone = createRef();
        this.responsible_employee_id = createRef();
        this.createSelect = (ref, t, selectType) => {
            $(ref.current).select2({
                width: "100%",
                language: {
                    noResults: () => t("plugins.select2.noResults", { ns: "translations" }),
                    searching: () => t("plugins.select2.searching", { ns: "translations" }),
                    maximumSelected: () => t("plugins.select2.maximumSelected", { ns: "translations" }),
                    loadingMore: () => t("plugins.select2.loadingMore", { ns: "translations" }),
                    inputTooShort: () => t("plugins.select2.inputTooShort", { ns: "translations" }),
                    inputTooLong: () => t("plugins.select2.inputTooLong", { ns: "translations" }),
                    errorLoading: () => t("plugins.select2.errorLoading", { ns: "translations" }),
                }
            });
            Functions.createSelectList(ref, t, selectType);

        }

       
        this.state = {

        };
    }

    componentDidMount() {
        const { t } = this.props;
        this.createSelect(this.responsible_employee_id, t, 'employee');
        this.props.setPageHeader(t("pages.storages.add", { ns: "translations" }));
        this.props.setHeaderElements([
            {
                componentId: "add-storage",
                href: "#", translation: { key: "misc.add", opts: { ns: "translations" } },
                className: "btn btn-link btn-float text-body", icon: "fas fa-plus text-primary",
            },
        ]);
        $("input[type$='tel']").each((index, telInput) => {
            intlTelInput(telInput,
                {
                    autoPlaceholder: "polite",
                    geoIpLookup: (success, failure) => {
                        //success(countryCode)
                    },
                    preferredCountries: ["tr", "us", "gb"],
                    nationalMode: false,
                    seperateDialCode: true,
                    utilsScript: require("intl-tel-input/build/js/utils"),
                    customContainer: "w-100",
                    formatOnDisplay: true
                }
            )
        });
        this.context.addEventHandler("add-storage", () => {
            var data = JSON.stringify({
                "code": this.code.current.value,
                "name": this.name.current.value,
                "address": this.address.current.value,
                "phone": this.phone.current.value,
                "responsible_employee_id": parseInt(this.responsible_employee_id.current.value),
            });

            var config = {
                method: 'post',
                url: `${getEndPoint()}/api/storages/add`,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: data
            };

            axios(config)
                .then(function (response) {
                    ReactSwal.fire({
                        title: <span>{t("misc.successfull", { ns: "translations" })}</span>,
                        html: <span>{t("operations.successMessage", { ns: "translations" })}</span>,
                        icon: '',
                        showCloseButton: true,
                        focusConfirm: false,
                        confirmButtonText: t("misc.ok", { ns: "translations" }),
                    })
                }).catch(function (error) {
                    ReactSwal.fire({
                        title: <span>{t("misc.error", { ns: "translations" })}</span>,
                        html: <span>{t("operations.errorMessage", { ns: "translations" })}</span>,
                        icon: '',
                        showCloseButton: true,
                        focusConfirm: false,
                        confirmButtonText: t("misc.ok", { ns: "translations" }),
                    })
                })
        });
    }

    componentWillUnmount() {
        this.props.setPageHeader("");
        this.props.setHeaderElements([]);
        this.context.handlers = [];
    }

    render() {
        const { t } = this.props;

        return (
            <div className='card p-1'>
                <div className='card-body p-1'>
                    <div className="form-group">
                        <label htmlFor="code">{t("code")}</label>
                        <input ref={this.code} name="code" className="form-control" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="name">{t("name")}</label>
                        <input ref={this.name} name="name" type="text" className='form-control' />
                    </div>
                    <div className="form-group">
                        <label htmlFor="phone">{t("phone")}</label>
                        <input ref={this.phone} name="phone" type="tel"  defaultValue="+90 "className='form-control' />
                    </div>
                    <div className="form-group">
                        <label htmlFor="responsible_employee_id">{t("responsible_employee_id")}</label>
                        <select ref={this.responsible_employee_id} name="responsible_employee_id" className="form-control">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="address">{t("address")}</label>
                        <textarea ref={this.address} name="address" className="form-control" />
                    </div>


                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps())(
    withTranslation(["storage-add", "translations"])(StorageAdd)
);