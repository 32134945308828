import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { resetToken, setToken } from "../state/slices/api.slice";
import { getEndPoint } from "../state/api/index";

import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import "datatables.net";
import "datatables.net-responsive";
import "datatables.net-select";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Functions from "../class/functions";
import "sweetalert2/dist/sweetalert2.min.css";
import "sweetalert2/dist/sweetalert2.css";
import axios from "axios";
import $ from "jquery";
import { connect } from "react-redux";
import { setUser } from "../features/user/user-slice";
import Cookies from "universal-cookie";
import { ReactSession } from "react-client-session";
const cookies = new Cookies();
// const ReactSwal = withReactContent(Swal);

const mapStateToProps = (state) => ({
  redux: {
    user: state.user,
  },
});
const mapDispatchToProps = () => ({
  setUser: setUser,
});

class Login extends Component {
  constructor(props) {
    super(props);
    // if (this.props.redux.user !== null || this.props.redux.user !== undefined) {
    //     // this.props.router.navigate('/')
    //     //hata var
    // }

    this.usernameRef = React.createRef();
    this.passwordRef = React.createRef();
  }

  componentDidMount() {
    const { t } = this.props;
    Functions.changeTitle(t("pages.login", { ns: "translations" }));
  }

  onLoginButtonClick(ev) {
    ev.preventDefault();

    let _this = this;

    axios({
      method: "post",
      url: `${getEndPoint()}/api/login`,
      headers: {
        "Content-Type": "application/json",
        "XSRF-TOKEN":
          "eyJpdiI6IlZBMk1vVHVIYUFOUnRxYUNwZEtKZXc9PSIsInZhbHVlIjoiNHpPMHNzTFMxSnlzaXJXT1BHM2pkMnNkcW40bkRNV1h0VHdJT1ZyUjc2Rm91YXBWSTlUYUlDSHcxRzNXblRYdmQ3aGlZVVJDUTFpNy9OaUI5anoyRjFiTERlZ0pmL1dGbEx4K0pYRHhacThPdGxZNC8rQitha0JtbzRBWXBKVjAiLCJtYWMiOiJkYWVhNGEzMTU3Zjc3NDk2YjI3NmUyZWRmMzIwZmRjNWJjZWFlYzZlZDdhNGI3MmQwMDkyMmRmNWM3OWU4N2VmIiwidGFnIjoiIn0%3D; laravel_session=eyJpdiI6IlE5NFFtVTRhM0RybDcvcnh5bmoxZGc9PSIsInZhbHVlIjoicEpsd2MyQkk1Z3VsUkNwNmx3Z3JKZjhSVjlrYW53cm1NVzRlTkl5NHRodlZGbGM5WTQ1S29FTlg0WGFvL1I5VHNmaHVVeVJLM2RmNEFFR1E3UktmU0xPZWVwYjZtTTkvYm5McUd1anZNYzlld2lUWXZaVGZyQmNGQlFEOS9mVGgiLCJtYWMiOiJhOTI5ZWU4M2QzMjhjZTU5MTdhMWU3OTVlYmYzMzgxYmUzZGM3ZjIwZDE1MWNhNjc1N2Q1NGU4NWFkNmVlYmFlIiwidGFnIjoiIn0%3D; remember_web_59ba36addc2b2f9401580f014c7f58ea4e30989d=eyJpdiI6IlY0Y2xHVi8xZXdnR2R4S1RsRmZxZ2c9PSIsInZhbHVlIjoibjliVmRTSS9XazEwK245eWVtZnk3TXIzWXpSMzY4cERmbkNWT3dkL085UnpGRFJtb2J1aVlnT1doMGVpalZwQk5WSnJZMlc1c1Q3MDhxNWh6Vk1lUjlERXpDV1NYSkZhbDBJYmpJS3pnUUpsUHRHQm1aWHQvSXlEazNLWWdmN09pZHNLUGlPVlBkWkxVTHA1eXZ2MjBuT0pXTHJsS3drMmJqSnRmZTBqUzBlTG1qMm1GNlBrcnpCTWlIM3RYam96NDlZMXVSc0h6cDlqNG8yOEh4d0tlYVpvaGM0MnZHam1kS21Pb010bTRCcz0iLCJtYWMiOiI4MGRlOGJkOTBmNzFlZjYxMGZiNzczYTY2NDJlZjc5NDZkOGZmZDJkMDRlOGU3Yzc3MGYwNmE3YzU4NmExNDVjIiwidGFnIjoiIn0%3D",
      },
      data: {
        email: this.usernameRef.current.value,
        password: this.passwordRef.current.value,
        rememberMe: true,
      },
    })
      .then(function (response) {
        var dataObject = response.data;

        if (
          dataObject.result.statusCode === 200 &&
          dataObject.result.statusMessage === "success"
        )
          _this.props.setUser(dataObject.data);

        cookies.set("auth", "true", {
          path: "/",
          expires: new Date(Date.now() + 7200000),
        });
        Functions.setUserData(dataObject.data[0]);

        Swal.mixin({
          toast: true,
          icon: dataObject.result.statusMessage,
          title: "İşlem Başarılı Yönlendirme Gerçekleştiriliyor...",
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
          willClose: () => {
            if (dataObject.result.statusCode === 200)
              _this.props.router.navigate("/");
            window.location.href = "/";
          },
        }).fire();
      })
      .catch(function (error) {
        Swal.fire(
          "Hata",
          "Giriş Yapmaya Çalışırken Hata Oluştu Lütfen Tekrar Deneyiniz...",
          ""
        );
      });
    $(".demo").on("onkeypress", function () {});
  }

  render() {
    const { t } = this.props;
    const kobisLogoImage = require("../img/logo/kobis.png");

    return (
      <div className="page-content">
        <div className="content-wrapper">
          <div className="content-inner">
            <div className="content d-flex justify-content-center align-items-center">
              <form className="login-form">
                <div className="card mb-0">
                  <div className="card-body">
                    <div className="text-center mb-3">
                      <img
                        src={kobisLogoImage}
                        style={{ height: "160px", width: "auto" }}
                        alt="build_v13"
                      />
                    </div>

                    <div className="form-group form-group-feedback form-group-feedback-left">
                      <input
                        ref={this.usernameRef}
                        type="text"
                        className="form-control"
                        placeholder={t("username")}
                      />
                      <div className="form-control-feedback">
                        <i className="icon-user text-muted"></i>
                      </div>
                    </div>

                    <div className="form-group form-group-feedback form-group-feedback-left">
                      <input
                        ref={this.passwordRef}
                        type="password"
                        className="form-control"
                        placeholder={t("password")}
                      />
                      <div className="form-control-feedback">
                        <i className="icon-lock2 text-muted"></i>
                      </div>
                    </div>

                    <div className="form-group">
                      <button
                        onClick={(e) => this.onLoginButtonClick(e)}
                        type="submit"
                        className="btn btn-primary btn-block"
                      >
                        {t("signIn")}
                      </button>
                    </div>
                    <div className="form-group"></div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(withTranslation(["login", "translations"])(Functions.withRouter(Login)));
