import React, { Component, createRef } from 'react';
import $, { error } from "jquery";

import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { setBreadcrumbs } from '../../features/active-page/sub-pages-slice';
import { setPageHeader } from '../../features/active-page/page-header-slice';
import { setHeaderElements } from '../../features/active-page/header-elements-slice';
import { EventHandlerContext } from '../../contexts/event-handler-context';
import "datatables.net";
import intlTelInput from 'intl-tel-input';
import "intl-tel-input/build/css/intlTelInput.min.css";
import "datatables.net-responsive";
import "datatables.net-select";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Functions from '../../class/functions';
import axios from 'axios';
import { getEndPoint } from "../../state/api/index"

// import '../../js/main/bootstrap.bundle.min.js'

Functions.importThemeStyles();
let UserListData = [];

const ReactSwal = withReactContent(Swal);
const mapStateToProps = state => ({
    redux: {
        pageHeader: state.pageHeader,
        breadcrumbs: state.breadcrumbs,
        headerElements: state.headerElements
    }
});
const mapDispatchToProps = () => ({
    setPageHeader,
    setBreadcrumbs,
    setHeaderElements
});

class Personel extends Component {
    static contextType = EventHandlerContext

    constructor(props) {
        super(props);
        this.columnSelect = React.createRef();

        this.state = {
            rowsToDelete: "",
            selectDeleteItemRows: 0,
            userData: {}
        };

        this.storageListTable = null;
        this.storageListTableRef = React.createRef();
        this.selectAllRef = React.createRef();
        this.nameRef = React.createRef();
        this.emailRef = React.createRef();
        this.phoneRef = React.createRef();
        this.adressRef = React.createRef();
        this.roleSelectRef = React.createRef();
    }

    componentDidMount() {

        const { t } = this.props;
        let _this = this;
        this.props.setPageHeader('Personeller');

        this.context.addEventHandler("delete-user", (ev) => this.onDeleteStorageButtonClick(ev));

        $('.select').select2({
            width: '100%'
        });
        $('.roleSelect').select2({
            width: '100%'
        });
        if (this.storageListTable != undefined)
            this.storageListTable.destroy();

        this.storageListTable = $(".datatable-pagination").DataTable({
            pagingType: 'simple_numbers',
            autoWidth: false,
            responsive: true,
            processing: true,
            serverSide: true,
            lengthMenu: [[50, 100, 150, -1], [50, 100, 150, "Hepsi"]],
            ajax: {
                url: `${getEndPoint()}/api/users/personel`,
                type: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                xhrFields: {
                    withCredentials: true
                },
                crossDomain: true,
                dataFilter: function (data) {
                    var json = $.parseJSON(data);
                    json.data = json.data;
                    UserListData = json.data;
                    json.recordsFiltered = json.result.recordsTotal
                    json.recordsTotal = json.result.recordsTotal
                    return JSON.stringify(json);
                },
            },
            fnServerParams: (aoData) => {

                aoData["filters"] = [{ expression: "LIKE", column: $('.select').val(), value: '%' + String(aoData.search.value).toLocaleUpperCase('TR') + '%' },
                { "expression": "order", "column": aoData.columns[aoData.order[0].column].data, "value": aoData.order[0].dir },];
                aoData["page"] = aoData.start / aoData.length;
                aoData["per_page"] = aoData.length;
            },
            "columns": [
                { data: "id", "orderable": "false" },

                { data: "code", "orderable": "false" },
                { data: "name", "orderable": "false" },
                { data: "address", "orderable": "false" },
                { data: "phone", "orderable": "false" },
                {

                    data: "id", render: function (data, type, row) {

                        var content = ` Swal.fire({
                            title: 'Silmek İstediğinize Emin misiniz?',
                            showDenyButton: true,
                            confirmButtonText: 'Kaydet.',
                            denyButtonText: 'Vazgeç.',
                        }).then((result) => {
                            if (result.isConfirmed) {
                               
                                $.ajax({
                                    url: '${getEndPoint()}/api/users/personel-delete',
                                    method: 'post',
                                    headers: {
                                        'Accept': 'application/json',
                                        'Content-Type': 'application/json'
                                    },
                                    data: JSON.stringify({ 'id': ${data}}),
                                    xhrFields: {
                                    withCredentials: true
                                },
                            crossDomain: true,
                            success: function (response) {
                                Swal.fire('Başarılı', 'Personel Silindi', 'success')
                                location.reload();

                            }
                        });
                                   
                
                            } else if (result.isDenied) {
                                Swal.fire('Değişiklikler Kaydedilmedi', '', 'error')
                            }
                        })
                
                `
                        var htmlContent = '<a href="#" id="user-creditBtn" type="button"  onclick="' + content + '" data-target="#user-credit" data-toggle="modal" class="user-credit text-center  dropdown-item"><i class="fa fa-trash"> </i>Sil </a> <a href="/personel/' + row.id + '" class="text-center dropdown-item"><i class="icon-pencil"></i> Düzenle </a>'
                        return htmlContent
                    }, "orderable": "false"
                },


            ],
            order: [[0, 'asc']],
            language: {
                ...(t("plugins.dataTables", { ns: "translations" })),
                paginate: {
                    'next': $('html').attr('dir') === 'rtl' ? t("plugins.dataTables.paginate.next", { ns: "translations" }) + '&larr;' : t("plugins.dataTables.paginate.next", { ns: "translations" }) + ' &rarr;',
                    'previous': $('html').attr('dir') === 'rtl' ? '&rarr; ' + t("plugins.dataTables.paginate.previous", { ns: "translations" }) : '&larr; ' + t("plugins.dataTables.paginate.previous", { ns: "translations" })
                }
            }
        });

        this.storageListTable.on('select', (e, dt, type, indexes) => this.onStorageListTableSelect(e, dt, type, indexes));
        this.storageListTable.on('deselect', (e, dt, type, indexes) => this.onStorageListTableDeselect(e, dt, type, indexes));

        this.storageListTable.on('click', '[id*=btnDetails]', function (e) {
            var data = _this.storageListTable.row($(this).parents('tr'));
            _this.setState({
                userData: UserListData[data[0]]
            })
            var config = {
                method: 'get',
                url: `${getEndPoint()}/api/roles/all`,
            };

            axios(config)
                .then(function (response) {
                    var roleSelect = $('.roleSelect');
                    roleSelect.empty().trigger('change');
                    response.data.data.forEach(element => {
                        var option = new Option(
                            element.key,
                            element.id,
                            _this.state.userData.get_role.id == element.id ? true : false,
                            _this.state.userData.get_role.id == element.id ? true : false);
                        roleSelect.append(option).trigger('change');

                    });
                })
        });

        $(this.storageListTable.current + 'tfoot th').each(function () {
            var title = $(this.storageListTable.current + 'tfoot th').eq($(this).index()).text();
            $(this).html('<input type="text" placeholder="Search ' + title + '" />');
        });
        $("input[type$='tel']").each((index, telInput) => {
            intlTelInput(telInput,
                {
                    autoPlaceholder: "polite",
                    geoIpLookup: (success, failure) => {
                        //success(countryCode)
                    },
                    preferredCountries: ["tr", "us", "gb"],
                    nationalMode: false,
                    seperateDialCode: true,
                    utilsScript: require("intl-tel-input/build/js/utils"),
                    customContainer: "w-100",
                    formatOnDisplay: true
                }
            )
        });

    }
    componentWillUnmount() {
        this.props.setPageHeader("");
        this.props.setHeaderElements([]);
        this.context.handlers = [];
    }





    render() {
        const { t } = this.props;

        return (
            <div className='card'>
                <div className='card-body'>
                    <div className='w-100'>

                        <table ref={this.storageListTableRef} className="table table-striped table-hover dt-responsive nowrap datatable-pagination w-100">
                            <thead className='w-100'>
                                <tr>
                                    <th>
                                        <label>Aranılacak Alan</label>

                                    </th>
                                    <th>
                                        <div className='form-group'>
                                            <select className='select' ref={this.columnSelect}>
                                                <option value="name">Ad || Soyad</option>
                                                <option value="phone">Telefon</option>
                                                <option value="adress">Adres</option>
                                            </select>
                                        </div>
                                    </th>

                                </tr>
                                <tr>
                                    <th></th>

                                    <th>Personel Kodu</th>
                                    <th>{t("table.header.name")}</th>
                                    <th>{t("table.header.phone")}</th>
                                    <th>Adres</th>
                                    <th>İşlemler</th>
                                </tr>
                            </thead>
                            <tbody className='w-100'>

                            </tbody>
                        </table>
                    </div>
                </div>



            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps())(
    withTranslation(["user-list", "translations"])(Personel)
);