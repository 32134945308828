import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { setBreadcrumbs } from '../../features/active-page/sub-pages-slice';
import { setPageHeader } from '../../features/active-page/page-header-slice';
import { setHeaderElements } from '../../features/active-page/header-elements-slice';
import { EventHandlerContext } from '../../contexts/event-handler-context';
import $ from "jquery";
import "datatables.net";
import "datatables.net-responsive";
import "datatables.net-buttons-dt";
import "datatables.net-select";
import "jszip";
import "pdfmake";
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.print';
import 'datatables.net-buttons/js/buttons.colVis';
import 'datatables.net-buttons/js/buttons.flash';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Functions from '../../class/functions';
import { getEndPoint } from "../../state/api/index"

Functions.importThemeStyles();

const ReactSwal = withReactContent(Swal);

const mapStateToProps = state => ({
    redux: {
        pageHeader: state.pageHeader,
        breadcrumbs: state.breadcrumbs,
        headerElements: state.headerElements
    }
});
const mapDispatchToProps = () => ({
    setPageHeader,
    setBreadcrumbs,
    setHeaderElements
});

class EmbezzlementTracking extends Component {
    static contextType = EventHandlerContext

    constructor(props) {
        super(props);

        this.state = {
            rowsToDelete: 0
        };

        this.embezzlementTrackingListTable = null;
        this.embezzlementTrackingListTableRef = React.createRef();
        this.selectAllRef = React.createRef();
    }

    componentDidMount() {
        const { t } = this.props;
        $('.select').select2({
            width: '100%'
        });
        //let _this = this;

        // this.props.setBreadcrumbs([
        //     { href: "/", className: "", value: "Test 1" },
        //     { href: "/", className: "", value: "Test 2" },
        //     { href: "/", className: "", value: "Test 3" },
        //     { href: "/", className: "", value: "Test 4" }
        // ]);
        this.props.setPageHeader(t("pages.debit.tracking", { ns: "translations" }));
        this.props.setHeaderElements([
            {
                componentId: "delete-entry",
                href: "#", translation: { key: "misc.deleteSelected", opts: { ns: "translations" } },
                className: "btn btn-link btn-float text-body", icon: "fas fa-trash text-danger",
            }
        ]);
        this.context.addEventHandler("delete-entry", (ev) => this.onDeleteStockButtonClick(ev));

        this.embezzlementTrackingListTable = $(".datatable-pagination").DataTable({
            pagingType: 'simple_numbers',
            autoWidth: false,
            responsive: true,
            processing: true,
            lengthMenu: [[50, 100, 150, -1], [50, 100, 150, "Hepsi"]],
            serverSide: true,
            dom: 'Blfrtip',
            buttons: [
                'copyHtml5',
                'excelHtml5',
                'csvHtml5',
                'pdfHtml5'
            ],
            ajax: {
                url: `${getEndPoint()}/api/stocks/debit-list`,
                type: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                xhrFields: {
                    withCredentials: true
                },
                crossDomain: true,
                dataFilter: function (data) {
                    var json = $.parseJSON(data);
                    json.data = json.data;
                    json.recordsFiltered = json.result.recordsTotal
                    json.recordsTotal = json.result.recordsTotal
                    return JSON.stringify(json);
                },
            },
            fnServerParams: (aoData) => {
                aoData["filters"] = [{ expression: "LIKE", column: $('.select').val(), value: '%' + String(aoData.search.value).toLocaleUpperCase('TR') + '%' },
                { "expression": "order", "column": aoData.columns[aoData.order[0].column].data, "value": aoData.order[0].dir },];

                aoData["page"] = aoData.start / aoData.length;
                aoData["per_page"] = aoData.length;
            },
            "columns": [
                { data: "id", "orderable": "false" },
                {
                    data: "id", render: function (data, type, row) {
                        return null
                    }
                },
                { data: "stock_code", "orderable": "false" },
                { data: "stock_name", "orderable": "false" },
                { data: "barcode_number", "orderable": "false" },
                { data: "vat_ratio", "orderable": "false" },
                { data: "price", "orderable": "false" },
                { data: "responsible_employee.name", "orderable": "false" },
            ],
            columnDefs: [
                {
                    data: '',
                    defaultContent: '',
                    orderable: false,
                    className: 'select-checkbox',
                    targets: 1,
                    checkboxes: {
                        selectRow: true
                    }
                },
                {
                    data: '',
                    defaultContent: '',
                    orderable: false,
                    className: '',
                    targets: 0
                }
            ],
            select: {
                style: 'multi',
                selector: 'td:nth-child(2)'
            },
            order: [[0, 'asc']],
            language: {
                ...(t("plugins.dataTables", { ns: "translations" })),
                paginate: {
                    'next': $('html').attr('dir') === 'rtl' ? t("plugins.dataTables.paginate.next", { ns: "translations" }) + '&larr;' : t("plugins.dataTables.paginate.next", { ns: "translations" }) + ' &rarr;',
                    'previous': $('html').attr('dir') === 'rtl' ? '&rarr; ' + t("plugins.dataTables.paginate.previous", { ns: "translations" }) : '&larr; ' + t("plugins.dataTables.paginate.previous", { ns: "translations" })
                }
            }
        });
        this.embezzlementTrackingListTable.on('select', (e, dt, type, indexes) => this.onEmbezzlementTrackingListTableSelect(e, dt, type, indexes));
        this.embezzlementTrackingListTable.on('deselect', (e, dt, type, indexes) => this.onEmbezzlementTrackingListTableDeselect(e, dt, type, indexes));
    }

    componentWillUnmount() {
        this.props.setPageHeader("");
        this.props.setHeaderElements([]);
        this.context.handlers = [];
        // this.setState({stocks:[]});
    }

    onDeleteStockButtonClick(ev) {
        const { t } = this.props;
        if (this.state.selectDeleteItemRows === 0) {
            ReactSwal.fire({
                title: <span>{t("operations.deleteMultiple", { ns: "translations", itemCount: 1 })}</span>,
                html: ``,
                icon: 'error',
                showCloseButton: true,
                confirmButtonText: t("misc.ok", { ns: "translations" }),
            });
            return;
        }

        ReactSwal.fire({
            title: <span>{t("operations.wouldYouLikeToDeleteRecords", { ns: "translations" })}</span>,
            html: <span>{t("operations.itemsToDelete", { ns: "translations", itemCount: this.state.rowsToDelete })}</span>,
            icon: '',
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: t("misc.yes", { ns: "translations" }),
            cancelButtonText: t("misc.cancel", { ns: "translations" }),
        }).then((a) => {
            if (a.isConfirmed) {
                ReactSwal.fire({
                    title: <span>{t("misc.successfull", { ns: "translations" })}</span>,
                    html: <span>{t("operations.itemsDeleted", { ns: "translations", itemCount: this.state.rowsToDelete })}</span>,
                    icon: '',
                    showCloseButton: true,
                    focusConfirm: false,
                    confirmButtonText: t("misc.ok", { ns: "translations" }),
                })
            }
        });
    }

    onSelectAllButtonClick(ev) {
        $(ev.target).parent().find("#selectAll").prop("checked", (index, oldPropertyValue) => {
            $(this.selectAllRef.current).prop("checked", !oldPropertyValue);
        });

        if ($(ev.target).parent().find("#selectAll").is(':checked')) {
            this.embezzlementTrackingListTable.rows().select();
            this.setState({
                rowsToDelete: this.embezzlementTrackingListTable.rows({ selected: true }).count()
            });
        } else {
            this.embezzlementTrackingListTable.rows().deselect();
            this.setState({
                rowsToDelete: 0
            });
        }
    }

    onEmbezzlementTrackingListTableSelect(e, dt, type, indexes) {
        this.setState({
            rowsToDelete: this.embezzlementTrackingListTable.rows({ selected: true }).count()
        });

        if (this.embezzlementTrackingListTable.rows({ selected: true }).count() === this.embezzlementTrackingListTable.rows().count())
            $(this.selectAllRef.current).prop("checked", true);
    }

    onEmbezzlementTrackingistTableDeselect(e, dt, type, indexes) {
        this.setState({
            rowsToDelete: this.embezzlementTrackingListTable.rows({ selected: true }).count()
        });

        if (this.embezzlementTrackingListTable.rows({ selected: true }).count() !== this.embezzlementTrackingListTable.rows().count())
            $(this.selectAllRef.current).prop("checked", false);
    }

    render() {
        const { t } = this.props;

        return (
            <div className='card'>

                <div className='card-body'>
                    <div className='w-100'>
                        <table ref={this.embezzlementTrackingListTableRef} className="table table-striped table-hover dt-responsive nowrap datatable-pagination w-100">
                            <thead className='w-100'>
                                <tr>
                                    <th>
                                        <label>Aranılacak Alan</label>

                                    </th>
                                    <th>
                                        <div className='form-group'>
                                            <select className='select' ref={this.columnSelect}>
                                                <option value="stock_name">Stok Adı</option>
                                                <option value="stock_code">Stok Kodu</option>
                                                <option value="barcode_number">{t("table.header.barcodeNumber")}</option>
                                            </select>
                                        </div>
                                    </th>

                                </tr>

                                <tr>
                                    <th></th>
                                    <th className='select-checkbox h-100'>
                                        <div className="custom-control custom-switch d-flex justify-content-center align-items-center align-content-center" onClick={(ev) => this.onSelectAllButtonClick(ev)}>
                                            <input ref={this.selectAllRef} type="checkbox" className="custom-control-input" id="selectAll" />
                                            <label className="custom-control-label" style={{ paddingLeft: "0" }} htmlFor="selectAll"></label>
                                        </div>
                                    </th>

                                    <th>{t("table.header.stockCode")}</th>
                                    <th>{t("table.header.stockName")}</th>
                                    <th>{t("table.header.barcodeNumber")}</th>
                                    <th>{t("table.header.vatRatio")}</th>
                                    <th>{t("table.header.price")}</th>
                                    <th>{t("table.header.responsibleEmployee")}</th>
                                </tr>
                            </thead>
                            <tbody className='w-100'>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps())(
    withTranslation(["debit-tracking", "translations"])(EmbezzlementTracking)
);