import React from "react";  

export const EventHandlerContext = React.createContext({
    handlers: [
        // { componentId: "add-stock", handler: () => { console.log("test"); } }
    ],
    addEventHandler: (c, h) => { },
    clearEventHandlers: () => {}
});

export default null;