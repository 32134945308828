import React, { Component, createRef } from 'react';
import $ from "jquery";

import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { setBreadcrumbs } from '../../features/active-page/sub-pages-slice';
import { setPageHeader } from '../../features/active-page/page-header-slice';
import { setHeaderElements } from '../../features/active-page/header-elements-slice';
import { EventHandlerContext } from '../../contexts/event-handler-context';
import "datatables.net";
import intlTelInput from 'intl-tel-input';
import "intl-tel-input/build/css/intlTelInput.min.css";
import "datatables.net-responsive";
import "datatables.net-select";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Functions from '../../class/functions';
import { getEndPoint } from "../../state/api/index"

import axios from 'axios';
export function AddLibrary(urlOfTheLibrary) {
    const script = document.createElement('script');
    script.src = urlOfTheLibrary;
    script.async = true;
    document.body.appendChild(script);
}

Functions.importThemeStyles();
let UserListData = [];

const ReactSwal = withReactContent(Swal);
const mapStateToProps = state => ({
    redux: {
        pageHeader: state.pageHeader,
        breadcrumbs: state.breadcrumbs,
        headerElements: state.headerElements
    }
});
const mapDispatchToProps = () => ({
    setPageHeader,
    setBreadcrumbs,
    setHeaderElements
});

class UserList extends Component {
    static contextType = EventHandlerContext

    constructor(props) {
        super(props);
        this.columnSelect = React.createRef();

        this.state = {
            rowsToDelete: [],
            selectDeleteItemRows: 0,
            userData: {}
        };

        this.storageListTable = null;
        this.storageListTableRef = React.createRef();
        this.selectAllRef = React.createRef();
        this.nameRef = React.createRef();
        this.emailRef = React.createRef();
        this.phoneRef = React.createRef();
        this.adressRef = React.createRef();
        this.roleSelectRef = React.createRef();
        this.handeClickUser = this.handeClickUser.bind(this);
    }

    componentDidMount() {

        const { t } = this.props;
        let _this = this;
        this.props.setPageHeader(t("pages.users.users", { ns: "translations" }));
        this.props.setHeaderElements([
            {
                componentId: "delete-user",
                href: "#", translation: { key: "misc.deleteSelected", opts: { ns: "translations" } },
                className: "btn btn-link btn-float text-body", icon: "fas fa-trash text-danger",
            }
        ]);
        this.context.addEventHandler("delete-user", (ev) => this.onDeleteStorageButtonClick(ev));

        $('.select').select2({
            width: '100%'
        });
        $('.roleSelect').select2({
            width: '100%'
        });
        if (this.storageListTable != undefined)
            this.storageListTable.destroy();

        this.storageListTable = $(".datatable-pagination").DataTable({
            pagingType: 'simple_numbers',
            autoWidth: false,
            responsive: true,
            processing: true,
            serverSide: true,
            lengthMenu: [[50, 100, 150, -1], [50, 100, 150, "Hepsi"]],
            ajax: {
                url: `${getEndPoint()}/api/users/all`,
                type: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                xhrFields: {
                    withCredentials: true
                },
                crossDomain: true,
                dataFilter: function (data) {
                    var json = $.parseJSON(data);
                    json.data = json.data;
                    UserListData = json.data;
                    json.recordsFiltered = json.result.recordsTotal
                    json.recordsTotal = json.result.recordsTotal
                    return JSON.stringify(json);
                },
            },
            fnServerParams: (aoData) => {

                aoData["filters"] = [{ expression: "LIKE", column: $('.select').val(), value: '%' + String(aoData.search.value).toLocaleUpperCase('TR')+ '%' },
                { "expression": "order", "column": aoData.columns[aoData.order[0].column].data, "value": aoData.order[0].dir },];
                aoData["page"] = aoData.start / aoData.length;
                aoData["per_page"] = aoData.length;
            },
            "columns": [
                { data: "id", "orderable": "false" },
                {
                    data: "id", render: function (data, type, row) {
                        return null
                    }
                },
                { data: "name", "orderable": "false" },
                { data: "email", "orderable": "false" },
                { data: "phone", "orderable": "false" },
                // {
                //     data: "id", render: function (data, type, row) {
                //         return row.role == null ? 'Rol Bulunamadı' : row.role
                //     }
                // },
                // {
                //     data: "id", render: function (data, type, row) {
                //         return null
                //     }
                // },
            ],
            columnDefs: [{

                defaultContent: '',
                orderable: false,
                className: 'select-checkbox',
                targets: 1,
                checkboxes: {
                    selectRow: true
                },
            },
            // {
            //     "targets": 6,
            //     defaultContent: "<button   id='btnDetails'data-toggle='modal' data-target='#modal_default'  type='button' class='btn btn-success' width='25px'> " + t("misc.edit", { ns: "translations" }) + "</button>",

            // }
        ],
            select: {
                style: 'multi',
                selector: 'td:nth-child(2)'
            },
            order: [[0, 'asc']],
            language: {
                ...(t("plugins.dataTables", { ns: "translations" })),
                paginate: {
                    'next': $('html').attr('dir') === 'rtl' ? t("plugins.dataTables.paginate.next", { ns: "translations" }) + '&larr;' : t("plugins.dataTables.paginate.next", { ns: "translations" }) + ' &rarr;',
                    'previous': $('html').attr('dir') === 'rtl' ? '&rarr; ' + t("plugins.dataTables.paginate.previous", { ns: "translations" }) : '&larr; ' + t("plugins.dataTables.paginate.previous", { ns: "translations" })
                }
            }
        });

        // this.storageListTable.on('click', (e, dt, type, indexes) => this.onUserListClick(e, dt, type, indexes));
        // this.storageListTable.on('dblclick', (e, dt, type, indexes) => this.onUserListClick(e, dt, type, indexes));
        this.storageListTable.on('select', (e, dt, type, indexes) => this.onStorageListTableSelect(e, dt, type, indexes));
        this.storageListTable.on('deselect', (e, dt, type, indexes) => this.onStorageListTableDeselect(e, dt, type, indexes));

        this.storageListTable.on('click', '[id*=btnDetails]', function (e) {
            var data = _this.storageListTable.row($(this).parents('tr'));
            _this.setState({
                userData: UserListData[data[0]]
            })
            var config = {
                method: 'get',
                url: `${getEndPoint()}/api/roles/all`,
            };

            axios(config)
                .then(function (response) {
                    var roleSelect = $('.roleSelect');
                    roleSelect.empty().trigger('change');
                    response.data.data.forEach(element => {
                        var option = new Option(
                            element.key,
                            element.id,
                            _this.state.userData.get_role.id == element.id ? true : false,
                            _this.state.userData.get_role.id == element.id ? true : false);
                        roleSelect.append(option).trigger('change');

                    });
                })
        });

        $(this.storageListTable.current + 'tfoot th').each(function () {
            var title = $(this.storageListTable.current + 'tfoot th').eq($(this).index()).text();
            $(this).html('<input type="text" placeholder="Search ' + title + '" />');
        });
        $("input[type$='tel']").each((index, telInput) => {
            intlTelInput(telInput,
                {
                    autoPlaceholder: "polite",
                    geoIpLookup: (success, failure) => {
                        //success(countryCode)
                    },
                    preferredCountries: ["tr", "us", "gb"],
                    nationalMode: false,
                    seperateDialCode: true,
                    utilsScript: require("intl-tel-input/build/js/utils"),
                    customContainer: "w-100",
                    formatOnDisplay: true
                }
            )
        });

    }
    handeClickUser() {
        const { t } = this.props
        const _this = this
        ReactSwal.fire({
            title: <span>{t("misc.warning", { ns: "translations" })}</span>,
            html: <span>{t("operations.areYouSure", { ns: "translations" })}</span>,
            icon: '',
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: t("misc.yes", { ns: "translations" }),
            cancelButtonText: t("misc.cancel", { ns: "translations" }),
        }).then((a) => {
            if (a.isConfirmed) {
                var data = JSON.stringify({
                    "id": _this.state.userData.id,
                    "name": _this.nameRef.current.value,
                    "phone": _this.phoneRef.current.value,
                    "address": _this.adressRef.current.value,
                    "role": _this.roleSelectRef.current.value,
                    "email": _this.emailRef.current.value
                });

                var config = {
                    method: 'post',
                    url: `${getEndPoint()}/api/users/edit`,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: data
                };

                axios(config)
                    .then(function (response) {
                        ReactSwal.fire({
                            title: <span>{t("misc.successfull", { ns: "translations" })}</span>,
                            html: <span>{t("operations.successMessage", { ns: "translations" })}</span>,
                            icon: '',
                            showCloseButton: true,
                            focusConfirm: false,
                            confirmButtonText: t("misc.ok", { ns: "translations" }),
                        })
                        _this.componentDidMount();
                    })

            }
        });
    }
    componentWillUnmount() {
        this.props.setPageHeader("");
        this.props.setHeaderElements([]);
        this.context.handlers = [];
    }



    onDeleteStorageButtonClick(ev) {
        const { t } = this.props;
        let _this = this;
        if (this.state.selectDeleteItemRows === 0) {
            ReactSwal.fire({
                title: <span>{t("operations.deleteMultiple", { ns: "translations", itemCount: 1 })}</span>,
                html: ``,
                icon: 'error',
                showCloseButton: true,
                confirmButtonText: t("misc.ok", { ns: "translations" }),
            });
            return;
        }

        ReactSwal.fire({
            title: <span>{t("operations.wouldYouLikeToDeleteRecords", { ns: "translations" })}</span>,
            html: <span>{t("operations.itemsToDelete", { ns: "translations", itemCount: this.state.selectDeleteItemRows })}</span>,
            icon: '',
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: t("misc.yes", { ns: "translations" }),
            cancelButtonText: t("misc.cancel", { ns: "translations" }),
        }).then((a) => {
            if (a.isConfirmed) {

                var data = JSON.stringify({
                    "ids": [
                        _this.state.rowsToDelete
                    ]
                });

                var config = {
                    method: 'post',
                    url: `${getEndPoint()}/api/users/delete`,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: data
                };
                axios(config)
                    .then(function (response) {
                        ReactSwal.fire({
                            title: <span>{t("misc.successfull", { ns: "translations" })}</span>,
                            html: <span>{t("operations.itemsDeleted", { ns: "translations", itemCount: _this.state.selectDeleteItemRows })}</span>,
                            icon: '',
                            showCloseButton: true,
                            focusConfirm: false,
                            confirmButtonText: t("misc.ok", { ns: "translations" }),
                        })
                        _this.componentDidMount();
                    })

            }
        });
    }

    

    onStorageListTableSelect(e, dt, type, indexes) {
        // var stateData = this.state.rowsToDelete
        // if (stateData !== "") {
        //     stateData = String(stateData + ',')
        // }
        this.state.rowsToDelete.push(dt.data().id);
        this.setState({
            rowsToDelete: this.state.rowsToDelete,
            selectDeleteItemRows: this.storageListTable.rows({ selected: true }).count()
        });
        if (this.storageListTable.rows({ selected: true }).count() === this.storageListTable.rows().count())
            $(this.selectAllRef.current).prop("checked", true);
    }
    onStorageListTableDeselect(e, dt, type, indexes) {
        var filteredArray = this.state.rowsToDelete.filter(function (f) { return f !== dt.data().id })
        this.setState({
            rowsToDelete: filteredArray,
            selectDeleteItemRows: this.storageListTable.rows({ selected: true }).count()
        });
        if (this.storageListTable.rows({ selected: true }).count() !== this.storageListTable.rows().count())
            $(this.selectAllRef.current).prop("checked", false);
    }
    onUserListClick(e, dt, type, indexes) {
    }
    

    render() {
        const { t } = this.props;

        return (
            <div className='card'>
                {AddLibrary(
                    'https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js')}


                <div className='card-body'>
                    <div className='w-100'>

                        <table ref={this.storageListTableRef} className="table table-striped table-hover dt-responsive nowrap datatable-pagination w-100">
                            <thead className='w-100'>
                                <tr>
                                    <th>
                                        <label>Aranılacak Alan</label>

                                    </th>
                                    <th>
                                        <div className='form-group'>
                                            <select className='select' ref={this.columnSelect}>
                                                <option value="name">Ad || Soyad</option>
                                                <option value="email">Email</option>
                                                <option value="phone">Telefon</option>
                                                <option value="adress">Adres</option>
                                            </select>
                                        </div>
                                    </th>

                                </tr>
                                <tr>
                                    <th>ID</th>
                                    <th className='select-checkbox h-100'>
                                        <div className="custom-control custom-switch d-flex justify-content-center align-items-center align-content-center">
                                            <input defaultChecked={this.state.selectDeleteItemRows === 0 ? "" : "checked"} ref={this.selectAllRef} type="checkbox" className="custom-control-input" id="selectAll" />
                                            <label className="" style={{ paddingLeft: "0" }} defaultValue={false} htmlFor="selectAll">Seç</label>
                                        </div>
                                    </th>
                                    <th>{t("table.header.name")}</th>
                                    <th>{t("table.header.email")}</th>
                                    <th>{t("table.header.phone")}</th>
                                    {/* <th>{t("table.header.role")}</th> */}
                                    {/* <th>İşlemler</th> */}
                                </tr>
                            </thead>
                            <tbody className='w-100'>

                            </tbody>
                        </table>
                    </div>
                </div>
                <div id="modal_default" className="modal fade show bd-example-modal-lg" tabIndex="-1" aria-modal="true" role="dialog">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{t("pages.users.userEdit", { ns: "translations" })}</h5>
                                <button type="button" className="close" data-dismiss="modal">×</button>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <div className="form-group">
                                            <label htmlFor="name">{t("name", { ns: "user-add" })}</label>
                                            <input name="name" ref={this.nameRef} defaultValue={this.state.userData.name} type="text" className='form-control' />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="email">{t("email", { ns: "user-add" })}</label>
                                            <input name="email" ref={this.emailRef} defaultValue={this.state.userData.email} type="text" className='form-control' />
                                        </div>


                                        <div className="form-group">
                                            <label htmlFor="phone">{t("phone", { ns: "user-add" })}</label>
                                            <input ref={this.phoneRef} name="phone" defaultValue={this.state.userData.phone} type="tel" className='form-control' />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="adress">{t("adress", { ns: "user-add" })}</label>
                                            <input ref={this.adressRef} name="adress" defaultValue={this.state.userData.address} type="text" className='form-control' />
                                        </div>
                                        <div className="form-group" >
                                            <label htmlFor="roles">{t("role", { ns: "user-add" })}</label>

                                            <select ref={this.roleSelectRef} className="roleSelect" multiple>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-link" data-dismiss="modal">Vazgeç</button>
                                <button type="button" onClick={this.handeClickUser} className="btn btn-primary">Kaydet</button>
                            </div>
                        </div>
                    </div>
                </div>


            </div >
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps())(
    withTranslation(["user-list", "translations"])(UserList)
);