import { configureStore } from '@reduxjs/toolkit'
import pageHeaderSlice from './active-page/page-header-slice'
import breadcrumbsSlice from './active-page/sub-pages-slice'
import headerElementsSlice from './active-page/header-elements-slice'
import themeSlice from './layout/theme-slice';
import userSlice from './user/user-slice';

export default configureStore({
  reducer: {
      pageHeader: pageHeaderSlice,
      breadcrumbs: breadcrumbsSlice,
      headerElements: headerElementsSlice,
      user: userSlice,
      theme: themeSlice
  },
})