import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { setBreadcrumbs } from '../../features/active-page/sub-pages-slice';
import { setPageHeader } from '../../features/active-page/page-header-slice';
import { setHeaderElements } from '../../features/active-page/header-elements-slice';
import { EventHandlerContext } from '../../contexts/event-handler-context';
import $ from "jquery";
import "datatables.net";
import "datatables.net-responsive";
import "datatables.net-buttons-dt";
import "datatables.net-select";
import "jszip";
import "pdfmake";
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.print';
import 'datatables.net-buttons/js/buttons.colVis';
import 'datatables.net-buttons/js/buttons.flash';
import { getEndPoint } from "../../state/api/index"
import { DateRangePickerHandler } from '../../js/plugins/pickers/daterangepicker';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Functions from '../../class/functions';
import moment from 'moment';

Functions.importThemeStyles();

const ReactSwal = withReactContent(Swal);

const mapStateToProps = state => ({
    redux: {
        pageHeader: state.pageHeader,
        breadcrumbs: state.breadcrumbs,
        headerElements: state.headerElements
    }
});
const mapDispatchToProps = () => ({
    setPageHeader,
    setBreadcrumbs,
    setHeaderElements
});

class StorageCheckouts extends Component {
    static contextType = EventHandlerContext

    constructor(props) {
        super(props);
        const { t } = this.props;

        this.state = {
            rowsToDelete: "",
            selectDeleteItemRows: 0
        };
        this.filterData = ""
        this.stockListTable = null;
        this.stockListTableRef = React.createRef();
        this.selectAllRef = React.createRef();
        this.ilkTarih = React.createRef()
        this.depoRef = React.createRef()
        this.sonTarih = React.createRef()
        Functions.createSelectList(this.depoRef, t, 'storages')

    }

    componentDidMount() {
        const { t } = this.props;
        let _this = this;
        DateRangePickerHandler(this.ilkTarih.current, {
            parentEl: '.content-inner',
            showDropdowns: true,
            singleDatePicker: true,
            locale: t("dateRangePickerLocalization", { ns: "translations" })
        });
        DateRangePickerHandler(this.sonTarih.current, {
            parentEl: '.content-inner',
            showDropdowns: true,
            singleDatePicker: true,
            locale: t("dateRangePickerLocalization", { ns: "translations" })
        });

        $('.selectList2').select2({
            width: "100%",
            language: {
                noResults: () => t("plugins.select2.noResults", { ns: "translations" }),
                searching: () => t("plugins.select2.searching", { ns: "translations" }),
                maximumSelected: () => t("plugins.select2.maximumSelected", { ns: "translations" }),
                loadingMore: () => t("plugins.select2.loadingMore", { ns: "translations" }),
                inputTooShort: () => t("plugins.select2.inputTooShort", { ns: "translations" }),
                inputTooLong: () => t("plugins.select2.inputTooLong", { ns: "translations" }),
                errorLoading: () => t("plugins.select2.errorLoading", { ns: "translations" }),
            }
        });


        this.props.setPageHeader('Depo Çıkışları');
        if (this.stockListTable != undefined)
            this.stockListTable.destroy();
        this.stockListTable = $(".datatable-pagination").DataTable({
            pagingType: 'simple_numbers',
            autoWidth: false,
            responsive: true,
            processing: true,
            serverSide: true,
            lengthMenu: [[50, 100, 150, -1], [50, 100, 150, "Hepsi"]],
            dom: 'Blfrtip',
            buttons: [
                'copyHtml5',
                'excelHtml5',
                'csvHtml5',
                'pdfHtml5'
            ],
            ajax: {
                url: `${getEndPoint()}/api/stocks/gc-list`,
                type: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                xhrFields: {
                    withCredentials: true
                },
                crossDomain: true,
                dataFilter: function (data) {
                    var json = $.parseJSON(data);
                    json.data = json.data;
                    json.recordsFiltered = json.result.recordsTotal
                    json.recordsTotal = json.result.recordsTotal
                    return JSON.stringify(json);
                },
            },
            fnServerParams: (aoData) => {
                aoData["filters"] = [
                    { "expression": "order", "column": aoData.columns[aoData.order[0].column].data, "value": aoData.order[0].dir },
                    { expression: "LIKE", column: "StokKodu", value: '%' + String(aoData.search.value).toLocaleUpperCase('TR')+ '%' },
                    _this.filterData != "" ? _this.filterData[0] : null,
                    _this.filterData != "" ? _this.filterData[1] : null,
                    _this.filterData != "" ? _this.filterData[2] : null,
                    _this.filterData != "" ? _this.filterData[3] : null
                ];
                aoData["gc"] = 0;
                aoData["is_entry"] = true;
                aoData["page"] = aoData.start / aoData.length;
                aoData["per_page"] = aoData.length;
            },
            "columns": [
                { data: "id", "orderable": "false" },

                { data: "Depo", "orderable": "false" },
                { data: "StokKodu", "orderable": "false" },
                {
                    data: "get_stock", render: function (data, type, row) {
                        if (!data.length) {
                            return ' ';
                        }
                        return '<a href="/stock/' + data[0].id + '">' + data[0].stock_name + ' </a>'
                    },
                    "orderable": false
                },
                { data: "KdvOran", "orderable": "false" },
                { data: "Miktar", "orderable": "false" },
                { data: "price", "orderable": "false" },
                { data: "Aciklama", "orderable": "false" },


            ],

            order: [[1, 'asc']],
            language: {
                ...(t("plugins.dataTables", { ns: "translations" })),
                paginate: {
                    'next': $('html').attr('dir') === 'rtl' ? t("plugins.dataTables.paginate.next", { ns: "translations" }) + '&larr;' : t("plugins.dataTables.paginate.next", { ns: "translations" }) + ' &rarr;',
                    'previous': $('html').attr('dir') === 'rtl' ? '&rarr; ' + t("plugins.dataTables.paginate.previous", { ns: "translations" }) : '&larr; ' + t("plugins.dataTables.paginate.previous", { ns: "translations" })
                }
            }
        });
    }

    componentWillUnmount() {
        this.props.setPageHeader("");
        this.props.setHeaderElements([]);
        this.context.handlers = [];
    }
    handleClickFilter = () => {
        var depo = $(this.depoRef.current).select2('data')[0];
        this.filterData = [

            depo.id != "0" ? { expression: "=", column: 'depo_id', value: depo.id } : null,
            { expression: ">", column: 'created_at', value: moment($(this.ilkTarih.current).val(), 'DD/MM/YYYY').format('YYYY-MM-DD')  },
            { expression: "<", column: 'created_at', value: moment($(this.sonTarih.current).val(), 'DD/MM/YYYY').format('YYYY-MM-DD')  },
        ];

        this.componentDidMount()
    }

    render() {
        const { t } = this.props;

        return (
            <>
                <div className="card mb-1">
                    <div className="card-body py-2">
                        <div className="row">

                            <div className="col-sm-2">
                                <div className="d-block w-100 mt-2">
                                    <span>Depo</span>
                                    <div className="d-flex">
                                        <select ref={this.depoRef} name="depo" className="selectList2 form-control" >
                                            <option value="0">Yükleniyor...</option>

                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-2">
                                <div className="d-block w-100 mt-2">
                                    <span>İlk Tarih</span>
                                    <div className="d-flex">
                                        <input ref={this.ilkTarih} type="text" className="form-control w-100 daterange-single" defaultValue="" />

                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-2">
                                <div className="d-block w-100 mt-2">
                                    <span>Son Tarih</span>
                                    <div className="d-flex">
                                        <input ref={this.sonTarih} type="text" className="form-control w-100 daterange-single" defaultValue="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3 mt-4">
                                <button onClick={this.handleClickFilter} type="button" className="btn btn-primary w-100">Listele</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card'>

                    <div className='card-body'>
                        <div className='w-100'>
                            <table ref={this.stockListTableRef} className="table table-striped table-hover dt-responsive nowrap datatable-pagination w-100">
                                <thead className='w-100'>
                                    <tr>
                                        <th></th>


                                        <th>Depo</th>
                                        <th>Stok Kodu</th>
                                        <th>Stok Adı</th>
                                        <th>KDV Oranı</th>
                                        <th>Miktar</th>
                                        <th>Fiyatı</th>
                                        <th>Açıklama</th>
                                    </tr>
                                </thead>
                                <tbody className='w-100'>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps())(
    withTranslation(["storage-checkouts", "translations"])(StorageCheckouts)
);