import { createSlice } from '@reduxjs/toolkit'

export const headerElementsSlice = createSlice({
  name: 'headerElements',
  initialState: {
    value: [],
  },
  reducers: {
    setHeaderElements(state, action) {
    
      state.value = action.payload;
    }
    //setHeaderElement([]);
  },
})

export const { setHeaderElements } = headerElementsSlice.actions

export default headerElementsSlice.reducer