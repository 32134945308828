import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { setBreadcrumbs } from '../../features/active-page/sub-pages-slice';
import { setPageHeader } from '../../features/active-page/page-header-slice';
import { setHeaderElements } from '../../features/active-page/header-elements-slice';
import { EventHandlerContext } from '../../contexts/event-handler-context';
import Functions from '../../class/functions';
import $ from "jquery";
import "select2";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from 'axios';
// import "select2/dist/css/select2.min.css";
import {getEndPoint} from "../../state/api/index"

Functions.importThemeStyles();

const ReactSwal = withReactContent(Swal);
const mapStateToProps = state => ({
    redux: {
        pageHeader: state.pageHeader,
        breadcrumbs: state.breadcrumbs,
        headerElements: state.headerElements
    }
});
const mapDispatchToProps = () => ({
    setPageHeader,
    setBreadcrumbs,
    setHeaderElements
});




class StorageTransfer extends Component {
    static contextType = EventHandlerContext

    constructor(props) {
        super(props);

        this.checkedStorage = React.createRef();
        this.transferFromRef = React.createRef();
        this.transferToRef = React.createRef();
        this.state = {

        };
        this.createSelect = (ref, t) => {
            $(ref.current).select2({
                width: "100%",
                language: {
                    noResults: () => t("plugins.select2.noResults", { ns: "translations" }),
                    searching: () => t("plugins.select2.searching", { ns: "translations" }),
                    maximumSelected: () => t("plugins.select2.maximumSelected", { ns: "translations" }),
                    loadingMore: () => t("plugins.select2.loadingMore", { ns: "translations" }),
                    inputTooShort: () => t("plugins.select2.inputTooShort", { ns: "translations" }),
                    inputTooLong: () => t("plugins.select2.inputTooLong", { ns: "translations" }),
                    errorLoading: () => t("plugins.select2.errorLoading", { ns: "translations" }),
                }
            });
        }
    }

    componentDidMount() {
        const { t } = this.props;




        this.props.setPageHeader(t("pages.storages.transfer", { ns: "translations" }));
        this.props.setHeaderElements([
            {
                componentId: "transfer-storage",
                href: "#", translation: { key: "misc.transfer", opts: { ns: "translations" } },
                className: "btn btn-link btn-float text-body", icon: "fas fa-exchange-alt text-primary",
            },
        ]);
        //this.context.addEventHandler("add-debit", (ev) => this.onAddStockButtonClick(ev));
        this.context.addEventHandler("transfer-storage", (ev) => this.onTransferStorageClick(ev));

        this.createSelect(this.transferFromRef, t);
        this.createSelect(this.transferToRef, t);


        var config = {
            method: 'get',
            url: `${getEndPoint()}/api/storages/all`,
            headers: {
                'Content-Type': 'application/json',
            },
        };

        axios(config)
            .then(function (response) {
                Functions.selectAppend('.selectFrom', response.data.data)
                Functions.selectAppend('.selectTo', response.data.data)
            })
    }

    componentDidUpdate() {

    }

    componentWillUnmount() {
        this.props.setPageHeader("");
        this.props.setHeaderElements([]);
        this.context.handlers = [];
    }

    onTransferStorageClick(ev) {
        const { t } = this.props;
        const _this = this;
        ReactSwal.fire({
            title: <span>{t("operations.doYouWantToTransferStorage")}</span>,
            html: <span>{t("operations.selectedStorageWillBeTransfered")}</span>,
            icon: '',
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: t("misc.yes", { ns: "translations" }),
            cancelButtonText: t("misc.cancel", { ns: "translations" }),
        }).then((a) => {
            if (a.isConfirmed) {

                var data = JSON.stringify({
                    "from": _this.transferFromRef.current.value,
                    "to": _this.transferToRef.current.value,
                    "deleteAfterTransfer": _this.checkedStorage.current.checked
                });

                var config = {
                    method: 'post',
                    url: `${getEndPoint()}/api/storages/transfer`,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: data
                };

                axios(config)
                    .then(function (response) {
                        ReactSwal.fire({
                            title: <span>{t("misc.successfull", { ns: "translations" })}</span>,
                            html: <span>{t("operations.storageTransferedSuccessfully")}</span>,
                            icon: '',
                            showCloseButton: true,
                            focusConfirm: false,
                            confirmButtonText: t("misc.ok", { ns: "translations" }),
                        })
                        _this.componentDidMount()
                    })
                    .catch(function (error) {


                    })


            }
        });
    }

    render() {
        const { t } = this.props;

        return (
            <div>
                <div className="card p-1 m-0">
                    <div className="card-body p-1 m-0">

                        <div className='row'>
                            <div className="col-md-5 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="transferFrom">{t("transferFrom")}</label>
                                    <select ref={this.transferFromRef} name="transferFrom" data-placeholder={t("placeholders.transferFrom")} className="form-control select selectFrom" data-fouc>
                                        <option value="0">Yükleniyor...</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-2 col-sm-12 d-flex justify-content-center align-items-center align-content-center">
                                <i className="fas fa-exchange-alt"></i>
                            </div>
                            <div className="col-md-5 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="transferTo">{t("transferTo")}</label>
                                    <select ref={this.transferToRef} name="transferTo" data-placeholder={t("placeholders.transferTo")} className="form-control select selectTo" data-fouc>
                                        <option value="0">Yükleniyor...</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="custom-control custom-switch d-flex justify-content-center align-items-center align-content-center">
                                    <input ref={this.checkedStorage} defaultChecked={false} type="checkbox" className="custom-control-input" id="removeStorageAfterOperation" />
                                    <label className="custom-control-label" style={{ paddingLeft: "45px" }} defaultValue={false} htmlFor="removeStorageAfterOperation">
                                        İşlemden sonra aktarılan depo silinsin
                                    </label>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps())(
    withTranslation(["storage-transfer", "translations"])(StorageTransfer)
);