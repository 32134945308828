import "./class/app-start";

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Routes, Route
} from "react-router-dom";
import reportWebVitals from './reportWebVitals';

import store from "./features/store";
// import $ from "jquery";
// import bootstrap from "bootstrap";
// import * as d3 from "d3";
// import * as moment from "moment";
// import * as popper from "popper";
import "./js/app";
import AuthControl from './authControl'
import "./css/icons/icomoon/styles.min.css";
import "./css/icons/fontawesome/styles.min.css";
import "./css/icons/material/styles.min.css";

import './css/index.css';

import Layout from './pages/layout';
import QrCode from './pages/qr-code';
import Home from "./pages/home";
import Login from "./pages/login";
import StockAdd from "./pages/stocks/add";
import StockOut from "./pages/stocks/out";
import StockOutV2 from "./pages/stocks/outv2";
import StockEntry from "./pages/stocks/entry";
import StockEntries from "./pages/stocks/stok-giris-listesi";
import StockCounting from "./pages/stocks/stok-cikis-listesi";
import StockList from "./pages/stocks/list"; 
import StorageList from "./pages/storages/list";
import StorageAdd from "./pages/storages/add";
import StorageEntries from "./pages/storages/entries";
import StorageCheckouts from "./pages/storages/checkouts";
import Sayim from "./pages/storages/sayim";
import StorageTransfer from "./pages/storages/transfer";
import UserList from "./pages/users/list";
import UserAdd from "./pages/users/add";
import DebitTracking from "./pages/debit/tracking";
import DebitAdd from "./pages/debit/add";
import CompanyList from "./pages/company/list";
import CompanyAdd from "./pages/company/add";
import CompanyEdit from "./pages/company/edit";
import Profile from "./pages/settings/profile";
import Logout from "./pages/logout";
import NotFoundPage from "./pages/NotFoundPage";
import Personel from "./pages/personel/personel";
import PersonelEkle from "./pages/personel/personel-ekle";
import PersonelDuzenle from "./pages/personel/personel-duzenle";
import TalepForm from "./pages/operations/talep-form";
import Unit from "./pages/Unit/Unit";
import StockUpdate from "./pages/stocks/update";
import FiyatAnalizi from "./pages/operations/fiyat_analizi";
import axios from "axios";
// import { getEndPoint } from "./state/api";

axios.defaults.withCredentials = true;

// axios.post(`${getEndPoint()}/spa/csrf-cookie`);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path='/' element={<AuthControl />}>

            <Route exact path="/" element={<Layout DisableLayout={false} Content={<Home />} />} />
            <Route exact path="/users" element={<Layout DisableLayout={false} Content={<UserList />} />} />
            <Route exact path="/users/add" element={<Layout DisableLayout={false} Content={<UserAdd />} />} />
            <Route exact path="/personel" element={<Layout DisableLayout={false} Content={<Personel />} />} />
            <Route exact path="/personel/ekle" element={<Layout DisableLayout={false} Content={<PersonelEkle />} />} />
            <Route exact path="/personel/:id" element={<Layout DisableLayout={false} Content={<PersonelDuzenle />} />} />
            <Route exact path="/stocks" element={<Layout DisableLayout={false} Content={<StockList />} />} />
            <Route exact path="/stock/:StockID" element={<Layout   DisableLayout={false}  Content={<StockUpdate />} />} />
            <Route exact path="/stocks/add" element={<Layout DisableLayout={false} Content={<StockAdd />} />} />
            <Route exact path="/stocks/out-stock" element={<Layout DisableLayout={false} Content={<StockOut />} />} />
            <Route exact path="/stocks/out-stock-v2" element={<Layout DisableLayout={false} Content={<StockOutV2 />} />} />

            <Route exact path="/stocks/entry-stock" element={<Layout DisableLayout={false} Content={<StockEntry />} />} />
            <Route exact path="/stocks/entries-stock" element={<Layout DisableLayout={false} Content={<StockEntries />} />} />
            <Route exact path="/qr-code" element={<Layout DisableLayout={false} Content={<QrCode />} />} />
            <Route exact path="/fiyat-analizi" element={<Layout DisableLayout={false} Content={<FiyatAnalizi />} />} />

            <Route exact path="/birim" element={<Layout DisableLayout={false} Content={<Unit />} />} />
            <Route exact path="/talep" element={<Layout DisableLayout={false} Content={<TalepForm />} />} />
            <Route exact path="/stocks/counting-stock" element={<Layout DisableLayout={false} Content={<StockCounting />} />} />
            <Route exact path="/storages" element={<Layout DisableLayout={false} Content={<StorageList />} />} />
            <Route exact path="/storages/add" element={<Layout DisableLayout={false} Content={<StorageAdd />} />} />
            <Route exact path="/storages/counting" element={<Layout DisableLayout={false} Content={<Sayim />} />} />
            <Route exact path="/storages/entries" element={<Layout DisableLayout={false} Content={<StorageEntries />} />} />
            <Route exact path="/storages/checkouts" element={<Layout DisableLayout={false} Content={<StorageCheckouts />} />} />
            <Route exact path="/storages/transfer" element={<Layout DisableLayout={false} Content={<StorageTransfer />} />} />
            <Route exact path="/debit" element={<Layout DisableLayout={false} Content={<span>debit</span>} />} />
            <Route exact path="/debit/tracking" element={<Layout DisableLayout={false} Content={<DebitTracking />} />} />
            <Route exact path="/debit/add" element={<Layout DisableLayout={false} Content={<DebitAdd />} />} />
            <Route exact path="/company" element={<Layout DisableLayout={false} Content={<CompanyList />} />} />
            <Route exact path="/company/add" element={<Layout DisableLayout={false} Content={<CompanyAdd />} />} />
            <Route exact path="/company/:id" element={<Layout DisableLayout={false} Content={<CompanyEdit />} />} />
            <Route exact path="/logout" element={<Logout />} />
            <Route exact path="/settings/profile" element={<Layout DisableLayout={false} Content={<Profile />} />} />
            <Route exact path="*" element={<NotFoundPage />} />
          </Route>

          <Route exact path="/login" element={<Login />} />
        </Routes>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);


reportWebVitals();