import { createSlice } from '@reduxjs/toolkit'

export const pageHeaderSlice = createSlice({
  name: 'pageHeader',
  initialState: {
    value: "",
  },
  reducers: {
    setPageHeader(state, action) {

        state.value = action.payload;
    }
  },
})

export const { setPageHeader } = pageHeaderSlice.actions

export default pageHeaderSlice.reducer